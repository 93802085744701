import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { formValueSelector } from 'redux-form';

// Components
import { Tooltip } from 'react-tippy';
import PriceSheetsRow from './price-sheets-row';
import DeleteModal from '@/components/Shared/DeleteModal';
import PriceSheetsModal from './price-sheets-modal';
import PhotoDayGrid from '@/components/Shared/PhotodayGrid';

// Helpers
import { getStudioLabsInStudio } from '../../Settings/actions';
import { setProfileFlagsRequest } from '@/components/Login/actions';
import { selectInitialValuesFromPriceSheet } from './selectors';

import { getPricesheetListRequest, createPricesheetRequest, updatePricesheetRequest, deletePricesheetRequest, copyPricesheetRequest } from './actions';

// Styles
import './pricesheets.css';

const VITE_BULK_SHIPPING_ENABLED = import.meta.env.VITE_BULK_SHIPPING_ENABLED === '1';

const mapStateToProps = (state) => {
  const {
    login: { user, entities },
    form
  } = state;
  const roles = (user && user.roles) || [];
  const studio = Object.values(entities.studio)[0] || {};
  const { id: studioId, lab_id: labId } = studio || {};

  const labSelected = formValueSelector('PriceSheetForm')(state, 'lab_id');

  return {
    result: state.pricesheets.result,
    entities: state.pricesheets.entities,
    requesting: state.pricesheets.requesting,
    pager: state.pricesheets.pager,
    roles,
    studio,
    studioId,
    labId,
    login: state.login,
    labSelected,
    form
  };
};

const mapDispatchToProps = {
  setProfileFlagsRequest,
  getPricesheetListRequest,
  createPricesheetRequest,
  updatePricesheetRequest,
  deletePricesheetRequest,
  copyPricesheetRequest,
  getStudioLabsInStudio
};

class PriceSheets extends Component {
  state = {
    showViewEditModal: false,
    showDeleteWarningModal: false,
    edit: false,
    studioLabs: [],
    deletePriceSheet: {
      display: false,
      name: '',
      priceSheetId: null,
      jobsCount: 0
    },
    priceSheetPublishedJobs: []
  };

  static propTypes = {
    result: PropTypes.arrayOf(PropTypes.string),
    requesting: PropTypes.bool.isRequired,
    studio_id: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  };

  static defaultProps = {
    entities: {
      priceSheets: []
    }
  };

  componentDidMount() {
    const { studioId, getPricesheetListRequest, getStudioLabsInStudio } = this.props;

    if (studioId && studioId !== 'all-studios') {
      getStudioLabsInStudio(studioId, (labs) => {
        const studioLabs = Object.values(labs).map((lab) => {
          return {
            value: lab.lab.id,
            label: lab.lab.name,
            selected: lab.primary === true,
            allowBulkShipping: lab.lab.allow_bulk_shipping
          };
        });

        this.setState({ studioLabs });
      });
    }

    const getInitialPriceSheetList = () => {
      getPricesheetListRequest({
        order: 'created_at',
        dir: 'DESC',
        page: 1,
        perPage: 15
      });
    };

    getInitialPriceSheetList();
  }

  fetchPriceSheetsPage = (pager) => {
    const { getPricesheetListRequest } = this.props;

    getPricesheetListRequest(pager, (payload) => {
      const { pager, result } = payload;
      const { page, totalPages } = pager;

      if (!result.length && page > 1) {
        getPricesheetListRequest({
          ...pager,
          page: Math.min(page - 1, totalPages)
        });
      }
    });
  };

  toggleViewEditModal = (show, priceSheet, edit = false) => {
    this.setState({
      showViewEditModal: show,
      edit,
      priceSheet
    });
  };

  toggleWelcomeModal = () => {
    const { setProfileFlagsRequest } = this.props;
    setProfileFlagsRequest({ seen_price_sheets: true });
  };

  handleSubmitPriceSheet = (priceSheet) => {
    const { studioId, createPricesheetRequest, updatePricesheetRequest, history } = this.props;

    const { lab_id, shipping_type, markup_attributes } = priceSheet;
    const { markup_type } = markup_attributes;

    const pricesheetInfo = Object.assign(priceSheet, {
      lab_id: lab_id && lab_id.value,
      markup_type: markup_type && markup_type.value,
      shipping_type: shipping_type && shipping_type.value,
      markup_attributes: { ...markup_attributes, markup_type: markup_type?.value }
    });

    if (!priceSheet.id) {
      /* new PriceSheet */
      createPricesheetRequest(studioId, pricesheetInfo, (pricesheet) => {
        history.push('/storefront/price-sheets/' + pricesheet.id);
        // Navigate to Price Sheets details page
      });
    } else {
      if (shipping_type?.value !== 'drop_shipping') {
        pricesheetInfo.delete_non_bulk_items = true;
      }

      /* update PriceSheet */
      updatePricesheetRequest(pricesheetInfo, () => {
        const { pager } = this.props;
        // Load Price Sheets again
        this.toggleViewEditModal(false);
        this.fetchPriceSheetsPage(pager);
      });
    }
  };

  handleUpdatePriceSheet = (priceSheet) => {
    const id = this.state.priceSheet.id;
    const { updatePricesheetRequest, getPricesheetListRequest } = this.props;

    const { shipping_type, markup_attributes } = priceSheet;
    const { markup_type } = markup_attributes;

    const shippingTypeValue = typeof shipping_type === 'string' ? shipping_type : shipping_type?.value;

    const updatedPriceSheet = Object.assign(priceSheet, {
      id: id,
      shipping_type: shippingTypeValue,
      markup_attributes: { ...markup_attributes, markup_type: markup_type.value }
    });

    if (shippingTypeValue !== 'drop_shipping') {
      updatedPriceSheet.delete_non_bulk_items = true;
    }

    updatePricesheetRequest(updatedPriceSheet, () => {
      this.toggleViewEditModal(false);
      getPricesheetListRequest();
    });
  };

  handleCopyPriceSheet = (id) => {
    const { copyPricesheetRequest, history } = this.props;

    copyPricesheetRequest({ id }, (priceSheet) => {
      history.push('/storefront/price-sheets/' + priceSheet.id);
    });
  };

  handleDeleteAction = (priceSheet) => {
    this.setState({
      deletePriceSheet: {
        display: true,
        name: priceSheet.name,
        priceSheetId: priceSheet.id,
        jobsCount: priceSheet.jobs_count
      }
    });
  };

  toggleDeletePriceSheet = () => {
    this.setState({
      deletePriceSheet: {
        display: false,
        name: '',
        priceSheetId: null,
        jobsCount: 0
      }
    });
  };

  toggleDeleteWarningModal = () => this.setState(({ showDeleteWarningModal }) => ({ showDeleteWarningModal: !showDeleteWarningModal }));

  handleDelete = (priceSheetId, force = false) => {
    const { deletePricesheetRequest } = this.props;

    deletePricesheetRequest(priceSheetId, force, ({ data }) => {
      const { pager } = this.props;
      const { success, published_jobs } = data;

      if (success) {
        this.toggleDeletePriceSheet();
        this.fetchPriceSheetsPage(pager);
        this.setState({ showDeleteWarningModal: false });
      } else {
        this.toggleDeleteWarningModal();
        this.setState((prevState) => ({ deletePriceSheet: { ...prevState.deletePriceSheet, display: false }, priceSheetPublishedJobs: published_jobs }));
      }
    });
  };

  render() {
    const { studioId, result, roles, requesting, pager, entities, labId, labSelected } = this.props;
    const { pricesheets } = entities;
    const { showViewEditModal, showDeleteWarningModal, priceSheet, deletePriceSheet, studioLabs, edit, priceSheetPublishedJobs } = this.state;

    // Permissions
    const canManagePriceSheet = roles.includes('manage_price_sheets');

    const gridHeader = [
      {
        fieldName: 'created_at',
        displayName: 'Created On',
        sortable: true
      },
      {
        fieldName: 'name',
        displayName: 'Name',
        sortable: true
      },
      {
        fieldName: 'price_sheet_type',
        displayName: 'Type',
        sortable: true
      },
      {
        fieldName: 'lab.name',
        displayName: 'Lab',
        sortable: true
      },
      {
        fieldName: 'shipping_type',
        displayName: 'Shipping Type',
        sortable: true
      },
      {
        fieldName: null,
        displayName: 'Actions',
        sortable: false
      }
    ].filter((header) => {
      if (!VITE_BULK_SHIPPING_ENABLED && header.fieldName === 'shipping_type') {
        return null;
      }

      if (!canManagePriceSheet && header.displayName === 'Actions') {
        return null;
      }

      return header;
    });

    return (
      <>
        <section id="storefront__pricesheets">
          <header className="flex items-center justify-between">
            <div>
              <h2 className="text-headline-sm">Price Sheets</h2>
              <p className="text-type-base">Create custom price sheets for your jobs.</p>
            </div>
            {studioId !== 'all-studios' && roles.includes('manage_price_sheets') && (
              <div>
                <Tooltip title="Add Price Sheet" position="top" arrow={false} delay={300} touchHold={true}>
                  <button
                    className="button button--outline button--small pricesheet__action"
                    name="view"
                    type="button"
                    disabled={requesting}
                    onClick={() => this.toggleViewEditModal(true, { lab_id: labId })}
                  >
                    <i className="icon-add"></i>
                  </button>
                </Tooltip>
              </div>
            )}
          </header>

          <PhotoDayGrid
            headers={gridHeader}
            pager={pager}
            fetchRecordsPage={this.fetchPriceSheetsPage}
            table_id="price-sheets-table"
            extraClasses="price-sheets-table"
            borderClass="green-border color-border"
            requesting={requesting && result.length === 0}
            defaultContent="You have not added any price sheets yet."
          >
            {result &&
              result.map((priceSheet) => {
                return (
                  <PriceSheetsRow
                    key={pricesheets[priceSheet].id}
                    priceSheet={pricesheets[priceSheet]}
                    canManagePriceSheet={canManagePriceSheet}
                    deleteFunc={this.handleDeleteAction}
                    cloneFunc={this.handleCopyPriceSheet}
                  />
                );
              })}
          </PhotoDayGrid>

          {/* Modal delete price sheet  */}
          {deletePriceSheet.display && (
            <DeleteModal
              entity="price sheet"
              requesting={requesting}
              title="Delete a Price Sheet"
              onConfirm={this.handleDelete}
              isVisible={deletePriceSheet.display}
              currentId={deletePriceSheet.priceSheetId}
              toggleModal={this.toggleDeletePriceSheet}
            >
              <p>
                Are you sure you want to delete this price sheet, <span className="font-semibold">{deletePriceSheet.name}</span>?<br />
                This action cannot be undone.
              </p>
              {deletePriceSheet.jobsCount > 0 ? (
                <p>
                  <br />
                  The price sheet is currently being used by at least one job.
                  <br />
                  Any galleries using this price sheet will immediately be switched to Draft. <br />
                </p>
              ) : null}
            </DeleteModal>
          )}

          {showViewEditModal && (
            <PriceSheetsModal
              labId={labId}
              labSelected={labSelected}
              labs={studioLabs}
              disableLabs={edit}
              requesting={requesting}
              isVisible={showViewEditModal}
              toggleModal={this.toggleViewEditModal}
              priceSheet={selectInitialValuesFromPriceSheet(priceSheet)}
              onSubmit={edit ? this.handleUpdatePriceSheet : this.handleSubmitPriceSheet}
            />
          )}
        </section>

        {/* Modal delete warning */}
        <aside className={`modal ${showDeleteWarningModal ? '' : 'transparent'}`}>
          <div className="modal__box">
            <header className="modal__header">
              <button className="button button--action modal__close" name="button" type="button" onClick={this.toggleDeleteWarningModal}>
                <i className="icon-close"></i>
              </button>
              <h3 className="uppercase">Warning</h3>
            </header>
            <main className="modal__content">
              <p>This price sheet is currently attached to the following jobs:</p>
              <ul>
                {priceSheetPublishedJobs.map((job) => (
                  <li key={job.id}>
                    <strong>{job.name}</strong>
                  </li>
                ))}
              </ul>
              <br />
              <p>
                Once deleted, the jobs listed above will move to Draft status and will no longer be on-sale. Don't forget to attach another price sheet and
                choose themes accordingly, then update the status to Published when finished.
              </p>
            </main>
            <footer className="grid justify-center gap-5 modal__footer">
              <button
                className="button button--danger button--large"
                name="button"
                type="button"
                onClick={() => this.handleDelete(deletePriceSheet.priceSheetId, true)}
                disabled={requesting}
              >
                Delete
              </button>
              <button className="button button--clean button--large" name="button" type="button" onClick={this.toggleDeleteWarningModal}>
                Cancel
              </button>
            </footer>
          </div>
        </aside>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PriceSheets));
