import { FC, useState, useRef } from 'react';

// Plugins
import pLimit from 'p-limit';
import Dropzone, { FileWithPath, FileRejection, DropzoneRef } from 'react-dropzone';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Images
import iconUploadCloud from '@/assets/images/icon-cloud-upload.svg';
import { createKnockoutItem, updateKnockoutDropzone } from '../../../../actions';

// Hooks & Helpers
import loadExif from '@/utils/loadExif';

// Styles
import './style.css';

// Types
import { ImageFile } from '@/types';

interface UploadDropzoneProps {
  onClose: () => void;

  match: {
    params: {
      jobId: string;
      id: string;
    };
  };
}

const MAX_PARALLEL_IMAGES = 10;

const UploadDropzone: FC<UploadDropzoneProps> = ({ match, onClose }) => {
  const dropzoneRef = useRef<DropzoneRef>(null);

  const dispatch = useDispatch();

  const { job, knockoutUpload } = useSelector((state: any) => state.jobs);

  const {
    params: { id: postProcessingId }
  } = match;

  const galleryType = job.access_mode === 'access_per_subject' ? 'private' : 'public';

  const [fileDialogType, setFileDialogType] = useState<string>('');
  const [isDropzoneActive, setIsDropzoneActive] = useState<boolean>(false);
  const [showChecklistModal, setShowChecklistModal] = useState<boolean>(false);

  const handleDrop = (droppedFiles: File[]) => {
    onClose();
    dispatch(updateKnockoutDropzone({ processing: droppedFiles.length ? true : false, completed: 0 }));
  };

  const handleDropAccepted = (acceptedFiles: File[]) => {
    const imageFiles = acceptedFiles
      .filter((file: File) => !knockoutUpload.queue.some((queue: any) => queue.name === file.name))
      .filter((file: FileWithPath) => !file.name.startsWith('.') && !file?.path?.includes('__macosx'));

    // Magically add EXIF properties inside each file
    const exifPromises: any = [];
    const imageParallelLimit = pLimit(MAX_PARALLEL_IMAGES);

    imageFiles.forEach((file: File) => exifPromises.push(imageParallelLimit(() => loadExif(file))));

    Promise.all(exifPromises).then(() => {
      // Check files width and height acceptance first
      const rejectedFiles: FileRejection[] = [];

      imageFiles.forEach((file: ImageFile) => {
        const { data, type: fileType } = file;

        if (data) {
          const imageWidth: number = data['Image Width']?.value || 0;
          const imageHeight: number = data['Image Height']?.value || 0;

          if (imageWidth > 6400 || imageHeight > 6400 || imageWidth * imageHeight > 27000000) {
            rejectedFiles.push({ file, errors: [{ code: 'dimensions-too-large', message: 'Image resolution exceeds maximum allowed.' }] });
          }

          if (fileType === 'image/png') {
            rejectedFiles.push({ file, errors: [{ code: 'invalid-type', message: 'Invalid File Type' }] });
          }
        }
      });

      if (rejectedFiles.length) {
        handleDropRejected(rejectedFiles);
      }

      // Filter out rejectedFiles
      const imageFilesFiltered = imageFiles.filter(
        (file: File) => rejectedFiles.some((rejectedFile: FileRejection) => rejectedFile.file.name === file.name) === false
      );

      const shouldHold = galleryType !== 'private' && imageFiles.some((file: ImageFile) => file.data?.keywords?.length);

      dispatch(updateKnockoutDropzone({ knockoutId: postProcessingId, queue: imageFilesFiltered, hold: shouldHold }));
      dispatch(createKnockoutItem());
    });
  };

  const handleDropRejected = (rejectedFiles: FileRejection[]) => {
    const filteredRejectedFiles = rejectedFiles
      .map(({ file, errors }) => {
        function getReason(): string {
          if (file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
            return 'Invalid File Type';
          }

          if (file.size < 30720) {
            return 'File should be at least 30KB';
          }

          if (file.size > 15728640) {
            return 'File should not exceed 15MB';
          }

          return errors[0]?.message || 'File error';
        }

        return { name: file.name, reason: getReason() };
      })
      .filter((file) => !file.name.startsWith('.') && !file.name.toLowerCase().startsWith('thumbs'))
      .filter((file) => !knockoutUpload.rejected.some((rejected: File) => rejected.name === file.name));

    dispatch(updateKnockoutDropzone({ rejected: filteredRejectedFiles }));
  };

  const handleDialogTypeChange = (type: any) => {
    setFileDialogType(type);
  };

  const handleDialogOpen = () => {
    if (dropzoneRef?.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <>
      <Dropzone
        ref={dropzoneRef}
        disabled={knockoutUpload.queue.length > 0}
        multiple={true}
        maxSize={15728640} // 15MB
        minSize={30720} // 30KB
        accept={{ 'image/*': ['.jpeg', '.jpg'] }}
        noClick={true}
        noKeyboard={true}
        noDragEventsBubbling={true}
        preventDropOnDocument={true}
        useFsAccessApi={false}
        onDrop={handleDrop}
        onDropAccepted={handleDropAccepted}
        onDropRejected={handleDropRejected}
        onDragEnter={() => setIsDropzoneActive(true)}
        onDragLeave={() => setIsDropzoneActive(false)}
      >
        {({ getRootProps, getInputProps }) => (
          <>
            <aside className="modal animate" {...getRootProps()}>
              <div className="modal__box">
                <section
                  className={`flex flex-col items-center justify-center job-postprocessing__upload ${isDropzoneActive ? 'job-postprocessing__upload--active' : ''}`}
                >
                  <button className="button button--action modal__close" name="button" type="button" onClick={() => onClose()}>
                    <i className="icon-close"></i>
                  </button>
                  {knockoutUpload.queue.length ? (
                    <>
                      <img
                        className="job-postprocessing__upload-splash-icon job-postprocessing__upload-splash-icon--disabled"
                        src={iconUploadCloud}
                        alt="Icon Upload Cloud"
                      />
                      <h2>Upload in progress.</h2>
                      <h5>Please wait until current upload is complete before uploading more photos.</h5>
                    </>
                  ) : (
                    <>
                      <input
                        {...getInputProps({ disabled: false })}
                        {...(fileDialogType === 'folder' ? { webkitdirectory: '', mozdirectory: '', directory: '' } : {})}
                      />
                      <img className="job-postprocessing__upload-splash-icon" src={iconUploadCloud} alt="Icon Upload Cloud" />
                      <h2 className="job-postprocessing__upload-main-title">
                        Drag files to be post-processed here <br />
                        or upload{' '}
                        <button className="button button--link" onMouseEnter={() => handleDialogTypeChange('folder')} onClick={handleDialogOpen}>
                          folders
                        </button>{' '}
                        or{' '}
                        <button className="button button--link" onMouseEnter={() => handleDialogTypeChange('file')} onClick={handleDialogOpen}>
                          photos
                        </button>
                        .
                      </h2>

                      <p className="text-center mb-6">
                        Photos must be in JPG format, embedded color profile must be sRGB, file size justify-between 30K and 15MB, and a maximum of 27 million
                        pixels (length x width of pixel dimensions must equal less than 27 million). In addition, the maximum side length should not exceed
                        6400px.
                      </p>

                      {galleryType !== 'private' && (
                        <p className="text-center mb-6">
                          When moving post-processed photos to a Group or Public gallery, folders and keyword formatting will translate into tags.{' '}
                          <a
                            href="https://support.photoday.io/en/articles/3307091-tagging-best-practices"
                            className="text-primary-blue-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn more about tags
                          </a>
                          .
                        </p>
                      )}

                      <p>
                        Need help? Check out our{' '}
                        <button className="button button--link" type="button" name="preflight" onClick={() => setShowChecklistModal(true)}>
                          pre-flight checklist
                        </button>
                        .
                      </p>
                    </>
                  )}
                </section>
              </div>
            </aside>
          </>
        )}
      </Dropzone>

      {/* Pre-flight modal */}
      {showChecklistModal && (
        <aside className="modal animate">
          <div className="modal__box text-left job-postprocessing__upload-checklist">
            <header className="modal__header">
              <button className="button button--action modal__close" name="button" type="button" onClick={() => setShowChecklistModal(false)}>
                <i className="icon-close"></i>
              </button>
              <h3>Pre-flight Checklist</h3>
            </header>
            <ul className="list--bullet">
              <li>
                <p>Photos should be in a JPG/JPEG file format and be no greater than 15MB per photo.</p>
              </li>
              <li>
                <p>If there is a color profile embedded in the files, it must be sRGB to ensure consistent color results from our partner labs.</p>
              </li>
              <li>
                <p className="m-0">
                  Photo resolution limits: Total pixels (length x width) less than 27 million & no side greater than 6400 pixels. Acceptable pixel dimensions
                  based on popular aspect ratios:
                </p>
                <ul className="list--bullet">
                  <li>4x6 or 2x3 - 6300x4200</li>
                  <li>5x7 or 3.5x5 - 6145x4390</li>
                  <li>8x10 or 4x5 - 5800x4640</li>
                  <li>1x1 - 5150x5150</li>
                </ul>
              </li>
              <li>
                <p>It’s recommended that files names are no more than 31 characters long.</p>
              </li>
              <li>
                <p>
                  If you attempt to upload a file with special characters like {`“< > : ” \\ / ? | * ~” [ ]`} we will strip them out and still accept the file,
                  but it might result in an image error.
                </p>
              </li>
              <li>
                <p>If a photo is in a folder or its folder is nested in a folder, the photo will only be tagged with the folder name it’s in.</p>
              </li>
              <li>
                <p>If a photo is uploaded and has Lightroom keywords, the keywords will become that photo’s tags (Group/Public galleries only).</p>
              </li>
              <li>
                <p>
                  If a folder containing photos that has Lightroom keywords is uploaded, you’ll have the option to choose either the keywords or the folder name
                  as the photo’s tag.
                </p>
              </li>
            </ul>
          </div>
        </aside>
      )}
    </>
  );
};

export default UploadDropzone;
