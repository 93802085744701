import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getMyAccountStudioRequest } from '../../Settings/actions';

// Plugins
import moment, { Moment } from 'moment';

// Images
import iconInfoBookmark from '@/assets/images/icon-info-bookmark.svg';
import iconInfoShield from '@/assets/images/icon-info-shield.svg';
import iconInfoWarning from '@/assets/images/icon-info-warning.svg';
import iconInfo from '@/assets/images/icon-info.svg';

// Styles
import './style.css';

// Types
import { Studio, User } from '@/types';

enum VerificationStage {
  Inform = 'inform',
  Warning = 'warning',
  Disabled = 'disabled',
  Reverify = 'reverify'
}

interface BannerContent {
  icon: string;
  title: string;
  description: string;
  link?: string;
  buttonText: string;
}

const VERIFICATION_DAYS_INFORM: number = 30;
const VERIFICATION_DAYS_WARNING: number = 10;
const VERIFICATION_DAYS_DISABLED: number = 0;

const bannerContent: Record<VerificationStage, BannerContent> = {
  [VerificationStage.Inform]: {
    icon: iconInfoBookmark,
    title: 'Congrats! You’re now a high-volume seller!',
    description:
      'In compliance with the INFORM Consumers Act (ICA), all high-volume sellers must verify their business to continue selling on PhotoDay. ICA provides a national standard that ensures consumers a safe online buying experience.',
    link: 'https://support.photoday.io/en/articles/8828210-informed-consumers-act-ica-faq',
    buttonText: 'Verify Now'
  },
  [VerificationStage.Warning]: {
    icon: iconInfo,
    title: 'Please verify your business to continue selling.',
    description:
      "As a high-volume seller in PhotoDay, you must verify your business to continue selling products to customers. If you don’t verify, your customers won't be able to view or order from their galleries.",
    link: 'https://support.photoday.io/en/articles/8828210-informed-consumers-act-ica-faq',
    buttonText: 'Verify Now'
  },
  [VerificationStage.Disabled]: {
    icon: iconInfoWarning,
    title: 'Customer Galleries are currently disabled. Please verify your business.',
    description:
      'Your customers cannot access their galleries, view photos, or purchase products until you successfully verify your business. This is in compliance with the INFORM Consumers Act (ICA) to ensure a safe online buying experience for consumers.',
    link: 'https://support.photoday.io/en/articles/8828210-informed-consumers-act-ica-faq',
    buttonText: 'Verify Now'
  },
  [VerificationStage.Reverify]: {
    icon: iconInfoShield,
    title: 'It’s time to reverify your account',
    description:
      'Let us know if your business information has changed in the past year, if anything has changed you must go through the verification process again to make sure your information is up to date on our platform.',
    buttonText: 'Review Business Information'
  }
};

const ALL_STUDIOS = 'all-studios';
const MANAGE_STUDIO_SETTINGS = 'manage_studio_settings';

const VerificationBanner: FC = () => {
  const dispatch = useDispatch();

  const { studio: loginStudio, user: loginUser } = useSelector((state: any) => state.login);
  const { studio: usersStudio, user: usersUser, requesting: usersRequesting } = useSelector((state: any) => state.users);

  const studio: Studio = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;
  const { id: studioId } = studio;
  const user: User = Object.keys(usersUser ?? {}).length ? usersUser : loginUser;
  const { roles: userRoles } = user;

  const shouldSeeCta = studioId !== ALL_STUDIOS && userRoles?.some((role: string) => role === MANAGE_STUDIO_SETTINGS);

  const [currentStage, setCurrentStage] = useState<VerificationStage>();
  const [daysRemaining, setDaysRemaining] = useState<number>();

  useEffect(() => {
    dispatch(getMyAccountStudioRequest({ studioId }));
  }, []);

  useEffect(() => {
    const {
      verification_required: isStudioVerificationRequired,
      verification_deadline: studioVerificationDeadline,
      studio_verification: studioVerification
    } = studio;
    const { reverify: studioReverify } = studioVerification ?? {};

    if (isStudioVerificationRequired) {
      let currentStage;

      if (studioReverify) {
        // Reverify
        currentStage = VerificationStage.Reverify;
      } else {
        // Verify
        const todaysDate: Moment = moment.utc().startOf('day');
        const deadlineDate: Moment = moment.utc(studioVerificationDeadline).startOf('day');
        const todaysDiffFromDeadline: number = deadlineDate.diff(todaysDate, 'days') + 1;

        if (todaysDiffFromDeadline <= VERIFICATION_DAYS_INFORM && todaysDiffFromDeadline > VERIFICATION_DAYS_WARNING) {
          currentStage = VerificationStage.Inform;
        } else if (todaysDiffFromDeadline <= VERIFICATION_DAYS_WARNING && todaysDiffFromDeadline > VERIFICATION_DAYS_DISABLED) {
          currentStage = VerificationStage.Warning;
        } else if (todaysDiffFromDeadline <= VERIFICATION_DAYS_DISABLED) {
          currentStage = VerificationStage.Disabled;
        }

        setDaysRemaining(todaysDiffFromDeadline);
      }

      if (currentStage) setCurrentStage(currentStage);
    }
  }, [loginStudio, usersStudio]);

  if (!currentStage || usersRequesting) return null;

  return (
    <aside
      className={`flex justify-between flex-nowrap wrap-md gap-7 panel panel--spacious animate business-verification__banner business-verification__banner--${currentStage}`}
    >
      <hgroup className="basis-10/12 md:basis-full">
        <h1 className={`flex gap-2.5 items-center font-semibold business-verification__title--${currentStage}`}>
          <img src={bannerContent[currentStage]?.icon} height="24" alt="" />
          {bannerContent[currentStage]?.title}
        </h1>
        <h5 className="text--normal m-0">
          {bannerContent[currentStage]?.description}{' '}
          {bannerContent[currentStage]?.link && (
            <a href={bannerContent[currentStage]?.link} className="font-semibold" target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
          )}
        </h5>
      </hgroup>
      <aside className="basis-2/12 md:basis-full flex flex-col justify-center gap-2.5">
        {shouldSeeCta && (
          <Link className={`button font-semibold button--block business-verification__button--${currentStage}`} to="/user/my-studio/verification">
            {bannerContent[currentStage]?.buttonText}
          </Link>
        )}
        {Number(daysRemaining) >= 0 && (
          <span className={`text-body-sm font-semibold ${daysRemaining === 0 ? 'text-error-500' : ''}`}>{daysRemaining} Days Remaining</span>
        )}
      </aside>
    </aside>
  );
};

export default VerificationBanner;
