import { memo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setPhotoQueryComponent } from '../../actions';

// Plugins
import Select from 'react-select';
import { Tooltip } from 'react-tippy';

// Components
import Dropdown from '@/components/Shared/Dropdown';
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';

// Styles
import './style.css';

const SERVICES_ENABLED = import.meta.env.VITE_SERVICES_ENABLED === '1';
const CAPTURE_QR_ENABLED = import.meta.env.VITE_CAPTURE_QR_ENABLED === '1';

const TopBar = memo(function TopBar({
  job,
  queue: uploadQueue,
  people,
  search,
  filterTags,
  galleryType,
  filterPhotos,
  filterPeople,
  faceSelected,
  photoSelected,
  facesRequesting,
  unselectedPhotos,
  downloadsDisabled,
  allPhotosSelected,
  uploadDropzoneRef,
  qrSessionsTotalErrors,
  filterPeopleUnmatched,
  onPhotosSort,
  onDeleteShow,
  onPhotoRemove,
  onTagModalShow,
  onPhotosSearch,
  onPhotosFilter,
  onPhotoSelectAll,
  onDigitalDownload,
  onShowMatchToggle,
  onFaceSelectClear,
  onToggleIsMenuOpen,
  onPhotoSelectClear,
  onPhotosSearchClear,
  onShowLightboxToggle,
  onPhotosSearchChange,
  onRemoveTagsFromPhotos,
  onShowBulkFeatureToggle,
  onShowBulkYearbookToggle,
  onQrResolutionModalToggle,
  onClearSubjectsSearchBy
}) {
  const dispatch = useDispatch();

  const {
    tags: { list: tags },
    job: {
      requesting: jobRequesting,
      setup_steps: jobSetupSteps,
      photo_stats: jobPhotoStats,
      biometrics_enabled: jobBiometricsEnabled,
      qr_sessions: jobQrSessions,
      yearbook_selection_photo_count: jobYearbookSelectionPhotoCount,
      yearbook_selection_photo_1_title: jobYearbookSelectionPhoto1Title,
      yearbook_selection_photo_2_title: jobYearbookSelectionPhoto2Title
    },
    query: { people: peopleQuery, tags: tagsQuery },
    photos: { pagination, requesting: photosRequesting }
  } = useSelector((state) => state.jobs);

  const {
    studio: { feature_flags: studioFeatureFlags }
  } = useSelector((state) => state.login);
  const isStudioCaptureQrAllowed = CAPTURE_QR_ENABLED && studioFeatureFlags?.allow_capture_qr ? true : false;

  const requesting = facesRequesting || jobRequesting || photosRequesting;

  const [navOptions, setNavOptions] = useState();
  const [navSelected, setNavSelected] = useState();

  const [subjectFeaturedSelected, setSubjectFeaturedSelected] = useState();
  const [subjectYearbookSelected, setSubjectYearbookSelected] = useState();

  const areAllPhotosTrained = jobPhotoStats?.photos > 0 && jobPhotoStats.photos_trained === jobPhotoStats.photos;

  const isFeaturedFilterApplied = filterPhotos === 'featured';
  const isYearbookFilterApplied = filterPhotos === 'yearbook';

  const hasQrSessionErrors = isStudioCaptureQrAllowed && qrSessionsTotalErrors > 0;

  const isPhotoActionsDisabled =
    isFeaturedFilterApplied || isYearbookFilterApplied || (isStudioCaptureQrAllowed && (!areAllPhotosTrained || hasQrSessionErrors));

  const arePhotosSelected = photoSelected.length > 0;
  const isNoPhotoSelected = photoSelected.length === 0;
  const isIgnoredFilterNotApplied = filterPhotos !== 'ignored';

  const photoActionsShow = arePhotosSelected && !isPhotoActionsDisabled;
  const addPhotosShow = !arePhotosSelected && !isFeaturedFilterApplied && !isYearbookFilterApplied;
  const galleryActionsShow = isNoPhotoSelected && !isPhotoActionsDisabled && isIgnoredFilterNotApplied;

  const featuredActionsShow = isFeaturedFilterApplied;
  const yearbookActionsShow = isYearbookFilterApplied;

  const privateActionsShow = galleryType === 'private';
  const publicActionsShow = galleryType !== 'private';

  const areSubjectsAndPhotosUploaded = jobSetupSteps.uploaded_subjects && jobSetupSteps.uploaded_photos;

  const bulkFeaturedDisabled = !areSubjectsAndPhotosUploaded;
  const bulkYearbookDisabled = !areSubjectsAndPhotosUploaded;

  const getNavOptions = () => {
    const photoOptionsPrivate = [
      { value: 'all', label: 'All Photos', count: jobPhotoStats.photos >= 0 ? `(${jobPhotoStats.photos})` : '' },
      {
        value: 'unmatched',
        label: 'Unmatched',
        count: jobPhotoStats.photos_with_unmatched_faces >= 0 ? `(${jobPhotoStats.photos_with_unmatched_faces})` : ''
      },
      ...(jobBiometricsEnabled
        ? [
            {
              value: 'ignored',
              label: 'Ignored Faces',
              count: jobPhotoStats.faces_ignored >= 0 ? `(${jobPhotoStats.faces_ignored})` : ''
            }
          ]
        : [])
    ];

    const photoOptions = [
      { value: 'all', label: 'All Photos', count: jobPhotoStats.photos >= 0 ? `(${jobPhotoStats.photos})` : '' },
      { value: 'untagged', label: 'Untagged', count: jobPhotoStats.photos_untagged >= 0 ? `(${jobPhotoStats.photos_untagged})` : '' }
    ];

    const peopleOptions = [
      { value: 'featured', label: 'Subjects Overview' },
      { value: 'subjects', label: 'Subjects Detail', count: filterPeople.id ? `(${filterPeople.photos_count})` : '' }
    ];

    const photoSelectionOptions = job?.yearbook_selection_enabled ? [{ value: 'yearbook', label: 'Yearbook' }] : [];

    const tagOptions = [
      {
        value: 'tags',
        label: 'Tags Detail',
        count: filterTags.length && pagination.total >= 0 ? `(${pagination.total})` : ''
      }
    ];

    if (jobPhotoStats.last_uploaded && jobPhotoStats.last_uploaded >= 0 && !uploadQueue.length) {
      const lastUploadOption = {
        value: 'last_uploaded',
        label: 'Last Uploaded',
        count: jobPhotoStats.last_uploaded >= 0 ? `(${jobPhotoStats.last_uploaded})` : ''
      };

      galleryType === 'private' ? photoOptionsPrivate.push(lastUploadOption) : photoOptions.push(lastUploadOption);
    }

    if (SERVICES_ENABLED && jobPhotoStats.photos_with_transparency && jobPhotoStats.photos_with_transparency >= 0) {
      const lastUploadOption = {
        value: 'has_transparency',
        label: 'Knocked Out',
        count: jobPhotoStats.photos_with_transparency >= 0 ? `(${jobPhotoStats.photos_with_transparency})` : ''
      };

      galleryType === 'private' ? photoOptionsPrivate.push(lastUploadOption) : photoOptions.push(lastUploadOption);
    }

    if (galleryType === 'private' && jobPhotoStats.subjects) {
      const newOptions = [
        {
          label: 'Photos',
          options: photoOptionsPrivate
        },
        {
          label: 'Subjects',
          options: peopleOptions
        },
        {
          label: 'Photo Selection',
          options: photoSelectionOptions
        }
      ];

      let newNavSelected;

      if (filterPeople.id) {
        newNavSelected = peopleOptions[1];
      } else if ((filterPhotos && navSelected && navSelected.value !== filterPhotos) || (navSelected && !navSelected.value)) {
        // Change current navSelected because filterPhotos changed
        let newOption = newOptions?.find((option) => option?.options?.find((innerOption) => innerOption.value === filterPhotos));

        if (newOption?.options?.length) {
          newNavSelected = newOption.options.find((option) => option.value === filterPhotos);
        }
      } else if (navSelected && navSelected.value) {
        // Update navSelected to reflect change to navSelected photos count navigate to all photos if all last_uploaded photos are deleted
        if (jobPhotoStats.last_uploaded !== 0 || navSelected.value !== 'last_uploaded') {
          let newOption = newOptions?.find((option) => option?.options?.find((innerOption) => innerOption.value === navSelected.value));

          if (newOption?.options?.length) {
            newNavSelected = newOption?.options?.find((option) => option.value === navSelected.value);
          }
        } else {
          newNavSelected = newOptions[0]?.options[0];
        }
      } else {
        newNavSelected = photoOptionsPrivate[0];
      }

      setNavSelected(newNavSelected);
      setNavOptions(newOptions);
    } else if (galleryType !== 'private') {
      const newOptions = [
        {
          label: 'Photos',
          options: photoOptions
        }
      ];

      if (tags && tags.length > 0) {
        newOptions.push({
          label: 'Tags',
          options: tagOptions
        });
      }

      let newNavSelected;

      if ((filterPhotos && navSelected && navSelected.value !== filterPhotos) || (navSelected && !navSelected.value)) {
        // change current navSelected because filterPhotos changed

        const optionFound = newOptions?.find((option) => option?.options?.find((innerOption) => innerOption.value === filterPhotos));
        newNavSelected = optionFound ? optionFound.options?.find((option) => option.value === filterPhotos) : photoOptions[0];
      } else if (navSelected && navSelected.value) {
        // update navSelected to reflect change to navSelected photos count

        const optionFound = newOptions?.find((option) => option?.options?.find((innerOption) => innerOption.value === navSelected.value));
        newNavSelected = optionFound ? optionFound.options?.find((option) => option.value === navSelected.value) : photoOptions[0];
      } else {
        newNavSelected = photoOptions[0];
      }

      setNavSelected(newNavSelected);
      setNavOptions(newOptions);
    } else {
      setNavSelected({ value: 'all', label: 'All Photos', count: jobPhotoStats.photos >= 0 ? `(${jobPhotoStats.photos})` : '' });
      setNavOptions([
        {
          label: 'Photos',
          options: [{ value: 'all', label: 'All Photos', count: jobPhotoStats.photos >= 0 ? `(${jobPhotoStats.photos})` : '' }]
        }
      ]);
    }
  };

  const handleSelectChange = (option) => {
    onPhotosFilter(option.value);
    setNavSelected(option);
  };

  const handleSelectFeaturedChange = (option) => {
    setSubjectFeaturedSelected(option);
    dispatch(setPhotoQueryComponent({ people: option.value }));
  };

  const handleSelectYearbookChange = (option) => {
    setSubjectYearbookSelected(option);
    dispatch(setPhotoQueryComponent({ people: option.value }));
  };

  const formatGroupLabel = (data) => (
    <div className="job-topbar__select-group">
      <span>{data.label}</span>
    </div>
  );

  const formatOptionLabel = (data) => (
    <div className="job-topbar__select-option">
      <span>{data.label} </span>
      {data.count && <span className="font-semibold">{data.count}</span>}
    </div>
  );

  useEffect(() => {
    if (filterTags.length && navSelected.value === 'tags') {
      // Update existing navSelected as count updates
      const newTagsOption = {
        value: 'tags',
        label: 'Tags Detail',
        count: filterTags.length && pagination.total >= 0 ? `(${pagination.total})` : ''
      };

      const newNavOptions = navOptions.map((option) =>
        option.label !== 'Tags'
          ? option
          : {
              ...option,
              options: option?.options?.map((innerOption) => (innerOption.value !== 'tags' ? innerOption : newTagsOption))
            }
      );

      setNavSelected(newTagsOption);
      setNavOptions(newNavOptions);
    }

    // Remove Tags Detail count when another option is choosen
    if ((filterTags.length === 0 || filterPhotos === 'untagged') && navSelected && navSelected.value !== 'tags') {
      const newNavOptions = navOptions.map((option) =>
        option.label !== 'Tags'
          ? option
          : {
              ...option,
              options: option?.options?.map((innerOption) =>
                innerOption.value !== 'tags'
                  ? innerOption
                  : {
                      value: 'tags',
                      label: 'Tags Detail'
                    }
              )
            }
      );

      setNavOptions(newNavOptions);
    }
  }, [filterTags, pagination.total]);

  useEffect(() => {
    getNavOptions();
  }, [galleryType, jobPhotoStats, tags, filterPeople, filterPhotos, peopleQuery, tagsQuery]);

  useEffect(() => {
    if ((peopleQuery && peopleQuery === 'search') || (tagsQuery && tagsQuery === 'search')) {
      setNavSelected({ ...navSelected, label: 'Search Results', count: pagination.total >= 0 ? `(${pagination.total})` : '(0)' });
    }
  }, [peopleQuery, tagsQuery]);

  useEffect(() => {
    // Reset second dropdown selection
    // Featured (primary)
    if (navSelected && navSelected.value === 'featured' && !subjectFeaturedSelected) {
      setSubjectFeaturedSelected({
        value: 'subjectsAll',
        label: 'All Subjects',
        count: jobPhotoStats ? `(${jobPhotoStats.subjects})` : ''
      });

      onClearSubjectsSearchBy();
    }

    if (navSelected && navSelected.value !== 'featured' && subjectFeaturedSelected) {
      setSubjectFeaturedSelected();
    }

    // Yearbook Selection
    if (navSelected && navSelected.value === 'yearbook' && !subjectYearbookSelected) {
      setSubjectYearbookSelected({
        value: 'subjectsAll',
        label: 'All Subjects',
        count: jobPhotoStats ? `(${jobPhotoStats.subjects})` : ''
      });
    }

    if (navSelected && navSelected.value !== 'yearbook' && subjectYearbookSelected) {
      setSubjectYearbookSelected();
    }
  }, [navSelected]);

  return (
    <>
      <header className={`job-topbar__header ${filterPhotos === 'tags' || filterPhotos === 'subjects' ? 'job-topbar__header--tall' : ''}`}>
        <aside className="flex justify-between gap-2.5">
          <div className="flex gap-2.5 animate">
            {/* Photos & Subjects main dropdown */}
            <Select
              className="select job-topbar__header-select"
              classNamePrefix="select"
              isSearchable={false}
              placeholder="All photos"
              value={navSelected}
              maxMenuHeight={400}
              onMenuOpen={() => onToggleIsMenuOpen(true)}
              onMenuClose={() => onToggleIsMenuOpen(false)}
              formatGroupLabel={formatGroupLabel}
              formatOptionLabel={formatOptionLabel}
              options={navOptions}
              onChange={(select) => handleSelectChange(select)}
            />
            {privateActionsShow && (
              <>
                {featuredActionsShow && (
                  <Select
                    className="select job-topbar__header-select"
                    classNamePrefix="select"
                    isSearchable={false}
                    placeholder="All Subjects"
                    value={subjectFeaturedSelected}
                    maxMenuHeight={400}
                    formatOptionLabel={formatOptionLabel}
                    options={[
                      {
                        value: 'subjectsAll',
                        label: 'All Subjects',
                        count: `(${jobPhotoStats.subjects ?? 0})`
                      },
                      {
                        value: 'subjectsWith',
                        label: 'Subjects with a featured photo',
                        count: `(${jobPhotoStats.subjects_with_featured ?? 0} of ${jobPhotoStats.subjects ?? 0})`
                      },
                      {
                        value: 'subjectsWithout',
                        label: 'Subjects with no featured photo',
                        count: `(${jobPhotoStats.subjects_without_featured ?? 0} of ${jobPhotoStats.subjects ?? 0})`
                      },
                      {
                        value: 'subjectsMatchedWithout',
                        label: 'Subjects with matched photo(s) and NO featured photo',
                        count: Number.isFinite(jobPhotoStats.subjects_matched_without_featured)
                          ? `(${jobPhotoStats.subjects_matched_without_featured} of ${jobPhotoStats.subjects ?? 0})`
                          : ''
                      }
                    ]}
                    onChange={(select) => handleSelectFeaturedChange(select)}
                  />
                )}
                {yearbookActionsShow && (
                  <Select
                    className="select job-topbar__header-select"
                    classNamePrefix="select"
                    isSearchable={false}
                    placeholder="All Subjects"
                    value={subjectYearbookSelected}
                    maxMenuHeight={400}
                    formatOptionLabel={formatOptionLabel}
                    options={[
                      {
                        value: 'subjectsAll',
                        label: 'All Subjects',
                        count: `(${jobPhotoStats.subjects ?? 0})`
                      },
                      {
                        value: 'subjectsWithYearbookPhoto',
                        label: 'Subjects with a Yearbook Selection',
                        count: `(${jobPhotoStats.subjects_with_yearbook ?? 0} of ${jobPhotoStats.subjects ?? 0})`
                      },
                      ...(jobYearbookSelectionPhotoCount === 2
                        ? [
                            {
                              value: 'subjectsWithOnlyPoseOne',
                              label: `Subjects with only ${jobYearbookSelectionPhoto1Title}`,
                              count: `(${jobPhotoStats.subjects_with_yearbook_photo_1_only ?? 0} of ${jobPhotoStats.subjects ?? 0})`
                            },
                            {
                              value: 'subjectsWithOnlyPoseTwo',
                              label: `Subjects with only ${jobYearbookSelectionPhoto2Title}`,
                              count: `(${jobPhotoStats.subjects_with_yearbook_photo_2_only ?? 0} of ${jobPhotoStats.subjects ?? 0})`
                            },
                            {
                              value: 'subjectsWithBothPoses',
                              label: `Subjects with both ${jobYearbookSelectionPhoto1Title} and ${jobYearbookSelectionPhoto2Title} poses`,
                              count: `(${jobPhotoStats.subjects_with_yearbook_photo_1_and_2 ?? 0} of ${jobPhotoStats.subjects ?? 0})`
                            }
                          ]
                        : []),
                      {
                        value: 'subjectsWithoutYearbookPhoto',
                        label: 'Subjects with no Yearbook Selection',
                        count: `(${jobPhotoStats.subjects_without_yearbook ?? 0} of ${jobPhotoStats.subjects ?? 0})`
                      },
                      {
                        value: 'subjectsMatchedWithoutYearbookPhoto',
                        label: 'Subjects with matched photo(s) and NO yearbook photo',
                        count: Number.isFinite(jobPhotoStats.subjects_matched_without_yearbook)
                          ? `(${jobPhotoStats.subjects_matched_without_yearbook} of ${jobPhotoStats.subjects ?? 0})`
                          : ''
                      }
                    ]}
                    onChange={(select) => handleSelectYearbookChange(select)}
                  />
                )}
              </>
            )}
            {/* FaceFind/QR Progress Bar */}
            {jobBiometricsEnabled && jobPhotoStats?.photos_trained > 0 && jobPhotoStats?.photos_trained < jobPhotoStats?.photos && (
              <div className="animate">
                <small className="flex justify-between mb-1">
                  FaceFind Completed &nbsp;{' '}
                  <span>
                    {jobPhotoStats.photos_trained?.toLocaleString()} of {jobPhotoStats.photos?.toLocaleString()}
                  </span>
                </small>
                <span className="job-topbar__progress">
                  <span
                    className="job-topbar__progress-bar"
                    style={{ width: `${Math.round((jobPhotoStats.photos_trained / jobPhotoStats.photos) * 100)}%` }}
                  ></span>
                </span>
              </div>
            )}
          </div>

          <div className="flex items-center justify-end shrink button-group sm:hidden">
            <div className="flex items-center justify-end button-group animate">
              {galleryActionsShow && (
                <>
                  <small className="job-topbar__subtitle text-neutral-500 animate">0 Selected</small>
                  {/* Search */}
                  <form className="basis-6/12" onSubmit={onPhotosSearch}>
                    <fieldset className="fieldset--clean">
                      <input
                        className="input--clean"
                        type="search"
                        name="search"
                        placeholder="Search File Name"
                        maxLength="50"
                        value={search}
                        onChange={onPhotosSearchChange}
                      />
                      <button
                        className={`button button--clear button--small ${search ? '' : 'hidden'}`}
                        name="button"
                        type="button"
                        onClick={onPhotosSearchClear}
                      >
                        Clear
                      </button>
                      <button className="button button--icon" name="button" type="submit">
                        <i className="icon-search" />
                      </button>
                    </fieldset>
                  </form>

                  {/* QR resolution */}
                  {isStudioCaptureQrAllowed && jobQrSessions?.length > 0 && (
                    <Tooltip title="QR Resolution" position="top" arrow={false}>
                      <button className="button button--outline button--small" name="qr" type="button" onClick={onQrResolutionModalToggle}>
                        <i className="icon-qrcode"></i>
                      </button>
                    </Tooltip>
                  )}
                </>
              )}

              {/* Add Photos */}
              {addPhotosShow && (
                <Tooltip className="sm:hidden" title="Add Photos" position="top" arrow={false}>
                  <Dropdown buttonIcon="icon-add-small" buttonExtraClass={'button--lean button--small button-dropdown'} buttonDisabled={uploadQueue.length > 0}>
                    <ul className="panel panel-dropdown">
                      <li
                        className="panel-dropdown__item"
                        onMouseEnter={() => uploadDropzoneRef.current && uploadDropzoneRef.current.changeFileDialogType('folder')}
                        onClick={() => uploadDropzoneRef.current && uploadDropzoneRef.current.openFileDialog()}
                      >
                        Upload folders
                      </li>
                      <li
                        className="panel-dropdown__item"
                        onMouseEnter={() => uploadDropzoneRef.current && uploadDropzoneRef.current.changeFileDialogType('file')}
                        onClick={() => uploadDropzoneRef.current && uploadDropzoneRef.current.openFileDialog()}
                      >
                        Upload photos
                      </li>

                      {SERVICES_ENABLED && (
                        <li className="panel-dropdown__item">
                          <Link to={`/jobs/${job.id}/services/postprocessing`}>Knockout Photos</Link>
                        </li>
                      )}
                    </ul>
                  </Dropdown>
                </Tooltip>
              )}

              {/* Sort */}
              {galleryActionsShow && filterPhotos !== 'subjects' && (
                <Tooltip title="Sort" position="top" arrow={false}>
                  <Dropdown buttonIcon="icon-sort" buttonExtraClass={'button--lean button--small button-dropdown'}>
                    <ul className="panel panel-dropdown panel-dropdown--large">
                      <li
                        className={`panel-dropdown__item ${job.sort_type === 'alpha_asc' ? 'panel-dropdown__item--active' : ''}`}
                        onClick={() => onPhotosSort('alpha_asc')}
                      >
                        Name: A to Z
                      </li>
                      <li
                        className={`panel-dropdown__item ${job.sort_type === 'alpha_desc' ? 'panel-dropdown__item--active' : ''}`}
                        onClick={() => onPhotosSort('alpha_desc')}
                      >
                        Name: Z to A
                      </li>
                      <li
                        className={`panel-dropdown__item ${job.sort_type === 'upload_asc' ? 'panel-dropdown__item--active' : ''}`}
                        onClick={() => onPhotosSort('upload_asc')}
                      >
                        Upload Date: New to Old
                      </li>
                      <li
                        className={`panel-dropdown__item ${job.sort_type === 'upload_desc' ? 'panel-dropdown__item--active' : ''}`}
                        onClick={() => onPhotosSort('upload_desc')}
                      >
                        Upload Date: Old to New
                      </li>
                      <li
                        className={`panel-dropdown__item ${job.sort_type === 'capture_asc' ? 'panel-dropdown__item--active' : ''}`}
                        onClick={() => onPhotosSort('capture_asc')}
                      >
                        Capture Date: New to Old
                      </li>
                      <li
                        className={`panel-dropdown__item ${job.sort_type === 'capture_desc' ? 'panel-dropdown__item--active' : ''}`}
                        onClick={() => onPhotosSort('capture_desc')}
                      >
                        Capture Date: Old to New
                      </li>
                    </ul>
                  </Dropdown>
                </Tooltip>
              )}
            </div>

            {/* Bulk Feature */}
            {privateActionsShow && (
              <>
                {featuredActionsShow && (
                  <Tooltip title="Feature" position="top" arrow={false}>
                    <button
                      className="button button--outline button--small"
                      name="feature"
                      type="button"
                      disabled={bulkFeaturedDisabled}
                      onClick={onShowBulkFeatureToggle}
                    >
                      <i className="icon-star"></i>
                    </button>
                  </Tooltip>
                )}
                {yearbookActionsShow && (
                  <Tooltip title="Yearbook" position="top" arrow={false}>
                    <button
                      className="button button--outline button--small"
                      name="yearbook"
                      type="button"
                      disabled={bulkYearbookDisabled}
                      onClick={onShowBulkYearbookToggle}
                    >
                      <i className="icon-pose"></i>
                    </button>
                  </Tooltip>
                )}
              </>
            )}

            {photoActionsShow && (
              <div className="flex items-center justify-end button-group animate">
                {/* Photos selected count */}
                {requesting ? (
                  <TextLoader height={26} minWidth={200} maxWidth={200} />
                ) : (
                  <small className="job-topbar__subtitle text-neutral-500 animate">
                    {allPhotosSelected ? pagination.total - unselectedPhotos.length : photoSelected.length} Selected
                  </small>
                )}
                {/* View */}
                {!isPhotoActionsDisabled && (
                  <Tooltip title="View" position="top" arrow={false}>
                    <button className="button button--outline button--small" name="view" type="button" onClick={onShowLightboxToggle}>
                      <i className="icon-preview"></i>
                    </button>
                  </Tooltip>
                )}

                {/* Tags */}
                {publicActionsShow && (
                  <>
                    <Tooltip title="Tags" position="top" arrow={false}>
                      {!filterTags.length || filterPhotos === 'untagged' ? (
                        <button className="button button--lean button--small" name="tag" type="button" onClick={() => onTagModalShow()}>
                          <i className="icon-tag"></i>
                        </button>
                      ) : (
                        <Dropdown buttonIcon="icon-tag" buttonName={'Tag'} buttonExtraClass={'button--lean button--small button-dropdown'}>
                          <ul className="panel panel-dropdown panel-dropdown--large">
                            <li className={`panel-dropdown__item `} onClick={() => onTagModalShow()}>
                              Add Tags
                            </li>
                            <li className={`panel-dropdown__item `} onClick={() => onRemoveTagsFromPhotos(false)}>
                              {filterTags.length > 1 ? 'Remove Selected Tags' : 'Remove Selected Tag'}
                            </li>
                            <li className={`panel-dropdown__item `} onClick={() => onRemoveTagsFromPhotos(true)}>
                              Remove All Tags
                            </li>
                          </ul>
                        </Dropdown>
                      )}
                    </Tooltip>
                  </>
                )}

                {/* Subjects */}
                {privateActionsShow && people.length > 0 && filterPeople?.id && (
                  <>
                    {/* Move/Unmatch Dropdown */}
                    <Tooltip title="Move" position="top" arrow={false}>
                      <Dropdown buttonIcon="icon-move" buttonName={'Move'} buttonExtraClass={'button--lean button--small button-dropdown'}>
                        <ul className="panel panel-dropdown panel-dropdown--large">
                          <li className={`panel-dropdown__item ${people.length < 2 ? 'panel-dropdown__item--disabled' : ''}`} onClick={onShowMatchToggle}>
                            Move
                          </li>
                          <li className={`panel-dropdown__item `} onClick={onPhotoRemove}>
                            Unmatch
                          </li>
                        </ul>
                      </Dropdown>
                    </Tooltip>
                  </>
                )}

                {/* Match */}
                {privateActionsShow && (filterPhotos === 'ignored' || filterPhotos === 'all' || filterPhotos === 'unmatched') && (
                  <Tooltip title="Match" position="top" arrow={false}>
                    <button className="button button--lean  button--small" name="match" type="button" onClick={onShowMatchToggle}>
                      <i className={jobBiometricsEnabled ? 'icon-match-full' : 'icon-match-capture'}></i>
                    </button>
                  </Tooltip>
                )}

                {/*  Enable or Disable Digital Downloads */}
                {filterPhotos !== 'ignored' && (
                  <Tooltip title="Download" position="top" arrow={false}>
                    <Dropdown buttonIcon="icon-download" buttonExtraClass={'button--lean button--small button-dropdown'}>
                      <ul className="panel panel-dropdown panel-dropdown--large">
                        <li
                          className={`panel-dropdown__item ${downloadsDisabled === 'none' ? 'panel-dropdown__item--disabled' : ''}`}
                          onClick={() => onDigitalDownload(false)}
                        >
                          Enable Digital Downloads
                        </li>
                        <li
                          className={`panel-dropdown__item ${downloadsDisabled === 'all' ? 'panel-dropdown__item--disabled' : ''}`}
                          onClick={() => onDigitalDownload(true)}
                        >
                          Disable Digital Downloads
                        </li>
                      </ul>
                    </Dropdown>
                  </Tooltip>
                )}

                {/*  Delete Photos */}
                {faceSelected.length === 0 && (
                  <Tooltip title="Delete" position="top" arrow={false}>
                    <button className="button button--lean  button--small" name="delete" type="button" onClick={onDeleteShow}>
                      <i className="icon-trash"></i>
                    </button>
                  </Tooltip>
                )}
              </div>
            )}

            {/* Select */}
            {!isPhotoActionsDisabled && filterPhotos !== 'ignored' && (
              <Tooltip title="Select" position="top" arrow={false}>
                <Dropdown buttonIcon="icon-select" buttonExtraClass={'button--lean button--small button-dropdown'}>
                  <ul className="panel panel-dropdown">
                    <li
                      className={`panel-dropdown__item ${filterPeopleUnmatched === 'ignored' ? 'panel-dropdown__item--disabled' : ''}`}
                      name="selectAll"
                      onClick={onPhotoSelectAll}
                    >
                      Select All
                    </li>
                    <li
                      className="panel-dropdown__item"
                      name="clearSelection"
                      onClick={filterPeopleUnmatched === 'ignored' ? onFaceSelectClear : onPhotoSelectClear}
                    >
                      Clear Selection
                    </li>
                  </ul>
                </Dropdown>
              </Tooltip>
            )}
          </div>
        </aside>
      </header>
    </>
  );
});

export default TopBar;
