export default function removeTimeFromISO8601(isoString: Date): string {
  if (!isValidISO8601(isoString)) return isoString.toISOString();

  // Split the string at the 'T' character and take the first date part
  const dateOnly = isoString.toISOString().split('T')[0];
  return dateOnly;
}

function isValidISO8601(isoString: Date): boolean {
  const date = new Date(isoString);
  return date instanceof Date;
}
