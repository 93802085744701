import { Component } from 'react';
import { connect } from 'react-redux';

// Plugins
import queryString from 'query-string';

// Hooks & Helpers
import PreviewModal from './PreviewModal';
import SafeLink from '@/components/Shared/Link';
import loadExif from '@/utils/loadExif';
import { getMyAccountStudioRequest, updateMyAccountStudioRequest } from '../../actions';

// Styles
import '../studio.css';

// Images
import loader from '@/assets/images/loader16x16.gif';

const mapStateToProps = (state, ownProps) => {
  const {
    login: {
      studio: { id: studioId }
    },
    users: { studio, requesting }
  } = state;
  const { send_back } = queryString.parse(ownProps.location.search);

  return {
    studio,
    studioId,
    send_back,
    requesting
  };
};

const mapDispatchToProps = {
  getMyAccountStudioRequest,
  updateMyAccountStudioRequest
};

const MAX_IMAGE_SIZE = 2000000;
const MAX_IMAGE_DIMENSION = 5000;

class StudioWatermark extends Component {
  state = {
    showPreviewModal: false,
    showReplaceWarningModal: false,
    studio: {
      ...this.props.studio,
      watermark_position: this.props.studio.watermark_position || 'top,left',
      watermark_alpha: this.props.studio.watermark_alpha || 50,
      watermark_scale: this.props.studio.watermark_scale || 50
    },
    logo_attachment: {
      content: '',
      filename: '',
      content_type: ''
    },
    watermarkValidationMessage: '',
    watermark_attachment: {
      content: '',
      filename: '',
      content_type: ''
    }
  };

  componentDidMount() {
    this.props.getMyAccountStudioRequest({ studioId: this.props.studioId }, (response) => {
      const { data } = response;

      this.setState({ studio: data });
    });
  }

  saveStudio = () => {
    const { studio, logo_attachment, watermark_attachment } = this.state;
    const { send_back, updateMyAccountStudioRequest } = this.props;
    const { logo_url, watermark_url } = studio;

    let studioPayload = {
      id: studio.id,
      name: studio.name,
      web_url: studio.web_url,
      phone: studio.phone,
      address_attributes: studio.address,
      logo_url,
      watermark_url,
      watermark_alpha: studio.watermark_alpha,
      watermark_position: studio.watermark_position,
      watermark_scale: studio.watermark_scale
    };

    if (logo_attachment.content && logo_attachment.content.length > 0) {
      studioPayload = {
        ...studioPayload,
        logo_attachment: {
          ...logo_attachment
        }
      };
    }

    if (watermark_attachment.content && watermark_attachment.content.length > 0) {
      studioPayload = {
        ...studioPayload,
        watermark_attachment: {
          ...watermark_attachment
        }
      };
    }

    if (!send_back) {
      updateMyAccountStudioRequest(studioPayload);
    } else {
      updateMyAccountStudioRequest(studioPayload, () => send_back && this.props.history.goBack());
    }
  };

  togglePreviewModal = () => {
    this.setState({
      showPreviewModal: !this.state.showPreviewModal
    });
  };

  toggleReplaceWarningModal = () => {
    this.setState({
      showReplaceWarningModal: !this.state.showReplaceWarningModal
    });
  };

  handleUploadLogo = (evt) => {
    const image = evt.target.files[0];

    if (image) {
      const { name, type } = image;
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          this.setState({
            logo_attachment: {
              content: reader.result,
              filename: name,
              content_type: type
            }
          });
        },
        false
      );

      reader.readAsDataURL(image);
    }
  };

  handleLogoRemove = () => {
    this.setState(
      {
        logo_attachment: {
          content: '',
          filename: '',
          content_type: ''
        },
        studio: { ...this.state.studio, logo_url: null }
      },
      () => {
        const { studio } = this.state;

        let studioPayload = {
          id: studio.id,
          logo_attachment: {}
        };
        this.props.updateMyAccountStudioRequest(studioPayload);
      }
    );
  };

  handleUploadWatermark = (evt) => {
    const image = evt.target.files[0];

    if (image) {
      const { name, type } = image;
      const reader = new FileReader();

      loadExif(image).then(() => {
        const { data: imageData, size: imageSize } = image;

        if (imageData) {
          const imageWidth = imageData['Image Width']?.value || 0;
          const imageHeight = imageData['Image Height']?.value || 0;

          if (imageWidth > MAX_IMAGE_DIMENSION || imageHeight > MAX_IMAGE_DIMENSION) {
            this.setState({ watermarkValidationMessage: 'Your image should be less than 5000px wide and 5000px high.' });
          } else if (imageSize > MAX_IMAGE_SIZE) {
            this.setState({ watermarkValidationMessage: 'Your image should be max size of 2MB.' });
          } else {
            reader.addEventListener(
              'load',
              () => {
                this.setState({
                  watermarkValidationMessage: '',
                  showReplaceWarningModal: true,
                  watermark_attachment: {
                    content: reader.result,
                    filename: name,
                    content_type: type
                  }
                });
              },
              false
            );

            reader.readAsDataURL(image);
          }
        }
      });
    }
  };

  handleWatermarkRemove = () => {
    this.setState(
      {
        watermark_attachment: {
          content: '',
          filename: '',
          content_type: ''
        },
        studio: { ...this.state.studio, watermark_url: null }
      },
      () => {
        const { studio } = this.state;

        let studioPayload = {
          id: studio.id,
          watermark_attachment: {}
        };
        this.props.updateMyAccountStudioRequest(studioPayload, () => {
          this.props.getMyAccountStudioRequest({ studioId: studio.id }, (response) => {
            const { data } = response;

            this.setState({ studio: data });
          });
        });
      }
    );
  };

  changeValueAlpha = (evt) => {
    const { studio } = this.state;
    const alpha = evt.target.value;

    this.setState({
      studio: {
        ...studio,
        watermark_alpha: alpha
      }
    });
  };

  changeValueScale = (evt) => {
    const { studio } = this.state;
    const scale = evt.target.value;

    this.setState({
      studio: {
        ...studio,
        watermark_scale: scale
      }
    });
  };

  handleInputOnChange = (evt) => {
    const input = evt.target.name.split('-');
    const value = evt.target.value;
    const parentProp = `${input[0]}`;
    const childProp = `${input[1]}`;
    const parentObj = this.state[parentProp];
    const newParentObj = Object.assign({}, parentObj, { [childProp]: value });
    const newState = Object.assign({}, this.state, {
      [parentProp]: newParentObj
    });

    this.setState(newState);
  };

  render() {
    const { loadStudios, requesting } = this.props;
    const { showPreviewModal, showReplaceWarningModal, logo_attachment, watermark_attachment, watermarkValidationMessage } = this.state;
    const { studio } = loadStudios ? this.props : this.state;
    const { logo_url, watermark_position, watermark_alpha, watermark_scale, watermark_url } = studio;
    const isValidWatermarkImage = !!(watermark_url || watermark_attachment.content) && !watermarkValidationMessage;

    return (
      <>
        {showPreviewModal && (
          <PreviewModal
            isVisible={showPreviewModal}
            toggleModal={this.togglePreviewModal}
            logo={watermark_attachment.content || watermark_url}
            position={watermark_position}
            opacity={watermark_alpha}
            scale={watermark_scale}
          />
        )}

        <header className="flex items-center justify-between mb-4">
          <h2 className="text-headline-sm">Logo and Watermark</h2>
          <button className="button button--medium" type="button" onClick={this.saveStudio} disabled={requesting}>
            Save
          </button>
        </header>
        <section className="panel panel--section">
          <h5 className="mb-4">Logo</h5>
          <div className="watermark-logo__container">
            <figure className="watermark-logo">
              {logo_attachment.content || logo_url ? (
                <img className="watermark-logo__image" src={logo_attachment.content || logo_url} alt={logo_attachment.content || (logo_url && 'Logo')} />
              ) : (
                <span className="watermark-logo__image--empty" />
              )}

              <figcaption className="watermark-logo__caption">
                Your company logo will be used to brand items such as flyers.
                <br />
                <small>
                  <span className="font-semibold">Requirements:</span> PNG format, under 10MB.
                </small>
                <br />
                <small>
                  <span className="font-semibold">Guidelines:</span> For best results, use a horizontal logo file and minimize space around the logo. Maximum
                  dimensions - 2000px wide by 1000px high.
                </small>
              </figcaption>
            </figure>

            <div className="watermark-logo__actions">
              <label className="button watermark-logo__upload" htmlFor="logo">
                <input id="logo" className="hidden" type="file" accept="image/png" onChange={this.handleUploadLogo} />
                {logo_attachment.content || logo_url ? 'Replace' : 'Upload'}
              </label>

              {logo_attachment.content || logo_url ? (
                <button className="button button--dark logo__button--remove" type="button" onClick={this.handleLogoRemove}>
                  Remove
                </button>
              ) : null}
            </div>
          </div>
        </section>
        <section className="panel panel--section">
          <div className="watermark-content__header">
            <div className="basis-8/12 md:basis-full">
              <h5 className="mb-2">Watermark</h5>
              <p>
                This watermark is global to your studio and displayed in each customer gallery to help protect your images from screenshots. It will be removed
                after the customer purchases their prints and products.
              </p>
            </div>
            <SafeLink className="button button--dark my-studio__preview" Component="button" onClick={this.togglePreviewModal} disabled={!isValidWatermarkImage}>
              Preview
            </SafeLink>
          </div>

          <section className="watermark-content">
            <div className="logo-item">
              <h5 className="mb-2">Upload</h5>
              <p>Watermark must be in PNG format, maximum of 5000px width and height and 2MB max in size.</p>

              {isValidWatermarkImage ? (
                <img className="logo-image" src={watermark_attachment.content || watermark_url} alt="Watermark" />
              ) : (
                <div className="img-container">
                  <img src={loader} width={25} alt="loader" />
                </div>
              )}

              {watermarkValidationMessage && (
                <p className="text-error-500 font-semibold text-center block">
                  <i>{watermarkValidationMessage}</i>
                </p>
              )}

              <label htmlFor="watermark_logo" className="button button--block">
                <input id="watermark_logo" type="file" accept="image/png" onChange={this.handleUploadWatermark} />
                {watermark_url ? 'Replace' : 'Upload'}
              </label>
              <button className="button button--dark watermark__button--remove" type="button" onClick={this.handleWatermarkRemove}>
                Default
              </button>
            </div>

            <div className="logo-item">
              <h5 className="mb-2">Location</h5>
              <p>Select where the watermark will appear.</p>

              <ul className="watermark-content__location">
                <li>
                  <input
                    id="studio-watermark-top-left"
                    className="hidden"
                    type="radio"
                    name="studio-watermark_position"
                    onChange={this.handleInputOnChange}
                    value="top,left"
                    checked={watermark_position === 'top,left'}
                  />
                  <label className="watermark-content__position" htmlFor="studio-watermark-top-left" />
                </li>
                <li>
                  <input
                    id="studio-watermark-bottom-left"
                    className="hidden"
                    type="radio"
                    name="studio-watermark_position"
                    onChange={this.handleInputOnChange}
                    value="bottom,left"
                    checked={watermark_position === 'bottom,left'}
                  />
                  <label className="watermark-content__position" htmlFor="studio-watermark-bottom-left" />
                </li>
                <li>
                  <input
                    id="studio-watermark-top-right"
                    className="hidden"
                    type="radio"
                    name="studio-watermark_position"
                    onChange={this.handleInputOnChange}
                    value="top,right"
                    checked={watermark_position === 'top,right'}
                  />
                  <label className="watermark-content__position" htmlFor="studio-watermark-top-right" />
                </li>
                <li>
                  <input
                    id="studio-watermark-bottom-right"
                    className="hidden"
                    type="radio"
                    name="studio-watermark_position"
                    onChange={this.handleInputOnChange}
                    value="bottom,right"
                    checked={watermark_position === 'bottom,right'}
                  />
                  <label className="watermark-content__position" htmlFor="studio-watermark-bottom-right" />
                </li>
                <li>
                  <input
                    id="studio-watermark-center-middle"
                    className="hidden"
                    type="radio"
                    name="studio-watermark_position"
                    onChange={this.handleInputOnChange}
                    value="center,middle"
                    checked={watermark_position === 'center,middle' || watermark_position === 'center'}
                  />
                  <label className="watermark-content__position" htmlFor="studio-watermark-center-middle" />
                </li>
              </ul>
            </div>

            <div className="logo-item">
              <h5 className="mb-2">Opacity and Scale</h5>
              <p>Adjust the watermark’s opacity.</p>
              <input className="mb-5" type="range" value={watermark_alpha} min="30" step="10" max="100" onChange={this.changeValueAlpha} />
              <p>Adjust the watermark’s scale.</p>
              <input type="range" value={watermark_scale} min="30" step="10" max="100" onChange={this.changeValueScale} />
            </div>
          </section>
        </section>

        {/* Replace watermark warning modal */}
        <aside className={`modal ${showReplaceWarningModal ? '' : 'transparent'}`}>
          <div className="modal__box modal__box--small">
            <header className="modal__header">
              <button className="button button--action modal__close" name="button" type="button" onClick={this.toggleReplaceWarningModal}>
                <i className="icon-close"></i>
              </button>
            </header>
            <main className="modal__content text-center">
              <h4>If you are replacing a previously uploaded watermark, it may take a few minutes for the updated watermark to appear.</h4>
            </main>
            <footer className="modal__footer">
              <button className="button button--center button--large" name="button" type="button" onClick={this.toggleReplaceWarningModal}>
                Ok
              </button>
            </footer>
          </div>
        </aside>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudioWatermark);
