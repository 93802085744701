import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Components
import CreateOrEditOfferModal from '../Offers/CreateOrEditOfferModal';
import CreateOrEditGiftCardModal from './CreateOrEditGiftCardModal';

// Helpers
import { getCreditSheetId } from './selectors';
import { getMyAccountStudioRequest } from '../../Settings/actions';
import { getPriceSheetId } from '../PriceSheets/selectors';
import { formatCurrency } from '@/utils/displayFormats';
import {
  updateCreditSheetRequest,
  giftCardListRequest,
  updateGiftCardListRequest,
  deleteGiftCardListRequest,
  createGiftCardListRequest,
  getCreditSheetDetailRequest
} from './actions';

// Images
import logo_photoday from '@/assets/images/logo_black@2x.png';

// Styles
import './advancepay-detail.css';

const mapStateToProps = (state, ownProps) => {
  const priceSheetId = getPriceSheetId(ownProps);
  const creditSheetId = getCreditSheetId(ownProps);

  const {
    pricesheets: {
      requesting: priceSheetRequesting,
      entities: { pricesheet }
    },
    login: {
      studio: { id: studioId, lab_id: labId, lab_name: labName }
    },
    users: {
      studio: { logo_url: studioLogo }
    }
  } = state;

  const {
    advancepay: {
      entities: { gift_cards: giftCards },
      creditSheetDetail,
      requesting: advancePayRequesting
    }
  } = state;

  const requesting = advancePayRequesting || priceSheetRequesting;

  return {
    studioLogo,
    studioId,
    pricesheet,
    priceSheetId,
    creditSheetId,
    requesting,
    giftCards,
    creditSheetDetail,
    lab: { id: labId, name: labName }
  };
};

const mapDispatchToProps = {
  giftCardListRequest,
  updateCreditSheetRequest,
  updateGiftCardListRequest,
  deleteGiftCardListRequest,
  createGiftCardListRequest,
  getCreditSheetDetailRequest,
  getMyAccountStudioRequest
};

const AdvancePayDetail = (props) => {
  const [activeOffer, setActiveOffer] = useState(null);
  const [activeGiftCard, setActiveGiftCard] = useState(null);
  const [showGiftCardCreateOrEditModal, setShowGiftCardCreateOrEditModal] = useState(false);
  const [showOfferCreateOrEditModal, setShowOfferCreateOrEditModal] = useState(false);
  const [creatingGiftCard, setCreatingGiftCard] = useState(false);

  const {
    giftCards,
    creditSheetDetail,
    studioLogo,
    requesting,
    studioId,
    getMyAccountStudioRequest,
    giftCardListRequest,
    creditSheetId,
    getCreditSheetDetailRequest
  } = props;
  const valuesGiftCards = giftCards.map((giftcard) => giftcard.amount_cents);

  const fetchGiftCards = () => {
    giftCardListRequest(creditSheetId);
  };

  const fetchCreditSheetDetail = () => {
    getCreditSheetDetailRequest(creditSheetId);
  };

  const handleOfferGridSettingsAction = (offer) => {
    setActiveOffer(Object.assign({}, offer));
    setShowOfferCreateOrEditModal(true);
  };

  const handleGiftCardModal = (giftCard) => {
    setActiveGiftCard(giftCard ? Object.assign({}, giftCard) : null);
    setShowGiftCardCreateOrEditModal(true);
  };

  const handleOfferCreateOrEditDialogSave = (creditSheet) => {
    const { studioId, updateCreditSheetRequest } = props;

    updateCreditSheetRequest({ studioId, creditSheet }, () => {
      fetchCreditSheetDetail();
      handleOfferDialogsCancel();
    });
  };

  const handleGiftCardCreateOrEditDialogSave = (giftCard, amount, description) => {
    const { updateGiftCardListRequest, createGiftCardListRequest, creditSheetId } = props;

    amount = parseInt(amount * 100);

    if (giftCard) {
      updateGiftCardListRequest({ giftCardId: giftCard.id, amount, description }, () => {
        handleGiftCardDialogsCancel();
      });
    } else {
      if (!creatingGiftCard) {
        setCreatingGiftCard(true);
        createGiftCardListRequest({ creditSheetId, amount, description }, () => {
          handleGiftCardDialogsCancel();
        });
      }
    }
  };

  const handleOfferDialogsCancel = () => {
    setActiveOffer(null);
    setShowOfferCreateOrEditModal(false);
  };

  const handleGiftCardDialogsCancel = () => {
    setActiveGiftCard(null);
    setShowGiftCardCreateOrEditModal(false);
    setCreatingGiftCard(false);
  };

  const handleDeleteGiftCard = (giftCardId) => {
    const { deleteGiftCardListRequest } = props;
    deleteGiftCardListRequest(giftCardId, () => {
      handleGiftCardDialogsCancel();
    });
  };

  useEffect(() => {
    fetchCreditSheetDetail();
    fetchGiftCards();

    studioId && getMyAccountStudioRequest({ studioId: studioId });
  }, []);

  if (!creditSheetDetail) {
    return null;
  }

  return (
    <div id="advancepay-container" className="container">
      {showOfferCreateOrEditModal && (
        <CreateOrEditOfferModal
          offer={creditSheetDetail}
          offerType="credit_sheets"
          pricesheetItems={[]}
          pricesheets={[]}
          onSave={handleOfferCreateOrEditDialogSave}
          onCancel={handleOfferDialogsCancel}
        />
      )}
      {showGiftCardCreateOrEditModal && (
        <CreateOrEditGiftCardModal
          giftCard={activeGiftCard}
          valuesGiftCards={valuesGiftCards}
          onSave={handleGiftCardCreateOrEditDialogSave}
          onCancel={handleGiftCardDialogsCancel}
          onDelete={handleDeleteGiftCard}
          requesting={requesting}
        />
      )}
      <div className="body-second-header">
        <div className="flex items-center gap-2.5">
          <h3 className="m-0">{creditSheetDetail.name}</h3>
          <button className="button" aria-hidden="true" data-toggle="modal" onClick={handleOfferGridSettingsAction}>
            Edit
          </button>
        </div>
      </div>

      <div className="add-credits">
        <div className="step-container">
          <h2 className="text-headline-sm">Add Credits</h2>
        </div>
        <div className="credits-container">
          <div className="credit-item" onClick={() => handleGiftCardModal(giftCards[0])}>
            <div className="credit-item__logo-wrapper">{studioLogo ? <img src={studioLogo} alt="logo" /> : <img src={logo_photoday} alt="logo" />}</div>
            <span className="credit-item__label font-semibold">PhotoDay AdvancePay Credit</span>
            <div className="credit-item__accent">
              <p className="credit-amount font-semibold">{giftCards[0] ? formatCurrency(giftCards[0].amount_cents) : 'Add Credit'}</p>
            </div>
          </div>
          <div className="credit-item" onClick={() => handleGiftCardModal(giftCards[1])}>
            <div className="credit-item__logo-wrapper">{studioLogo ? <img src={studioLogo} alt="logo" /> : <img src={logo_photoday} alt="logo" />}</div>
            <span className="credit-item__label font-semibold">PhotoDay AdvancePay Credit</span>
            <div className="credit-item__accent">
              <p className="credit-amount font-semibold">{giftCards[1] ? formatCurrency(giftCards[1].amount_cents) : 'Add Credit'}</p>
            </div>
          </div>
          <div className="credit-item" onClick={() => handleGiftCardModal(giftCards[2])}>
            <div className="credit-item__logo-wrapper">{studioLogo ? <img src={studioLogo} alt="logo" /> : <img src={logo_photoday} alt="logo" />}</div>
            <span className="credit-item__label font-semibold">PhotoDay AdvancePay Credit</span>
            <div className="credit-item__accent">
              <p className="credit-amount font-semibold">{giftCards[2] ? formatCurrency(giftCards[2].amount_cents) : 'Add Credit'}</p>
            </div>
          </div>
          <div className="credit-item" onClick={() => handleGiftCardModal(giftCards[3])}>
            <div className="credit-item__logo-wrapper">{studioLogo ? <img src={studioLogo} alt="logo" /> : <img src={logo_photoday} alt="logo" />}</div>
            <span className="credit-item__label font-semibold">PhotoDay AdvancePay Credit</span>
            <div className="credit-item__accent">
              <p className="credit-amount font-semibold">{giftCards[3] ? formatCurrency(giftCards[3].amount_cents) : 'Add Credit'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancePayDetail);
