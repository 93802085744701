import { Link } from 'react-router-dom';
import { FC, useState, useEffect } from 'react';

// Redux
import { getJobKnockoutList, updateJobKnockoutFromCable, deleteJobKnockout } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Plugins
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import queryString from 'query-string';
// @ts-ignore
import { useActionCable } from 'use-action-cable';

// Components
import AddEdit from '../AddEdit';
import Dropdown from '@/components/Shared/Dropdown';
import Pagination from '@/components/Shared/Pagination';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Types
import { Knockout } from '@/types';

interface PostProcessingListProps {
  match: {
    params: {
      jobId: string;
    };
  };
  history: { location: { search: string }; push: (location: string | { search: string }) => void };
}

const PostProcessingList: FC<PostProcessingListProps> = ({ match, history }) => {
  const dispatch = useDispatch();

  const {
    params: { jobId }
  } = match;

  const {
    knockoutUpload: { knockoutId: knockoutUploadingId },
    knockouts: { list: knockoutsList, pagination: knockoutsPagination, requesting }
  } = useSelector((state: any) => state.jobs);

  const { page: queryPage } = queryString.parse(history.location.search);

  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [selectPostProcessingItem, setSelectPostProcessingItem] = useState<Knockout | null>(null);

  const [tableFilter, setTableFilter] = useState<{ order: string; dir: string; per_page?: number; page?: number }>({
    order: 'created_at',
    dir: 'DESC',
    per_page: 15,
    page: Number(queryPage) || 1
  });

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const handleOrderBy = (order: string, dir: string) => {
    setTableFilter({ ...tableFilter, order, dir });
  };

  const handlePagination = (page: number = 0) => {
    history.push({ search: `?page=${page}` });
    setTableFilter({ ...tableFilter, page });
  };

  const handlePostProcessingMessageReceive = {
    received(message: any) {
      if (message.type === 'knockout') {
        dispatch(updateJobKnockoutFromCable({ data: message['knockout'] }));
      }
    }
  };

  const handleModalDeleteShow = (item: Knockout) => {
    setShowDeleteModal(true);
    setSelectPostProcessingItem(item);
  };

  const handlePostProcessingDelete = () => {
    dispatch(
      deleteJobKnockout({ knockoutId: selectPostProcessingItem?.id }, () => {
        setShowDeleteModal(false);

        // Send pagination back if last item is deleted
        if (knockoutsPagination.page > 1 && knockoutsList.length === 1) {
          setTableFilter({ ...tableFilter, page: knockoutsPagination.page - 1 });
        }
      })
    );
  };

  const getPostProcessingStatus = (item: Knockout) => {
    if (item.status === 'complete' && item.download_url) return 'Download Ready';
    if (item.status === 'complete') return 'Post-Processing Complete'; // All post processing items knocked out
    if (knockoutUploadingId === item.id) return 'Uploading';
    if (item.status === 'uploading' || item.uploading) return 'Uploading';
    if (item.status === 'knocking_out' || item.status === 'ready') return 'Processing';
    if (item.status === 'failed') return 'Failed';
    if (item.status === 'preparing') return 'Preparing'; // Download is preparing
    if (item.status === 'created' && item.knockout_items_count && !item.reviewed_at) return 'Ready for review';
    if (item.status === 'created' && item.knockout_items_count && item.reviewed_at) return 'Ready to submit';

    return 'Created';
  };

  useActionCable({ channel: 'JobEventsChannel', job_id: jobId }, handlePostProcessingMessageReceive);

  useEffect(() => {
    dispatch(getJobKnockoutList({ jobId, ...tableFilter }));
  }, [tableFilter, queryPage]);

  return (
    <>
      <header className="flex justify-between job-postprocessing__header">
        <h2>Post-Processing</h2>
        <aside>
          <Tooltip {...{ title: 'Create Post-Processing Job', theme: 'light', position: 'bottom', arrow: true, distance: 30 }}>
            <button className="button button--small button--lean" name="create" type="button" onClick={() => setShowAddEditModal(true)}>
              <i className="icon-add"></i>
            </button>
          </Tooltip>
        </aside>
      </header>

      {requesting ? (
        <TableLoader rows={4} rowHeight={76} />
      ) : (
        <>
          {knockoutsList?.length ? (
            <>
              <article className="table-box animate">
                <table className="table">
                  <thead className="table__header">
                    <tr>
                      <th>
                        <Dropdown {...{ buttonName: 'Date', buttonExtraClass: 'button--filter' }}>
                          <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                            <li
                              className={`panel-dropdown__item ${
                                tableFilter.order === 'created_at' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                              }`}
                              onClick={() => {
                                handleOrderBy('created_at', 'ASC');
                              }}
                            >
                              Ascending
                            </li>
                            <li
                              className={`panel-dropdown__item ${
                                tableFilter.order === 'created_at' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                              }`}
                              onClick={() => {
                                handleOrderBy('created_at', 'DESC');
                              }}
                            >
                              Descending
                            </li>
                          </ul>
                        </Dropdown>
                      </th>
                      <th>
                        <Dropdown {...{ buttonName: 'Name', buttonExtraClass: 'button--filter' }}>
                          <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                            <li
                              className={`panel-dropdown__item ${
                                tableFilter.order === 'name' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                              }`}
                              onClick={() => {
                                handleOrderBy('name', 'ASC');
                              }}
                            >
                              Ascending
                            </li>
                            <li
                              className={`panel-dropdown__item ${
                                tableFilter.order === 'name' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                              }`}
                              onClick={() => {
                                handleOrderBy('name', 'DESC');
                              }}
                            >
                              Descending
                            </li>
                          </ul>
                        </Dropdown>
                      </th>
                      <th className="text--normalized">Service(s)</th>
                      <th className="text--normalized"># of Photos</th>
                      <th className="text--normalized">Order #</th>
                      <th>
                        <Dropdown {...{ buttonName: 'Transferred', buttonExtraClass: 'button--filter' }}>
                          <ul className="panel panel-dropdown panel-dropdown--left panel-dropdown--small">
                            <li
                              className={`panel-dropdown__item ${
                                tableFilter.order === 'transferred_at' && tableFilter.dir === 'ASC' ? 'panel-dropdown__item--active' : ''
                              }`}
                              onClick={() => {
                                handleOrderBy('transferred_at', 'ASC');
                              }}
                            >
                              Ascending
                            </li>
                            <li
                              className={`panel-dropdown__item ${
                                tableFilter.order === 'transferred_at' && tableFilter.dir === 'DESC' ? 'panel-dropdown__item--active' : ''
                              }`}
                              onClick={() => {
                                handleOrderBy('transferred_at', 'DESC');
                              }}
                            >
                              Descending
                            </li>
                          </ul>
                        </Dropdown>
                      </th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="table__body">
                    {knockoutsList.map((item: Knockout) => (
                      <tr key={item.id}>
                        <td data-header="Date">{moment(item.created_at).format('MM/DD/YY')}</td>
                        <td data-header="Name">
                          {getPostProcessingStatus(item).match(/^(Ready for review|Post-Processing Complete|Download Ready)$/) ? (
                            <Link className="button button--link text-left" to={`/jobs/${jobId}/services/postprocessing/${item.id}/review`}>
                              {item.name}
                            </Link>
                          ) : getPostProcessingStatus(item).match(/^(Created|Uploading|Ready to submit)/) ? (
                            <Link className="button button--link text-left" to={`/jobs/${jobId}/services/postprocessing/${item.id}/settings`}>
                              {item.name}
                            </Link>
                          ) : (
                            <span>{item.name}</span>
                          )}
                        </td>
                        <td data-header="Service(s)">
                          {item.extraction || item.color_correction ? (
                            <>
                              {item.extraction && <span className="block whitespace-nowrap">• Knockout {item.remove_spill && 'w/ Spill Correction'}</span>}
                              {item.color_correction && <span className="block whitespace-nowrap">• Color Correction</span>}
                            </>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td data-header="# of Photos">{item.knockout_items_count ? item.knockout_items_count : '-'}</td>
                        <td data-header="Order #">{item.order_number}</td>
                        <td data-header="Last Transferred">{item.transferred_at ? moment(item.transferred_at).format('MM/DD/YY') : '-'}</td>
                        <td data-header="Status" className="capitalize">
                          {getPostProcessingStatus(item)}
                        </td>
                        <td data-header="Action" className="flex">
                          <Tooltip {...{ title: 'Download Post-Processing', theme: 'light', position: 'bottom', arrow: true, distance: 30 }}>
                            {item.download_url === null ? (
                              <button className="button button--clean button--small" name="download" type="button" disabled={true}>
                                <i className="icon-download-box" />
                              </button>
                            ) : (
                              <a
                                href={item.download_url}
                                className="button button--clean button--small"
                                type="button"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="icon-download-box" />
                              </a>
                            )}
                          </Tooltip>
                          <Tooltip {...{ title: 'Delete Post-Processing', theme: 'light', position: 'bottom', arrow: true, distance: 30 }}>
                            <button className="button button--clean button--small" name="button" type="button" onClick={() => handleModalDeleteShow(item)}>
                              <i className="icon-trash" />
                            </button>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </article>
              {knockoutsPagination.total > knockoutsPagination.perPage && (
                <Pagination pagination={knockoutsPagination} onPagination={handlePagination} showPagesCount={4} />
              )}
            </>
          ) : (
            <aside className="flex flex-col items-center justify-center panel panel--secondary panel--tall">
              <h2>
                Add your first post-processing job{' '}
                <button className="button button--link" name="add" type="button" onClick={() => setShowAddEditModal(true)}>
                  here{' '}
                </button>
                .
              </h2>
            </aside>
          )}
        </>
      )}

      {/* Delete modal */}
      <aside className={`modal ${showDeleteModal ? '' : 'transparent'}`}>
        <div className="modal__box modal__box--xsmall modal__box--dark">
          <header className="modal__header text-left">
            <button className="button button--action modal__close" name="button" type="button" onClick={() => setShowDeleteModal(false)}>
              <i className="icon-close"></i>
            </button>
            <h3>Delete</h3>
          </header>
          <main className="modal__content">
            <p>
              Are you sure you want to delete the <b>{selectPostProcessingItem?.name}</b> Post-Processing job?
            </p>
            <p>This action cannot be undone.</p>
          </main>
          <footer className="modal__footer modal__footer--fixed">
            <button className="button button--medium button--danger" name="delete" type="button" onClick={handlePostProcessingDelete} disabled={requesting}>
              Delete
            </button>
          </footer>
        </div>
      </aside>

      {showAddEditModal && <AddEdit jobId={jobId} onClose={() => setShowAddEditModal(false)} />}
    </>
  );
};

export default PostProcessingList;
