import React, { useState } from 'react';

// Redux
import { Field } from 'redux-form';

// Plugins
import moment from 'moment';
import DatePicker from 'react-datepicker';

// Helpers
import { InputGroup, Input } from '@/components/Shared/Forms';

const OfferOptions = ({ offer, change }) => {
  const [expirationDate, setExpirationDate] = useState(offer.expiration ? moment(offer.expiration).utc().format('L') : '');

  const renderHiddenInput = ({ input }) => {
    return <input {...input} type="hidden" className="input--block" />;
  };

  const handleMinOrderPriceChange = (e) => {
    let num = parseFloat(e.target.value);
    change('min_order_price_cents', !isNaN(num) ? Math.round(num * 100) : null);
  };

  const handleExpirationDateChange = (date) => {
    change('expiration', date);
    setExpirationDate(date);
  };

  return (
    <div>
      <div className="flex gap-2.5">
        <div className="basis-2/6">
          <InputGroup
            name="min_order_price"
            label="Minimum Order Dollar Amount"
            placeholder="Optional"
            addonPosition="left"
            onChange={handleMinOrderPriceChange}
            number
            maxLength5
          >
            <span>$</span>
          </InputGroup>
        </div>
        <fieldset className="basis-2/6">
          <label>Offer Expiration Date</label>
          <DatePicker
            className="input--date input--block"
            name="expiration"
            minDate={new Date()}
            dateFormat="MM/dd/yy"
            placeholderText="MM/DD/YY"
            isClearable={true}
            selected={expirationDate}
            onChange={handleExpirationDateChange}
            strictParsing
          />
          <strong className="valid-until">Valid until 11:59pm PST</strong>
        </fieldset>
        <div className="basis-2/6">
          <Input name="max_redemptions" label="Max Redemptions" placeholder="Optional" number maxLength10 />
        </div>
      </div>
      <p className="text-center">
        <small>
          * If planning to use this offer for AdvancePay, either don't assign an expiration date, or make sure expiration date goes beyond the gallery publish
          date to give customers time to purchase.
        </small>
      </p>
      <Field name="min_order_price_cents" component={renderHiddenInput} type="hidden" />
    </div>
  );
};

export default OfferOptions;
