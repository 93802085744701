import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

// Components
import ModalForgotPassword from './modalForgotPassword';

// Helpers
import { loginRequest } from './actions';

// Images
import logo from '@/assets/images/image_PD_logo.svg';
import logo_oto_hair from '@/assets/images/logo_oto_hair.png';

// Styles
import './login.css';

const Login = ({ history }) => {
  const dispatch = useDispatch();

  const { requesting, invalidLogin, isUnverified, isLocalStorageAvailable, isAuthenticated } = useSelector((state) => state.login);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleInputOnChange = (evt) => {
    const { name, type, checked, value } = evt.target;
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
    if (name === 'rememberMe') setRememberMe(checked);
    if (name === 'showPassword') setShowPassword(checked);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (email && password) {
      dispatch(loginRequest({ email, password, rememberMe }));
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  if (isUnverified) {
    return <Redirect to="/register/resend-verification" />;
  }

  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }

  return (
    <div id="login__index">
      <section className="container onboarding-bg">
        <img src={logo} className="gray-logo" alt="Logo" />

        <main className={'container-fluid flex-page-centered' + (invalidLogin ? ' error' : '')}>
          <div className="white-container basis-9/12 login-screen">
            <figure className="blue-gradient left-onboarding-container">
              <img src={logo_oto_hair} alt="Logo Oto" />
              <h1 className="font-semibold"> Welcome back!</h1>
              <p> Please enter your email and password to log back in. </p>
            </figure>

            <aside className="right-onboarding-container">
              <form className="sign-in-form">
                <div className={`mb-2.5 ${invalidLogin ? 'has-error' : ''}`}>
                  <label className="font-semibold">Email </label>
                  <input
                    id="exampleInputEmail1"
                    className="input--block"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="johnsmith@email.com"
                    autoComplete="username"
                    required
                    data-cy="cy-login-email"
                    onChange={handleInputOnChange}
                  />
                </div>
                <div className={` ${invalidLogin ? 'has-error' : ''}`}>
                  <label className="font-semibold">Password</label>
                  <input
                    id="exampleInputPassword1"
                    className="input--block"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={password}
                    placeholder="••••••"
                    autoComplete="current-password"
                    required
                    data-cy="cy-login-password"
                    onChange={handleInputOnChange}
                  />
                  <small
                    style={{
                      visibility: invalidLogin ? 'visible' : 'hidden'
                    }}
                    className="block text-error-500"
                    data-cy="cy-invalid-login"
                  >
                    Invalid Login
                  </small>
                </div>
                <div className="flex items-center justify-between">
                  <button className="button button--link" type="button" data-cy="cy-login-forgot" onClick={toggleModal}>
                    Forgot Password
                  </button>
                  <input id="showPassword" className="hidden" type="checkbox" name="showPassword" checked={showPassword} onChange={handleInputOnChange} />
                  <label htmlFor="showPassword" className="label--checkbox label--clean">
                    Show Password
                  </label>
                </div>
                <button
                  className="button login-btn"
                  type="submit"
                  data-loading={requesting}
                  data-cy="cy-login-button"
                  disabled={requesting || isLocalStorageAvailable === false || !email || !password}
                  onClick={handleSubmit}
                >
                  Log In
                </button>
                <fieldset className="flex justify-center">
                  <input id="rememberMe" className="hidden" type="checkbox" name="rememberMe" checked={rememberMe} onChange={handleInputOnChange} />
                  <label htmlFor="rememberMe" className="label--checkbox label--clean">
                    Remember Me
                  </label>
                </fieldset>
              </form>

              {isLocalStorageAvailable === false && (
                <aside className="panel panel--dark panel--round panel--nomargin animate">
                  <p className="m-0">
                    Your browser’s settings may need to be configured to log in and to get the best experience on this website.{' '}
                    <a
                      href="https://support.photoday.io/en/articles/6577497-how-to-enable-cookies-and-javascript"
                      className="text-type-base font-semibold"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </p>
                </aside>
              )}

              <a href="https://photoday.io/signup" className="font-semibold no-account-link" data-cy="cy-login-signup">
                Don't have an account?
              </a>
            </aside>
          </div>
        </main>

        <ModalForgotPassword isVisible={showModal} onClose={toggleModal} />
      </section>
    </div>
  );
};

export default Login;
