import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';

// Plugins
import CsvParse from 'papaparse';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { Tooltip } from 'react-tippy';
import { useActionCable } from 'use-action-cable';

// Components
import AddEdit from '../AddEdit';
import CodeLookup from '../CodeLookup';
import Header from '../../../Header';
import Dropdown from '@/components/Shared/Dropdown';
import Pagination from '@/components/Shared/Pagination';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateMyAccountStudioRequest, getMyAccountStudioRequest } from '../../../../Settings/actions';
import {
  getJob,
  createPeopleCsv,
  createPeopleCsvDetails,
  updatePhotoStats,
  getPeopleList,
  getPeopleFieldOptions,
  deletePeople,
  setPeopleDetails,
  setPeopleLabels,
  createPeopleLookupValidate,
  resetPeopleLookupValidate
} from '../../../actions';

// Helpers
import imageScaling from '@/utils/imageScaling';
import { normalizePhone } from '@/utils/formatters';

// Images
import imageAppStore from '@/assets/images/app-store-button.png';
import imageGooglePlay from '@/assets/images/google-play-button.png';
import imagePeoplePlaceholder from '@/assets/images/people-placeholder.png';
import imagePeopleThumbnailPlaceholder from '@/assets/images/people-thumbnail-placeholder.png';

// Styles
import './style.css';

const CODE_LOOKUP_ENABLED = import.meta.env.VITE_CODE_LOOKUP_ENABLED === '1';
const MAX_LABEL_LENGTH = 6;

const List = ({ match, history }) => {
  const dropzoneRef = useRef();

  const dispatch = useDispatch();

  const {
    params: { jobId }
  } = match;
  const {
    job,
    people: { list: peopleList, pagination, fields, fieldsMap, fieldsMapDetails, lookupConfigValidate, labels, lookupConfig, requesting: peopleRequesting },
    requesting
  } = useSelector((state) => state.jobs);

  const {
    studio: { id: studioId }
  } = useSelector((state) => state.login);

  const { studio } = useSelector((state) => state.users);

  const [map, setMap] = useState(fieldsMap);
  const [data, setData] = useState({ file: {}, headers: [], rows: [] });
  const [missingMappingHeaders, setMissingMappingHeaders] = useState([]);

  const [showAddEdit, setShowAddEdit] = useState(false);

  const [search, setSearch] = useState('');
  const [tableFilter, setTableFilter] = useState({
    id: jobId,
    order: 'last_name',
    dir: 'ASC',
    filter: { scopes: [] },
    method: '',

    page: pagination.page,
    per_page: 15
  });

  const [selectPeople, setSelectPeople] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState('');
  const [performAllDelete, setPerformAllDelete] = useState(false);

  const [showCapture, setShowCapture] = useState(false);

  const [shouldUpdateReferencePhoto, setShouldUpdateReferencePhoto] = useState(false);
  const [showUpdateReferencePhotoModal, setShowUpdateReferencePhotoModal] = useState(false);

  const [showMapConfirmModal, setShowMapConfirmModal] = useState(false);
  const [showAppendConfirmModal, setShowAppendConfirmModal] = useState(false);

  const [showLookupWarningModal, setShowLookupWarningModal] = useState(false);

  const handleAddEditToggle = () => setShowAddEdit(!showAddEdit);

  const handleDropzoneDialogOpen = () => {
    if (dropzoneRef.current && typeof dropzoneRef.current.open === 'function') {
      dropzoneRef.current.open();
    }
  };

  const handleDropAccepted = (acceptedFiles) => {
    const file = acceptedFiles[0];

    let data = { meta: {}, rows: [] };

    CsvParse.parse(file, {
      quotes: false,
      header: true,
      worker: true,
      preview: 4,
      dynamicTyping: false,
      skipEmptyLines: 'greedy',

      step: (row) => (data = { meta: row.meta, rows: [...data.rows, { ...row.data }] }),
      complete: () => {
        const { meta } = data;
        const update =
          meta.fields && meta.fields.length
            ? map.filter((item) => meta.fields.indexOf(item.label) > -1).map((item) => Object.assign({}, item, { map: item.label }))
            : [];

        setMap([...map.filter((item) => meta.fields && meta.fields.indexOf(item.label) === -1), ...update]);
        setData({ file, headers: Array.from(new Set(meta.fields)), rows: data.rows });
      }
    });
  };

  const handleClearMapData = () => setData({ file: {}, headers: [], rows: [] });

  const handleMapSelect = (select = { label: '', value: '', map: '' }) => {
    // If image name is selected open modal asking overwrite current reference photos
    if (select.map && select.value === 'image_name') {
      setShowUpdateReferencePhotoModal(true);
    }

    setMap([
      ...map.filter((item) => item.value !== select.value && item.map !== select.map), // add all but selected
      select, // add selected
      ...map.filter((item) => item.map === select.map).map((item) => ({ ...item, map: '' })) // clean already mapped
    ]);
  };

  const handleShouldUpdateReferencePhoto = (shouldUpdate = false) => {
    setShowUpdateReferencePhotoModal(false);
    setShouldUpdateReferencePhoto(shouldUpdate);
  };

  const handleUpdateMapCheck = () => {
    const { headers } = data;

    let missingMappingHeaders = [];

    // Check for un-mapped csv columns
    headers.forEach((header) => {
      if (map.some((item) => item.map === header) === false) {
        missingMappingHeaders.push(header);
      }
    });

    setMissingMappingHeaders(missingMappingHeaders);

    // If any missing column show the confirmation modal
    if (missingMappingHeaders.length > 0) {
      setShowMapConfirmModal(missingMappingHeaders.length > 0);
    } else {
      handleAppendMapCheck();
    }
  };

  const handleAppendMapCheck = () => {
    // Check if map will affect current subjects before map new csv
    const fileMapping = map.filter((item) => item.map).map((item) => ({ [item.map.trim()]: item.value.trim() }));
    const createPeoplePayload = { id: jobId, file: data.file, mapping: Object.assign({}, ...fileMapping), replace_session_photo: shouldUpdateReferencePhoto };

    dispatch(
      createPeopleCsvDetails(createPeoplePayload, ({ data }) => {
        const { will_update: willUpdate, will_add: willAdd } = data;

        if (willUpdate > 0 || willAdd > 0) {
          setShowMapConfirmModal(false);
          setShowAppendConfirmModal(true);
        } else {
          handleMap();
        }
      })
    );
  };

  const handleMap = () => {
    const firstPersonAdd = peopleList.length === 0;
    const fileMapping = map.filter((item) => item.map).map((item) => ({ [item.map.trim()]: item.value.trim() }));
    const createPeoplePayload = { id: jobId, file: data.file, mapping: Object.assign({}, ...fileMapping), replace_session_photo: shouldUpdateReferencePhoto };

    dispatch(
      createPeopleCsv(createPeoplePayload, () => {
        handleClearMapData();
        setShowMapConfirmModal(false);
        setShowAppendConfirmModal(false);

        dispatch(getJob({ id: jobId }));
        dispatch(getPeopleFieldOptions({ id: jobId }));
        dispatch(getPeopleList(tableFilter));

        if (lookupConfig.enabled) {
          dispatch(createPeopleLookupValidate({ jobId, lookupConfig }));
        }

        if (studio.display_capture_message && firstPersonAdd) {
          handleCaptureToggle();
        }
      })
    );
  };

  const handleSearchChange = (e) => setSearch(e.target.value);
  const handlePeopleSearch = (e) => {
    e.preventDefault();

    dispatch(getPeopleList({ ...tableFilter, search }));
  };

  const handleSearchClear = (e) => {
    e.stopPropagation();

    setSearch('');
    dispatch(getPeopleList(tableFilter));
  };

  const handleOrderBy = (e, { order, dir, filter, method }) => {
    if (e.target.checked) {
      if (filter || filter !== '') {
        tableFilter.filter.scopes.push(filter);
      }

      const filters = {
        ...tableFilter,
        ...(order || order === '' ? { order } : {}),
        ...(dir || dir === '' ? { dir } : {}),
        ...(method || method === '' ? { method } : {})
      };

      dispatch(getPeopleList(filters));
      setTableFilter(filters);
    } else {
      tableFilter.filter.scopes = tableFilter.filter?.scopes.filter((item) => item && item !== filter);

      setTableFilter(tableFilter);
      dispatch(getPeopleList(tableFilter));
    }
  };

  const handlePagination = (page) => {
    dispatch(getPeopleList({ ...tableFilter, search, page }));
  };

  const handleLabelsChange = (e) => {
    // Set limit for data labels
    if ((e.target.checked && labels.length < MAX_LABEL_LENGTH) || (!e.target.checked && labels.length > 2)) {
      dispatch(setPeopleLabels(e.target.checked ? [...labels, e.target.name] : [...labels.filter((label) => label !== e.target.name)]));
    }
  };

  const handleSelectPeople = (e, id) => {
    if (id === 'all') {
      setSelectPeople(e.target.checked ? peopleList.map((person) => person.id) : []);
    } else {
      setSelectPeople(e.target.checked ? [...selectPeople, id] : [...selectPeople.filter((select) => select !== id)]);
    }
  };

  const handleAddEditShow = (id) => {
    setSelectPeople(id ? [id] : []);
    setShowAddEdit(true);
  };

  const handleDeleteShow = (action, id = null) => {
    if (id) {
      setSelectPeople([id]);
    }

    setShowDeleteModal(true);
    setPerformAllDelete(action === 'all' ? true : false);
  };
  const handleDeleteChange = (e) => setConfirmDelete(e.target.value);
  const handleDeleteCancel = () => {
    setSelectPeople([]);
    setShowDeleteModal(false);
  };
  const handleDelete = () => {
    dispatch(
      deletePeople({ id: jobId, subject_ids: selectPeople, perform_all: performAllDelete }, () => {
        setSelectPeople([]);
        setConfirmDelete('');
        setShowDeleteModal(false);

        dispatch(resetPeopleLookupValidate());
        dispatch(getPeopleFieldOptions({ id: jobId }));
        dispatch(
          getPeopleList({
            ...tableFilter,
            page: selectPeople.length === peopleList.length && Math.ceil(pagination.total / pagination.perPage) === pagination.page ? 1 : pagination.page
          })
        );
      })
    );
  };

  const handleCaptureToggle = () => setShowCapture(!showCapture);
  const handleCaptureNoShow = () => {
    dispatch(updateMyAccountStudioRequest({ id: studio.id, display_capture_message: false }, () => setShowCapture(!showCapture)));
  };

  const handleMapConfirmClose = () => setShowMapConfirmModal(false);

  const handleAppendConfirmClose = () => setShowAppendConfirmModal(false);

  const handleUploadMessageReceive = {
    received(message) {
      if (message.type === 'subjects_updated') {
        dispatch(setPeopleDetails(message));
      }

      if (message.type === 'photo_stats') {
        dispatch(updatePhotoStats(message));
      }
    }
  };

  const formatFilterLabelName = (field = '') => {
    let formattedName;

    if (field) {
      formattedName = field
        .replace('session_start', 'check-in_date')
        ?.replace('_', ' ')
        ?.toLowerCase()
        ?.split(' ')
        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        ?.join(' ');
    }

    return formattedName;
  };

  const DropdownFilter = ({ dropdownPosition, fieldName, filters }) => (
    <ul className={`panel panel-dropdown panel-dropdown--medium panel-dropdown--${dropdownPosition}`}>
      <li className="panel-dropdown__item">
        <h6>Sort</h6>
        <fieldset>
          <input
            id={fieldName + 'ascending'}
            className="hidden"
            type="checkbox"
            checked={tableFilter.order === fieldName && tableFilter.dir === 'ASC'}
            onChange={(e) => handleOrderBy(e, { order: fieldName, dir: 'ASC' })}
          />
          <label className="label--checkbox label--clean text--normal" htmlFor={fieldName + 'ascending'}>
            Ascending
          </label>
        </fieldset>
        <fieldset className="fieldset--clean">
          <input
            id={fieldName + 'descending'}
            className="hidden"
            type="checkbox"
            checked={tableFilter.order === fieldName && tableFilter.dir === 'DESC'}
            onChange={(e) => handleOrderBy(e, { order: fieldName, dir: 'DESC' })}
          />
          <label className="label--checkbox label--clean text--normal" htmlFor={fieldName + 'descending'}>
            Descending
          </label>
        </fieldset>
      </li>
      {filters && (
        <li className="panel-dropdown__item">
          <h6>Filter</h6>
          {filters.map(({ label, filter, method }, index, array) => {
            let isChecked = false;

            isChecked = tableFilter.filter.scopes.includes(filter);

            return (
              <fieldset key={fieldName + index} className={index === array.length - 1 ? 'fieldset--clean' : ''}>
                <input
                  id={fieldName + index}
                  className="hidden"
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => handleOrderBy(e, { order: fieldName, filter, method })}
                />
                <label className="label--checkbox label--clean text--normal" htmlFor={fieldName + index}>
                  {label}
                </label>
              </fieldset>
            );
          })}
        </li>
      )}
    </ul>
  );

  useActionCable({ channel: 'JobEventsChannel', job_id: jobId }, handleUploadMessageReceive);

  useEffect(() => {
    if (!Object.keys(fields).length) {
      dispatch(getPeopleFieldOptions({ id: jobId }));
    }

    dispatch(getPeopleList(tableFilter));
    dispatch(getMyAccountStudioRequest({ studioId }));
  }, []);

  useEffect(() => {
    if (showAddEdit === false) {
      setSelectPeople([]);
    }
  }, [showAddEdit]);

  useEffect(() => {
    const keys = Object.keys(fields);

    if (labels.length === 0 && keys.length > 0) {
      dispatch(setPeopleLabels(keys.slice(0, MAX_LABEL_LENGTH)));
    }
  }, [fields]);

  useEffect(() => {
    if (lookupConfigValidate?.some((item) => !item.valid)) {
      setShowLookupWarningModal(true);
    }
  }, [lookupConfigValidate]);

  return (
    <>
      <Header history={history} jobId={jobId} title="Subjects" />

      <main className="container job-people-list">
        <header className="flex items-center justify-between job-people-list__header">
          {/* Mapping */}
          {data.headers.length ? (
            <>
              <h1 className="text-headline-md">Map Subject Data</h1>
              <aside className="grid grid-cols-2 gap-2.5">
                <button className="button button--outline" name="cancel" type="button" onClick={handleClearMapData}>
                  Cancel
                </button>
                <button
                  className="button"
                  name="map"
                  type="button"
                  disabled={map.filter((item) => (item.value === 'first_name' && item.map) || (item.value === 'last_name' && item.map)).length !== 2}
                  onClick={handleUpdateMapCheck}
                >
                  Map
                </button>
              </aside>
            </>
          ) : (
            <>
              <h1 className="text-headline-md">Subjects Data {pagination.total > 0 ? `(${pagination.total})` : ''}</h1>
              <aside className="flex justify-end items-center button-group md:hidden">
                <Tooltip
                  title="Appending Data"
                  position="top"
                  arrow="true"
                  interactive
                  html={
                    <a
                      href="https://support.photoday.io/en/articles/5334171-how-do-i-append-data-to-an-existing-private-gallery"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      How do I append data?
                    </a>
                  }
                >
                  <i className="fa fa-question-circle-o fa-lg" />
                </Tooltip>
                <Dropdown buttonName={'Add'} buttonExtraClass={'button--lean button-dropdown'}>
                  <ul className="panel panel-dropdown">
                    <li className="panel-dropdown__item" onClick={() => handleAddEditShow()}>
                      Subjects data
                    </li>
                    <li className="panel-dropdown__item" onClick={handleDropzoneDialogOpen}>
                      CSV file
                    </li>
                  </ul>
                </Dropdown>
                <Link className="button button--outline" to={`/jobs/${jobId}/services/exports`} disabled={peopleList.length === 0}>
                  Exports
                </Link>
                <Link className="button" to={`/jobs/${jobId}/gallery`}>
                  Done
                </Link>
              </aside>
            </>
          )}
        </header>

        {/* Mapping */}
        {data.headers.length ? (
          <section className="panel animate mb-10">
            <ul className="grid job-people-list__map">
              {data.headers.map((header, index) => {
                const selectOptions = map.filter(({ value: mapValue }) => mapValue !== 'session_start').map((item) => ({ ...item, map: header }));
                const selectedMappedObject = map.find((item) => item.map === header);
                const shouldShowWarning = map.some((item) => item.map === header) === false;

                return (
                  <li className="flex flex-col" key={index}>
                    <Select
                      className={`select job-people-list__map-select ${shouldShowWarning ? 'job-people-list__map-select--warning' : ''}`}
                      classNamePrefix="select"
                      isSearchable={false}
                      isClearable={true}
                      placeholder="Map to..."
                      value={selectedMappedObject || ''}
                      options={selectOptions}
                      onChange={(select, { action }) => (action === 'clear' ? handleMapSelect({ ...selectedMappedObject, map: '' }) : handleMapSelect(select))}
                    />
                    <h5 className={`job-people-list__map-title ${shouldShowWarning ? 'job-people-list__map-title--warning' : ''}`}>{header}</h5>
                    <ol className="job-people-list__map-list">
                      {data.rows.map((row, index) => (
                        <li className="job-people-list__map-item" key={index}>
                          {row[header]}
                        </li>
                      ))}
                      <li className="job-people-list__map-item job-people-list__map-item--small">
                        <small>... and several more</small>
                      </li>
                    </ol>
                  </li>
                );
              })}
            </ul>
          </section>
        ) : (
          <section className="mb-10">
            {Object.keys(fields).length > 0 && (
              <>
                <aside className="flex gap-2.5 mb-2.5">
                  <form onSubmit={handlePeopleSearch}>
                    <fieldset className="fieldset--clean">
                      <input
                        className="input--clean"
                        type="search"
                        name="search"
                        placeholder="Search Subjects"
                        maxLength="50"
                        value={search}
                        onChange={handleSearchChange}
                      />
                      <button
                        className={`button button--clear button--small ${search.length ? '' : 'hidden'}`}
                        name="clear"
                        type="button"
                        onClick={handleSearchClear}
                      >
                        Clear
                      </button>
                      <button className="button button--icon" name="search" type="submit">
                        <i className="icon-search" />
                      </button>
                    </fieldset>
                  </form>
                  <Dropdown buttonName={'Edit Columns'} buttonExtraClass={'button--lean button-dropdown'}>
                    <ul className="panel panel-dropdown">
                      <li className="panel-dropdown__item">
                        <h5 className="m-0">
                          Data labels <br />
                          <small>Select up to 3 to show.</small>
                        </h5>
                      </li>
                      <li className="panel-dropdown__item">
                        <fieldset>
                          <input id="photos_count" className="hidden" type="checkbox" name="photos_count" checked={true} readOnly={true} />
                          <label className="label--checkbox label--clean label--disabled" htmlFor="photos_count">
                            Photo Count
                          </label>
                        </fieldset>
                        <fieldset>
                          <input id="last_name" className="hidden" type="checkbox" name="last_name" checked={true} readOnly={true} />
                          <label className="label--checkbox label--clean label--disabled" htmlFor="last_name">
                            Last Name
                          </label>
                        </fieldset>
                        <fieldset>
                          <input id="first_name" className="hidden" type="checkbox" name="first_name" checked={true} readOnly={true} />
                          <label className="label--checkbox label--clean label--disabled" htmlFor="first_name">
                            First Name
                          </label>
                        </fieldset>
                        <fieldset className="fieldset--clean">
                          <input id="access_id" className="hidden" type="checkbox" name="access_id" checked={true} readOnly={true} />
                          <label className="label--checkbox label--clean label--disabled" htmlFor="access_id">
                            Access/Opt-in Code
                          </label>
                        </fieldset>
                      </li>
                      <li className="panel-dropdown__item">
                        {map
                          .filter((item) => item.value !== 'first_name' && item.value !== 'last_name' && item.value !== 'access_id' && item.value !== 'absent')
                          .filter((item) => Object.keys(fields).includes(item.value))
                          .map((item, index, array) => (
                            <fieldset key={item.value} className={index === array.length - 1 ? 'fieldset--clean' : ''}>
                              <input
                                id={item.value}
                                className="hidden"
                                type="checkbox"
                                name={item.value}
                                checked={labels.some((label) => label === item.value)}
                                onChange={handleLabelsChange}
                              />
                              <label className="label--checkbox label--clean" htmlFor={item.value}>
                                {item.label}
                              </label>
                            </fieldset>
                          ))}
                      </li>
                    </ul>
                  </Dropdown>
                  {/* Delete */}
                  <Dropdown buttonName={'Delete'} buttonExtraClass={'button--danger-outline button-dropdown'}>
                    <ul className="panel panel-dropdown">
                      <li
                        className={`panel-dropdown__item ${selectPeople.length || job.photo_stats?.subjects !== pagination.total ? 'disabled' : ''}`}
                        onClick={() => handleDeleteShow('all')}
                      >
                        All Subjects
                      </li>
                      <li className={`panel-dropdown__item ${!selectPeople.length ? 'disabled' : ''}`} onClick={handleDeleteShow}>
                        Selected Subjects
                      </li>
                    </ul>
                  </Dropdown>
                </aside>
                <article className="table-box">
                  <table className="table">
                    <thead className="table__header">
                      <tr>
                        <th>
                          <input
                            id="all"
                            className="hidden"
                            type="checkbox"
                            name="all"
                            checked={peopleList.length > 0 && selectPeople.length === peopleList.length}
                            onChange={(e) => handleSelectPeople(e, 'all')}
                          />
                          <label htmlFor="all" className="label--checkbox label--clean" />
                        </th>
                        <th>
                          <Dropdown
                            selected={
                              tableFilter.filter.scopes.includes('with_ref') ||
                              tableFilter.filter.scopes.includes('without_ref') ||
                              tableFilter.filter.scopes.includes('all_ref')
                            }
                            buttonName={'Ref'}
                            buttonExtraClass={'button--filter'}
                          >
                            <DropdownFilter
                              dropdownPosition={'left'}
                              fieldName={'image_name'}
                              filters={[
                                { label: 'All', filter: 'all_ref', method: '' },
                                { label: 'With Ref', filter: 'with_ref', method: 'filterable_scope' },
                                { label: 'Blank', filter: 'without_ref', method: 'filterable_scope' }
                              ]}
                            />
                          </Dropdown>
                        </th>
                        <th>
                          <Dropdown
                            selected={tableFilter.filter.scopes.includes('has_photos') || tableFilter.filter.scopes.includes('has_no_photos')}
                            buttonName={'Photo Count'}
                            buttonExtraClass={'button--filter'}
                          >
                            <DropdownFilter
                              fieldName={'subject_photos_count'}
                              filters={[
                                { label: 'With Photo Count', filter: 'has_photos', method: 'filterable_scope' },
                                { label: 'Blank', filter: 'has_no_photos', method: 'filterable_scope' }
                              ]}
                            />
                          </Dropdown>
                        </th>
                        <th>
                          <Dropdown
                            selected={
                              tableFilter.filter.scopes.includes('blank_absent') ||
                              tableFilter.filter.scopes.includes('absent') ||
                              tableFilter.filter.scopes.includes('no_absent')
                            }
                            buttonName={'Absent'}
                            buttonExtraClass={'button--filter'}
                          >
                            <DropdownFilter
                              fieldName={'absent'}
                              filters={[
                                { label: 'Yes', filter: 'absent', method: 'filterable_scope' },
                                { label: 'No', filter: 'no_absent', method: 'filterable_scope' },
                                { label: 'Blank', filter: 'blank_absent', method: 'filterable_scope' }
                              ]}
                            />
                          </Dropdown>
                        </th>
                        <th>
                          <Dropdown
                            selected={tableFilter.filter.scopes.includes('without_last_name') || tableFilter.filter.scopes.includes('with_last_name')}
                            buttonName={'Last Name'}
                            buttonExtraClass={'button--filter'}
                          >
                            <DropdownFilter
                              fieldName={'last_name'}
                              filters={[
                                { label: 'With Last Name', filter: 'with_last_name', method: 'filterable_scope' },
                                { label: 'Blank', filter: 'without_last_name', method: 'filterable_scope' }
                              ]}
                            />
                          </Dropdown>
                        </th>
                        <th>
                          <Dropdown
                            selected={tableFilter.filter.scopes.includes('without_first_name') || tableFilter.filter.scopes.includes('with_first_name')}
                            buttonName={'First Name'}
                            buttonExtraClass={'button--filter'}
                          >
                            <DropdownFilter
                              fieldName={'first_name'}
                              filters={[
                                { label: 'With First Name', filter: 'with_first_name', method: 'filterable_scope' },
                                { label: 'Blank', filter: 'without_first_name', method: 'filterable_scope' }
                              ]}
                            />
                          </Dropdown>
                        </th>
                        <th>
                          <Dropdown
                            selected={tableFilter.filter.scopes.includes('without_access_code') || tableFilter.filter.scopes.includes('with_access_code')}
                            buttonName={'Access/Opt-in Code'}
                            buttonExtraClass={'button--filter'}
                          >
                            <DropdownFilter
                              fieldName={'access_id'}
                              filters={[
                                { label: 'With Access Code', filter: 'with_access_code', method: 'filterable_scope' },
                                { label: 'Blank', filter: 'without_access_code', method: 'filterable_scope' }
                              ]}
                            />
                          </Dropdown>
                        </th>
                        {Object.keys(fields)
                          .filter((field) => labels.includes(field))
                          .filter((field) => field !== 'first_name' && field !== 'last_name' && field !== 'access_id' && field !== 'absent')
                          .map((field) => (
                            <th key={field}>
                              <Dropdown
                                selected={tableFilter.filter.scopes.includes(`without_${field}`) || tableFilter.filter.scopes.includes(`with_${field}`)}
                                buttonName={field.replace('session_start', 'check-in_date').replace('_', ' ')}
                                buttonExtraClass={'button--filter'}
                              >
                                <DropdownFilter
                                  fieldName={field}
                                  filters={[
                                    { label: formatFilterLabelName(`with_${field}`), filter: `with_${field}`, method: '' },
                                    { label: 'Blank', filter: `without_${field}`, method: '' }
                                  ]}
                                />
                              </Dropdown>
                            </th>
                          ))}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="table__body">
                      {peopleRequesting ? (
                        <tr>
                          <td colSpan="12">
                            <TableLoader rows={15} rowHeight={75} />
                          </td>
                        </tr>
                      ) : peopleList.length ? (
                        <>
                          {peopleList.map((person) => (
                            <tr key={person.id}>
                              <td className="md:hidden">
                                <input
                                  id={person.id}
                                  className="hidden"
                                  type="checkbox"
                                  name={person.first_name}
                                  checked={selectPeople.some((select) => select === person.id)}
                                  onChange={(e) => handleSelectPeople(e, person.id)}
                                />
                                <label htmlFor={person.id} className="label--checkbox label--clean" />
                              </td>
                              <td data-header="Ref">
                                <figure className="job-people-list__figure">
                                  <img
                                    className="job-people-list__image"
                                    src={
                                      person.session_photo_url
                                        ? imageScaling({ url: person.session_photo_url, size: 'small' })
                                        : imagePeopleThumbnailPlaceholder
                                    }
                                    alt={person.first_name}
                                    width="35"
                                  />
                                </figure>
                              </td>
                              <td data-header="Photo Count" className="text-center">
                                {person.photos_count}
                              </td>
                              <td data-header="Absent">{person.absent ? 'Yes' : 'No'}</td>
                              <td data-header="Last Name">{person.last_name}</td>
                              <td data-header="First Name">{person.first_name}</td>
                              <td data-header="Access/Opt-in Code">{person.access_id}</td>
                              {Object.keys(fields)
                                .filter((field) => labels.includes(field))
                                .filter((field) => field !== 'first_name' && field !== 'last_name' && field !== 'access_id' && field !== 'absent')
                                .map((field) => (
                                  <td key={field} headers={field.replace('session_start', 'check-in_date').replace('_', ' ')}>
                                    {field === 'phone' ? normalizePhone(person[field]) : person[field]}
                                  </td>
                                ))}
                              <td data-header="Action" className="flex nowrap">
                                <Tooltip title="Settings" position="bottom" arrow="true" distance="30">
                                  <button className="button button--clean" name="button" type="button" onClick={() => handleAddEditShow(person.id)}>
                                    <i className="icon-edit" />
                                  </button>
                                </Tooltip>
                                <Tooltip title="Remove" position="bottom" arrow="true" distance="30">
                                  <button className="button button--clean" name="button" type="button" onClick={() => handleDeleteShow(null, person.id)}>
                                    <i className="icon-trash" />
                                  </button>
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={Object.keys(fields).length + 4}>
                            No items were found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </article>
                {pagination.total > pagination.perPage && <Pagination pagination={pagination} onPagination={handlePagination} showPagesCount={3} />}
              </>
            )}

            <Dropzone
              ref={dropzoneRef}
              multiple={false}
              maxSize={10000000}
              accept={{ 'text/csv': ['.csv'] }}
              noClick={true}
              noKeyboard={true}
              noDragEventsBubbling={true}
              preventDropOnDocument={true}
              onDropAccepted={handleDropAccepted}
            >
              {({ getRootProps, getInputProps }) => (
                <>
                  {Object.keys(fields).length ? (
                    <input {...getInputProps({ disabled: false })} />
                  ) : (
                    <section className="flex flex-col items-center justify-center panel panel--secondary mb-10 job-people-list__add" {...getRootProps()}>
                      <input {...getInputProps({ disabled: false })} />
                      <figure>
                        <img src={imagePeoplePlaceholder} alt="Add subjects" width="60" />
                      </figure>
                      <h4 className="job-people-list__add-title">Subjects Data</h4>
                      <p>There are a few ways you can add subjects data. Simply drag and drop a .CSV file anywhere on this screen</p>
                      <p>or</p>
                      <aside className="flex grow-0 center gap-2.5">
                        <button className="button button--lean button--large" name="csv" type="button" onClick={handleDropzoneDialogOpen}>
                          Upload .CSV File
                        </button>
                        <button className="button button--lean button--large" name="data" type="button" onClick={() => handleAddEditShow()}>
                          Enter Data Manually
                        </button>
                      </aside>
                    </section>
                  )}
                </>
              )}
            </Dropzone>
          </section>
        )}

        {/* Code LookUp Configuration */}
        {CODE_LOOKUP_ENABLED && <CodeLookup />}
      </main>

      <AddEdit
        map={map}
        jobId={jobId}
        studio={studio}
        showAddEdit={showAddEdit}
        selectPeople={selectPeople}
        onAddEditToggle={handleAddEditToggle}
        onCaptureToggle={handleCaptureToggle}
      />

      {/* Mapping update confirmation modal */}
      {showMapConfirmModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--xsmall modal__box--nomin">
            <header className="modal__header">
              <button className="button button--action modal__close" name="button" type="button" onClick={handleMapConfirmClose}>
                <i className="icon-close"></i>
              </button>
              <h3 className="m-0">Confirm</h3>
            </header>
            <main className="modal__content">
              <p>Would you like to continue mapping data without these columns?</p>
              <ul>
                {missingMappingHeaders.map((header) => (
                  <li key={header}>
                    <h6>{header}</h6>
                  </li>
                ))}
              </ul>
            </main>
            <footer className="text-center modal__footer">
              <button className="button button--large" name="confirm" type="button" data-loading={requesting} onClick={handleAppendMapCheck}>
                Confirm
              </button>
              <hr />
              <p className="m-0">
                <small>
                  Need help? Check out our{' '}
                  <a
                    href="https://support.photoday.io/en/articles/3379907-what-types-of-data-exports-are-available-to-me"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support article on export files
                  </a>
                  .
                </small>
              </p>
            </footer>
          </div>
        </aside>
      )}

      {/* Confirm appending data */}
      {showAppendConfirmModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--xsmall modal__box--nomin">
            <header className="modal__header">
              <button className="button button--action modal__close" name="button" type="button" onClick={handleAppendConfirmClose}>
                <i className="icon-close"></i>
              </button>
              <h3 className="m-0">Confirm Appending Data</h3>
            </header>
            <main className="modal__content">
              <p>
                Are you sure you want to delete{' '}
                <strong>
                  {performAllDelete ? job.photo_stats?.subjects : selectPeople.length} of {job.photo_stats?.subjects} subject(s)
                </strong>
                ?
              </p>
              <ul>
                <li>
                  <h4>
                    {fieldsMapDetails.currentCount} <small>subjects in the job before import</small>
                  </h4>
                </li>
                <li>
                  <h4>
                    {Number(fieldsMapDetails.willAdd + fieldsMapDetails.willUpdate) || 0} <small>subjects in the CSV</small>
                  </h4>
                </li>
                <li>
                  <h4>
                    {fieldsMapDetails.willUpdate} <small>records will be updated</small>
                  </h4>
                </li>
                <li>
                  <h4>
                    {fieldsMapDetails.willAdd} <small>records will be added</small>
                  </h4>
                </li>
                <li>
                  <h4>
                    {Number(fieldsMapDetails.currentCount + fieldsMapDetails.willAdd) || 0} <small>total subjects in the job after import</small>
                  </h4>
                </li>
              </ul>
            </main>
            <footer className="text-center modal__footer">
              <button className="button button--large" name="confirm" type="button" data-loading={requesting} onClick={handleMap}>
                Confirm
              </button>
              <hr />
              <p className="m-0">
                <small>
                  Need help? Check out our{' '}
                  <a
                    href="https://support.photoday.io/en/articles/3379907-what-types-of-data-exports-are-available-to-me"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support article on export files
                  </a>
                  .
                </small>
              </p>
            </footer>
          </div>
        </aside>
      )}

      {/* Delete modal */}
      {showDeleteModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--small">
            <header className="modal__header">
              <h3>Delete Subject(s)</h3>
            </header>
            <main className="modal__content">
              <p>
                Are you sure you want to delete{' '}
                <strong>
                  {performAllDelete ? job.photo_stats?.subjects : selectPeople.length} of {job.photo_stats?.subjects} subject(s)
                </strong>
                ?
              </p>
              <p>
                Type <b>"delete"</b> to permanently delete all selected subject(s).
              </p>
              <aside className="flex gap-2.5 items-center flex-nowrap mb-5">
                <div>
                  <i className="icon-warning-outline" />
                </div>
                <p className="text-body-xs italic m-0">
                  Deleted subjects can't be restored, and customers who are subscribed to their access ID's will lose connection to their galleries. Consider
                  updating your data instead of deleting.
                </p>
              </aside>
              <input className="input--block" type="text" name="delete" value={confirmDelete} onChange={handleDeleteChange} maxLength="20" />
            </main>
            <footer className="modal__footer flex gap-2.5 justify-end">
              <button className="button button--outline" name="cancel" type="button" onClick={handleDeleteCancel}>
                Cancel
              </button>
              <button
                className="button button--danger"
                name="delete"
                type="button"
                data-loading={requesting}
                onClick={handleDelete}
                disabled={requesting || !(confirmDelete.toLowerCase() === 'delete')}
              >
                Delete
              </button>
            </footer>
          </div>
        </aside>
      )}

      {/* Download capture app modal */}
      {showCapture && (
        <aside className="modal animate">
          <div className="modal__box job-people-list__capture">
            <header className="modal__header">
              <button className="button button--action modal__close" name="close" type="button" onClick={handleCaptureToggle}>
                <i className="icon-close"></i>
              </button>
            </header>
            <main className="modal__content">
              <h2>Time to Download PhotoDay Capture!</h2>
              <p>
                Awesome! You’ve Successfully added subjects data into this gallery. Now it’s time to download the PhotoDay Capture app onto the mobile device(s)
                you’ll be using on picture day. You’ll use the app to snap each person’s reference photos.
              </p>
              <a
                href="https://support.photoday.io/en/articles/3365618-can-i-use-the-photoday-capture-app-on-more-than-one-device"
                target="_blank"
                rel="noopener noreferrer"
              >
                Using the PhotoDay Capture app on more than one device?
              </a>
              <aside className="job-people-list__capture-action">
                <p>
                  <b>Download the app…</b>
                </p>
                <div className="flex text-center justify-between">
                  <a href="https://apps.apple.com/us/app/photoday-capture/id1370434730" target="_blank" rel="noopener noreferrer">
                    <img src={imageAppStore} alt="App store" width="135" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=io.photoday.capture" target="_blank" rel="noopener noreferrer">
                    <img src={imageGooglePlay} alt="Google Play" width="135" />
                  </a>
                </div>
              </aside>
            </main>
            <footer className="grid justify-center gap-5 modal__footer">
              <button className="button button--link" name="view" type="button" onClick={handleCaptureToggle}>
                View subjects data.
              </button>
              <button className="button button--link button--grey" onClick={handleCaptureNoShow}>
                View subjects data and don’t show this message again.
              </button>
            </footer>
          </div>
        </aside>
      )}

      {/* Update reference photos modal */}
      {showUpdateReferencePhotoModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--dark modal__box--small modal__box--nomin">
            <header className="modal__header">
              <button className="button button--action modal__close" name="close" type="button" onClick={() => setShowUpdateReferencePhotoModal(false)}>
                <i className="icon-close"></i>
              </button>
              <h3>Import</h3>
            </header>
            <main className="modal__content">
              <p>Do you want to update your current reference photos with the image from the image name column?</p>
            </main>
            <footer className="text-center modal__footer button-group">
              <button className="button button--medium" name="yes" type="button" onClick={() => handleShouldUpdateReferencePhoto(true)}>
                Yes
              </button>
              <button className="button button--outline button--medium" name="no" type="button" onClick={() => handleShouldUpdateReferencePhoto(false)}>
                No
              </button>
            </footer>
          </div>
        </aside>
      )}
    </>
  );
};

List.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.object.isRequired
};

export default List;
