import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Redux
import { useSelector } from 'react-redux';

const Upcoming = function Upcoming() {
  const { upcomingWebinars } = useSelector((state) => state.home);

  const liveUpcomingWebinars = upcomingWebinars.map((liveWebinar) => ({
    imageUrl: liveWebinar.image_url,
    title: liveWebinar.title,
    subtitle: liveWebinar.scheduled_at.replace(/\//g, '.'),
    buttonTitle: 'Register',
    buttonLink: liveWebinar.register_url,
    description: liveWebinar.description
  }));

  return (
    <>
      <header className="flex items-center animate academy-container__header">
        <h2 className="text-headline-sm">Upcoming</h2>
      </header>
      {!liveUpcomingWebinars?.length ? (
        <h4 className="text--normal">
          Stay tuned for the latest updates on our academy events! We'll share all the exciting details right here as soon as they're available.
        </h4>
      ) : (
        <section className="animate academy__events-grid">
          {liveUpcomingWebinars?.map((upcomingWebinar, index) => (
            <div className="academy__event" key={index}>
              <figure key={index}>
                <LazyLoadImage className="academy__event-image" src={upcomingWebinar.imageUrl} alt={upcomingWebinar.title} draggable={false} effect="opacity" />
                <figcaption>
                  <small className="font-semibold academy__event-title">{upcomingWebinar.title}</small>
                  <p className="font-semibold summary__event-subtitle">{upcomingWebinar.subtitle}</p>
                  <div className="academy__event-description">
                    {upcomingWebinar.description.split('\n').map((line, i) => (
                      <p key={i} dangerouslySetInnerHTML={{ __html: line }}></p>
                    ))}
                  </div>
                  <a
                    href={upcomingWebinar.buttonLink}
                    className="button button--outline button--block"
                    disabled={upcomingWebinar.buttonTitle === 'Coming Soon'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {upcomingWebinar.buttonTitle}
                  </a>
                </figcaption>
              </figure>
            </div>
          ))}
        </section>
      )}
    </>
  );
};

export default Upcoming;
