import { FC, useEffect, useState, useRef, useLayoutEffect } from 'react';

// Plugins
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Components
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Images
import imagePeopleThumbnailPlaceholder from '@/assets/images/people-thumbnail-placeholder.png';

// Styles
import './style.css';

// Types
import { Subject, Photo } from '@/types';

interface FeatureSubjectPhoto {
  photoId: string;
  moveToNext?: boolean;
}

interface FeatureManagerProps {
  subject: Subject;
  imageTitle: string;
  requesting: boolean;
  onClose: () => void;
  exportPrimaryId?: string;
  onSelect: ({ photoId, moveToNext }: FeatureSubjectPhoto) => void;
  onPhotosPeopleFilter?: (subject: Subject) => void;
}

const FeatureManager: FC<FeatureManagerProps> = ({ subject, requesting, imageTitle, exportPrimaryId, onSelect, onClose, onPhotosPeopleFilter }) => {
  const [selected, setSelected] = useState<string>('');

  const photoRef = useRef<HTMLDivElement>(null);
  const oneTimeRef = useRef<boolean>(false);

  const handleSelectPhoto = (photo: Photo) => {
    setSelected(photo.id);
  };

  const handleFeature = ({ photoId, moveToNext = false }: FeatureSubjectPhoto) => {
    onSelect({ photoId, moveToNext });
    setSelected('');
  };

  useEffect(() => {
    if (!exportPrimaryId && subject && subject.primary_photo_id) {
      setSelected(subject.primary_photo_id);
    }

    if (exportPrimaryId) setSelected(exportPrimaryId);
  }, [subject]);

  useLayoutEffect(() => {
    if (!oneTimeRef.current && photoRef.current instanceof HTMLElement) {
      const active = photoRef.current.querySelector('.feature-manager__photo--active');

      if (active) {
        oneTimeRef.current = true;
        active.scrollIntoView({ block: 'center' });
      }
    }
  }, [selected]);

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--large">
        <main className="modal__content">
          <header className="modal__header">
            {subject && (subject.first_name || subject.last_name) ? (
              <aside className="flex flex-nowrap items-center feature-manager__subject-details">
                <div className="feature-manager__subject__image-container">
                  <LazyLoadImage
                    className="feature-manager__subject__image"
                    src={subject.session_photo_url ? imageScaling({ url: subject.session_photo_url, size: 'small' }) : imagePeopleThumbnailPlaceholder}
                    alt={subject.first_name || 'subject photo'}
                    height={40}
                    draggable="false"
                  />
                </div>
                <dl className="flex gap-4 grow">
                  <div className="flex flex-col">
                    <dt className="font-semibold">Subject Name</dt>
                    <dd>{subject.first_name && subject.last_name ? `${subject.first_name} ${subject.last_name}` : '-----'}</dd>
                  </div>
                  <div className="flex flex-col sm:hidden">
                    <dt className="font-semibold">Access Code</dt>
                    <dd>{subject.access_id ? subject.access_id : '-----'}</dd>
                  </div>
                  <div className="flex flex-col sm:hidden">
                    <dt className="font-semibold">Student Id</dt>
                    <dd>{subject.student_id ? subject.student_id : '-----'}</dd>
                  </div>
                  <div className="flex flex-col md:hidden">
                    <dt className="font-semibold">Grade</dt>
                    <dd>{subject.grade ? subject.grade : '-----'}</dd>
                  </div>
                  <div className="flex flex-col md:hidden">
                    <dt className="font-semibold">Teacher</dt>
                    <dd>{subject.teacher_name ? subject.teacher_name : '-----'}</dd>
                  </div>
                  <div className="flex flex-col md:hidden">
                    <dt className="font-semibold">School</dt>
                    <dd>{subject.school ? subject.school : '-----'}</dd>
                  </div>
                </dl>
                {onPhotosPeopleFilter && (
                  <button
                    className="button button--outline"
                    onClick={() => {
                      onPhotosPeopleFilter(subject);
                    }}
                  >
                    Detail
                  </button>
                )}
              </aside>
            ) : (
              <TextLoader height={77} />
            )}
            <small>{`To change the current ${imageTitle}, select a photo below`}</small>
          </header>
          <section className="h-600 max-h-600 overflow-auto mt-5">
            {requesting ? (
              <GridLoader rows={1} columns={4} gap={20} minHeight={264} />
            ) : (
              <>
                {subject?.photos?.length ? (
                  <div className="feature-manager__photos animate" ref={photoRef}>
                    {subject.photos.map((photo, index) => (
                      <div
                        className={`feature-manager__photo-container ${photo.id === selected ? 'feature-manager__photo-container--active' : ''}`}
                        key={index}
                        onClick={() => handleSelectPhoto(photo)}
                        onDoubleClick={() => handleFeature({ photoId: photo.id })}
                      >
                        <header className="feature-manager__photo-header">
                          <h6 className="m-0 truncate">{photo.image_filename}</h6>
                        </header>
                        <LazyLoadImage
                          className="feature-manager__photo"
                          alt={photo.image_filename}
                          src={imageScaling({ url: photo?.retouched_image_url ?? photo.image_url, size: 'medium' })}
                          draggable="false"
                          effect={'opacity'}
                          delayTime={600}
                          delayMethod={'debounce'}
                        />
                        <footer className="feature-manager__photo-footer">
                          <i className={`icon-star ${photo.id === selected ? '' : 'icon-star--empty'}`}></i>
                        </footer>
                      </div>
                    ))}
                  </div>
                ) : (
                  <aside className="panel">
                    <h5 className="m-0">No photos were found for this subject.</h5>
                  </aside>
                )}
              </>
            )}
          </section>
        </main>
        <footer className="modal__footer flex justify-end gap-2.5">
          <button className="button button--outline" type="button" name="cancel" onClick={onClose}>
            Cancel
          </button>
          <button
            className="button"
            type="button"
            disabled={!selected || requesting}
            onClick={() => handleFeature({ photoId: selected })}
            data-loading={requesting}
          >
            Save
          </button>
          <button
            className="button button--medium button--outline"
            type="button"
            disabled={!selected || requesting}
            onClick={() => handleFeature({ photoId: selected, moveToNext: true })}
            data-loading={requesting}
          >
            Save & Continue
          </button>
        </footer>
      </div>
    </aside>
  );
};

export default FeatureManager;
