import { useState, useEffect } from 'react';
import { InputGroup, Select } from '@/components/Shared/Forms';

const PercentOrFixedOffers = ({ onChange, offerType: initialOfferType, amount: initialAmount, required }) => {
  const [offerType, setOfferType] = useState();
  const [amount, setAmount] = useState();

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
    onChange &&
      onChange({
        amount: event.target.value,
        offer_type: offerType
      });
  };

  const handleOfferTypeChange = (event) => {
    setOfferType(event.target.value);
    onChange && onChange({ amount, offer_type: event.target.value });
  };

  useEffect(() => {
    setOfferType(String(initialOfferType));
    setAmount(Number(initialAmount));
  }, [initialOfferType, initialAmount]);

  return (
    <fieldset id="fixed-discount-container">
      <label>Discount</label>
      <div className="flex gap-2">
        <div className="basis-6/12">
          <Select
            required
            options={[
              { value: 'fixed_amount', label: 'Fixed Dollar Amount' },
              { value: 'fixed_percentage', label: 'Percent' }
            ]}
            name="offer_type"
            onChange={handleOfferTypeChange}
          />
        </div>
        <div className="basis-6/12">
          <InputGroup
            name="amount"
            placeholder={offerType === 'fixed_percentage' ? '0' : '0.00'}
            addonPosition={offerType === 'fixed_percentage' ? 'right' : 'left'}
            onChange={handleAmountChange}
            required={required}
            type="number"
          >
            <span>{offerType === 'fixed_percentage' ? '%' : '$'}</span>
          </InputGroup>
        </div>
      </div>
    </fieldset>
  );
};

export default PercentOrFixedOffers;
