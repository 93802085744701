import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { CustomCheckboxList } from '@/components/Shared/Forms';

// Images
import icon_offer_discount from '@/assets/images/icon_offer_discount.png';
import icon_offer_shipping from '@/assets/images/icon_offer_shipping.png';

const PercentOrFixedCheckbox = ({ onChange, value }) => {
  const handleSelectOnClick = () => {
    let currentValue = ['fixed_amount', 'fixed_percentage'].includes(value) ? value : 'fixed_amount';
    onChange(currentValue, 1);
  };

  return (
    <div className="offer-item">
      <img src={icon_offer_discount} alt="" className="offer-image" onClick={handleSelectOnClick} />
      <h4 className="font-semibold">Discount</h4>
      <p> Take a percentage or a fixed amount off of the order total. </p>
      <button onClick={handleSelectOnClick} type="button" className="button center-block">
        Select{' '}
      </button>
    </div>
  );
};

PercentOrFixedCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

const FreeShippingCheckbox = ({ onChange }) => {
  const handleSelectOnClick = () => {
    onChange('shipping', 1);
  };

  return (
    <div className="offer-item">
      <img src={icon_offer_shipping} alt="" className="offer-image" onClick={handleSelectOnClick} />
      <h4 className="font-semibold">Free Shipping</h4>
      <p> Get free shipping on all orders over a specified purchase amount. </p>

      <button onClick={handleSelectOnClick} type="button" className="button center-block">
        Select{' '}
      </button>
    </div>
  );
};

FreeShippingCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

const OfferType = ({ onSelectOfferType, offerTypeSelected }) => {
  useEffect(() => {
    onSelectOfferType(offerTypeSelected);
  }, [onSelectOfferType, offerTypeSelected]);

  const handleCheckboxListChange = (newValue) => {
    onSelectOfferType && onSelectOfferType(newValue);
  };

  const options = [PercentOrFixedCheckbox, FreeShippingCheckbox];

  return (
    <div>
      <CustomCheckboxList name="offer_type" className="offer-list" options={options} onChange={handleCheckboxListChange} />
    </div>
  );
};

OfferType.propTypes = {
  onSelectOfferType: PropTypes.func,
  offerTypeSelected: PropTypes.string
};

export default OfferType;
