import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as Sentry from '@sentry/browser';

import * as actions from './actions';
import * as types from './constants';
import * as storage from '@/utils/storage';
import * as loginActions from '@/components/Login/actions';

import * as api from '@/api/user-api';
import * as labApi from '@/api/lab-api';
import * as creditsApi from '@/api/credits-api';

import successHandler from '@/utils/successResponseHandler';
import errorHandler from '@/utils/errorResponseHandler';

const LOGIN_ENTITIES = import.meta.env.VITE_LOGIN_ENTITIES;

function* getMyAccountStudioRequestFlow(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getStudio, payload);

    yield put(actions.getMyAccountStudioRequestSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getMyAccountStudioRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getMyAccountStudioTransactionsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getStudioTransactions, payload);

    yield put(actions.getMyAccountStudioTransactionsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getMyAccountStudioTransactionsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateMyAccountStudioRequestFlow(action) {
  const { studio, successCallback, errorCallback } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.updateStudio, { studio });
    yield put(loginActions.updateLoginStudio(response));

    const shouldShowSucessToaster = !studio?.studio_verification_attributes;
    if (shouldShowSucessToaster) {
      yield call(successHandler('Successfully saved'), actions.updateMyAccountStudioRequestSuccess, response);
    } else {
      yield put(actions.updateMyAccountStudioRequestSuccess(response));
    }

    if (successCallback) yield call(successCallback);
  } catch (error) {
    Sentry.captureException(error);
    yield call(errorHandler, actions.updateMyAccountStudioRequestError, error);
    if (errorCallback) yield call(errorCallback, error);
  } finally {
    yield put(hideLoading());
  }
}

// Stripe
function* updateStripeAccountRequestFlow(action) {
  try {
    yield put(showLoading());

    const { payload } = action;
    const response = yield call(api.updateStripeAccount, payload);
    yield call(successHandler('Successfully saved'), actions.updateStripeAccountRequestSuccess, response);
  } catch (error) {
    yield call(errorHandler, actions.updateStripeAccountRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteStripeAccountRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { studioId }
    } = action;
    const response = yield call(api.deleteStripeAccount, studioId);
    yield call(successHandler('Successfully disconnected'), actions.deleteStripeAccountRequestSuccess, response);
  } catch (error) {
    yield call(errorHandler, actions.deleteStripeAccountRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createStripeExpressOnboardingRequest(action) {
  const { payload, callback } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(api.createStripeExpressOnboarding, payload);
    yield put(actions.createStripeExpressOnboardingSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createStripeExpressOnboardingError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createStripeExpressCompleteRequest(action) {
  const { payload, callback } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(api.createStripeExpressComplete, payload);
    yield put(actions.createStripeExpressCompleteSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createStripeExpressCompleteError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createStripeExpressInstantPayoutRequest(action) {
  const { payload, callback } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(api.createStripeExpressInstantPayout, payload);
    yield put(actions.createStripeExpressInstantPayoutSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createStripeExpressInstantPayoutError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createStripeExpressReportRequest(action) {
  const { payload, callback } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(api.createStripeExpressReport, payload);
    yield put(actions.createStripeExpressReportSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createStripeExpressReportError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getStripeExpressInstantPayoutInfoRequest(action) {
  const { payload, callback } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(api.getStripeExpressInstantPayoutInfo, payload);
    yield put(actions.getStripeExpressInstantPayoutInfoSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getStripeExpressInstantPayoutInfoError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateMyAccountRequestFlow(action) {
  const { payload } = action;
  const { callback } = payload;

  try {
    yield put(showLoading());

    const { data } = yield call(api.updateAccount, payload);

    yield call(successHandler('Successfully saved'), actions.updateMyAccountRequestSuccess, data);

    const userEntity = { [data.id]: data };
    const json = yield call(storage.get, LOGIN_ENTITIES);

    let loginEntities = JSON.parse(json);

    loginEntities.user = userEntity;
    storage.set(LOGIN_ENTITIES, JSON.stringify(loginEntities), true);

    if (callback) callback(data);
  } catch (error) {
    yield call(errorHandler, actions.updateMyAccountRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getMyAccountRequestFlow() {
  try {
    yield put(showLoading());

    const response = yield call(api.getMyAccount);
    yield put(actions.getMyAccountRequestSuccess(response));
  } catch (error) {
    yield call(errorHandler, actions.getMyAccountRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getCurrentTermsRequestFlow() {
  try {
    yield put(showLoading());

    const { data } = yield call(api.getCurrentTerms);
    yield put(actions.getCurrentTermsRequestSuccess(data));
  } catch (error) {
    yield call(errorHandler, actions.getCurrentTermsRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getUserStudiosRequestFlow(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getStudios, payload);

    yield put(actions.getUserStudiosRequestSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getUserStudiosRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* changeCurrentStudioRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { studio, callback }
    } = action;
    const json = yield call(storage.get, LOGIN_ENTITIES);
    const studioEntity = { [studio.id]: studio };

    let loginEntities = JSON.parse(json);
    loginEntities.studio = studioEntity;

    // Store slim studio data so correct studio is added to header for restricted api.
    storage.set(LOGIN_ENTITIES, JSON.stringify(loginEntities), true);

    if (studio.id !== 'all-studios') {
      const { data: studioData } = yield call(api.getStudio, { studioId: studio.id });

      loginEntities.studio[studio.id] = studioData;
      storage.set(LOGIN_ENTITIES, JSON.stringify(loginEntities), true);
    }

    if (callback) {
      callback(loginEntities.studio);
    }
  } catch (error) {
    yield call(errorHandler, actions.changeCurrentStudioRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createNexusRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.createNexus, payload);

    yield call(successHandler('Nexus Successfully created'), actions.createNexusSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createNexusError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getNexusListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getNexusList, payload);

    yield put(actions.getNexusListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getNexusListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteNexusRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.deleteNexus, payload);

    yield call(successHandler('Nexus Successfully deleted'), actions.deleteNexusSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteNexusError, error);
  } finally {
    yield put(hideLoading());
  }
}

// Subscriptions
function* createSubscriptionRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.createSubscription, payload);

    yield call(successHandler('Subscription Successfully created'), actions.createSubscriptionSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createSubscriptionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getSubscriptionsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getSubscriptions, payload);

    yield put(actions.getSubscriptionsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getSubscriptionsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getSubscriptionRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getSubscription, payload);

    yield put(actions.getSubscriptionSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getSubscriptionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getSubscriptionPlansRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getSubscriptionPlans, payload);

    yield put(actions.getSubscriptionPlansSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getSubscriptionPlansError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getSubscriptionCouponRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getSubscriptionCoupon, payload);

    yield put(actions.getSubscriptionCouponSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getSubscriptionCouponError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteSubscriptionRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.deleteSubscription, payload);

    yield call(successHandler('Subscription Successfully deleted'), actions.deleteSubscriptionSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteSubscriptionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getStudioLabsInStudioRequest(action) {
  const {
    payload: { payload, callback }
  } = action;
  try {
    yield put(showLoading());

    const response = yield call(labApi.getStudioLabsList, payload);

    yield put(actions.getStudioLabsInStudioSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getStudioLabsInStudioError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* addStudioLabToStudioRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(labApi.addStudioLabToStudio, payload);

    yield put(actions.addStudioLabtoStudioSuccess(response));
    yield put(loginActions.updateLoginStudioLabs(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.addStudioLabtoStudioError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getAllLabsRequest(action) {
  const {
    payload: { callback }
  } = action;
  try {
    yield put(showLoading());

    const response = yield call(labApi.getAllLabs);

    yield put(actions.getAllLabsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getAllLabsError, error);
  } finally {
    yield put(hideLoading());
  }
}

// Users
function* getUserListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getUserList, payload);

    yield put(actions.getUserListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getUserListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getUserRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getUser, payload);

    yield put(actions.getUserSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getUserError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateUserRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.updateUser, payload);

    yield call(successHandler('User updated'), actions.updateUserSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updateUserError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createStudioUserInvitationRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.createStudioUserInvitation, payload);

    yield call(successHandler('Photographer has been invited by email'), actions.createStudioUserInvitationSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createStudioUserInvitationError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createStudioUserAcceptInvitationRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.createStudioUserAcceptInvitation, payload);

    yield call(successHandler('Accepted invitation'), actions.createStudioUserAcceptInvitationSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createStudioUserAcceptInvitationError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getStudioUserInvitationDetailsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getStudioUserInvitationDetails, payload);

    yield put(actions.getStudioUserInvitationDetailsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getStudioUserInvitationDetailsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getStudioUsersRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getStudioUsers, payload);

    yield put(actions.getStudioUsersSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getStudioUsersError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteStudioUserInvitationRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.deleteStudioUserInvitation, payload);

    yield call(successHandler('User has been removed'), actions.deleteStudioUserInvitationSuccess, {
      ...response,
      ...payload
    });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteStudioUserInvitationError, error);
  } finally {
    yield put(hideLoading());
  }
}

// Studio credit system
function* createStudioCreditPurchaseRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(creditsApi.createStudioCreditPurchase, payload);

    yield put(actions.createStudioCreditPurchaseSuccess(response));
    yield put(loginActions.updateLoginStudio(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createStudioCreditPurchaseError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getUserWatcher() {
  yield all([
    takeLatest(types.GET_MYACCOUNT_STUDIO_REQUEST, getMyAccountStudioRequestFlow),
    takeLatest(types.GET_MYACCOUNT_STUDIO_TRANSACTIONS_REQUEST, getMyAccountStudioTransactionsRequest),
    takeLatest(types.UPDATE_MYACCOUNT_STUDIO_REQUEST, updateMyAccountStudioRequestFlow),
    takeLatest(types.GET_MYACCOUNT_REQUEST, getMyAccountRequestFlow),
    takeLatest(types.GET_CURRENT_TERMS_REQUEST, getCurrentTermsRequestFlow),
    takeLatest(types.UPDATE_MYACCOUNT_REQUEST, updateMyAccountRequestFlow),
    takeLatest(types.UPDATE_STRIPE_ACCOUNT_REQUEST, updateStripeAccountRequestFlow),
    takeLatest(types.DELETE_STRIPE_ACCOUNT_REQUEST, deleteStripeAccountRequestFlow),
    takeLatest(types.CREATE_STRIPE_EXPRESS_ONBOARDING_REQUEST, createStripeExpressOnboardingRequest),
    takeLatest(types.CREATE_STRIPE_EXPRESS_COMPLETE_REQUEST, createStripeExpressCompleteRequest),
    takeLatest(types.CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_REQUEST, createStripeExpressInstantPayoutRequest),
    takeLatest(types.CREATE_STRIPE_EXPRESS_REPORT_REQUEST, createStripeExpressReportRequest),
    takeLatest(types.GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_REQUEST, getStripeExpressInstantPayoutInfoRequest),
    takeLatest(types.GET_USER_STUDIOS_REQUEST, getUserStudiosRequestFlow),
    takeLatest(types.CHANGE_CURRENT_STUDIO_REQUEST, changeCurrentStudioRequestFlow),
    takeLatest(types.GET_NEXUS_LIST, getNexusListRequest),
    takeLatest(types.CREATE_NEXUS, createNexusRequest),
    takeLatest(types.DELETE_NEXUS, deleteNexusRequest),
    takeLatest(types.CREATE_SUBSCRIPTION, createSubscriptionRequest),
    takeLatest(types.GET_SUBSCRIPTIONS, getSubscriptionsRequest),
    takeLatest(types.GET_SUBSCRIPTION, getSubscriptionRequest),
    takeLatest(types.GET_SUBSCRIPTION_PLANS, getSubscriptionPlansRequest),
    takeLatest(types.GET_SUBSCRIPTION_COUPON, getSubscriptionCouponRequest),
    takeLatest(types.DELETE_SUBSCRIPTION, deleteSubscriptionRequest),
    takeLatest(types.GET_STUDIO_LABS_IN_STUDIO, getStudioLabsInStudioRequest),
    takeLatest(types.ADD_STUDIO_LAB_TO_STUDIO, addStudioLabToStudioRequest),
    takeLatest(types.GET_ALL_LABS, getAllLabsRequest),
    takeLatest(types.GET_USER_LIST_REQUEST, getUserListRequest),
    takeLatest(types.GET_USER_REQUEST, getUserRequest),
    takeLatest(types.UPDATE_USER_REQUEST, updateUserRequest),
    takeLatest(types.CREATE_STUDIO_USER_INVITATION, createStudioUserInvitationRequest),
    takeLatest(types.CREATE_STUDIO_USER_ACCEPT_INVITATION, createStudioUserAcceptInvitationRequest),
    takeLatest(types.GET_STUDIO_USER_INVITATION_DETAILS, getStudioUserInvitationDetailsRequest),
    takeLatest(types.GET_STUDIO_USERS, getStudioUsersRequest),
    takeLatest(types.DELETE_STUDIO_USER_INVITATION, deleteStudioUserInvitationRequest),
    takeLatest(types.CREATE_STUDIO_CREDIT_PURCHASE_REQUEST, createStudioCreditPurchaseRequest)
  ]);
}

export default getUserWatcher;
