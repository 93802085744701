import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  createPeopleLookupConfig,
  createPeopleLookupValidate,
  getPeopleLookupConfig,
  getPeopleCsvColumnConfig,
  resetPeopleLookupValidate
} from '../../../actions';

// Plugins
import Select, { SingleValue, ActionMeta } from 'react-select';

// Components
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Styles
import './style.css';

// Types
import { SelectOptionType, LookupConfig, LookupConfigField, LookupFieldType, LookupConfigFieldValidate, CsvColumnConfig } from '@/types';

const CodeLookup: FC = () => {
  const dispatch = useDispatch();
  const params: { jobId: string } = useParams();
  const { jobId } = params;

  const {
    job: { job_status: jobStatus },
    people: { list: peopleList, lookupConfig, lookupConfigValidate, csvColumnConfig, requesting }
  } = useSelector((state: any) => state.jobs);

  // State
  const [stateLookupConfig, setStateLookupConfig] = useState<LookupConfig>();

  // UI Handlers
  const handleFilterDataChange = (select: SingleValue<SelectOptionType>, actionMeta: ActionMeta<any>) => {
    if (!select || !stateLookupConfig) return;

    const { name: fieldId } = actionMeta;
    const { value: fieldValue, label: fieldText } = select as SelectOptionType;
    const currentColumnConfig = csvColumnConfig.find((item: CsvColumnConfig) => item.field_id === fieldValue);
    const currentColumnDefaultType = currentColumnConfig?.field_types?.length ? currentColumnConfig.field_types[0] : null;

    const newFields = stateLookupConfig?.fields
      ? stateLookupConfig.fields?.map((field: LookupConfigField) => {
          const sameValue = field.field_id === fieldValue;

          if (field.field_id === fieldId && !sameValue && fieldValue && typeof fieldText === 'string') {
            return { ...field, field_id: fieldValue, field_text: fieldText, text_override: '', field_type: currentColumnDefaultType };
          }
          return field;
        })
      : [];

    setStateLookupConfig({ ...stateLookupConfig, enabled: true, fields: newFields });
  };

  const handleFieldTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!stateLookupConfig) return;

    const { name, value } = event.target;
    const newFields = stateLookupConfig?.fields
      ? stateLookupConfig.fields?.map((field: LookupConfigField) => {
          if (field.field_id === name) {
            field.field_type = value as LookupFieldType;
          }
          return field;
        })
      : [];

    setStateLookupConfig({ ...stateLookupConfig, enabled: true, fields: newFields });
  };

  const handleFieldRenameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!stateLookupConfig) return;

    const { name, value } = event.target;
    const newFields = stateLookupConfig?.fields
      ? stateLookupConfig.fields?.map((field: LookupConfigField) => {
          if (field.field_id === name) {
            return { ...field, text_override: value };
          }

          return field;
        })
      : [];

    setStateLookupConfig({ ...stateLookupConfig, enabled: true, fields: newFields });
  };

  const handleAddField = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!stateLookupConfig) return;
    const addIndex = event.currentTarget.getAttribute('data-index');
    const newFields = stateLookupConfig?.fields
      ? stateLookupConfig.fields?.map((field: LookupConfigField, index) => {
          if (addIndex && index === parseInt(addIndex)) {
            return { ...field, field_id: addIndex, field_type: 'text' as LookupFieldType };
          }
          return field;
        })
      : [];

    setStateLookupConfig({ ...stateLookupConfig, enabled: true, fields: newFields });
  };

  const handleRemoveField = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!stateLookupConfig) return;

    const removeIndex = event.currentTarget.getAttribute('data-index');
    const newFields = stateLookupConfig?.fields
      ? stateLookupConfig.fields?.map((field: LookupConfigField, index) => {
          if (removeIndex && index === parseInt(removeIndex)) {
            return { ...field, field_id: '_empty', field_type: null, text_override: null };
          }
          return field;
        })
      : [];

    setStateLookupConfig({ ...stateLookupConfig, enabled: true, fields: newFields });
  };

  const handleSave = (): void => {
    const filteredFields = stateLookupConfig?.fields?.filter((field) => field.field_id && field.field_id !== '_empty');
    const lookupConfigPayload = { jobId, lookupConfig: { ...stateLookupConfig, fields: filteredFields } };

    if (!stateLookupConfig?.enabled) {
      dispatch(resetPeopleLookupValidate());
    }

    if (stateLookupConfig?.enabled && peopleList.length > 0) {
      dispatch(
        createPeopleLookupValidate({ jobId, lookupConfig: { fields: filteredFields } }, ({ data }: { data: LookupConfigFieldValidate[] }) => {
          if (jobStatus === 'onsale') {
            const hasErrors = data.some((field) => !field.valid);

            if (!hasErrors) {
              dispatch(createPeopleLookupConfig(lookupConfigPayload));
            }
          } else {
            dispatch(createPeopleLookupConfig(lookupConfigPayload));
          }
        })
      );
    } else {
      dispatch(createPeopleLookupConfig(lookupConfigPayload));
    }
  };

  useEffect(() => {
    dispatch(getPeopleCsvColumnConfig());
    dispatch(getPeopleLookupConfig({ jobId }));
  }, []);

  useEffect(() => {
    // Set initial state hydration
    setStateLookupConfig(lookupConfig);
  }, [lookupConfig]);

  useEffect(() => {
    if (peopleList?.length > 0 && lookupConfig.enabled) {
      dispatch(createPeopleLookupValidate({ jobId, lookupConfig }));
    }
  }, [peopleList, lookupConfig]);

  return (
    <section>
      <header>
        <div className="flex gap-2.5 items-center mb-2.5">
          <h2 className="text-headline-sm">Gallery Lookup Configuration</h2>
          <span className="pill pill--small pill--blue">Beta</span>
          <input
            id="lookup"
            className="hidden"
            name="lookup"
            type="checkbox"
            checked={stateLookupConfig?.enabled || false}
            onChange={() => (stateLookupConfig ? setStateLookupConfig({ ...stateLookupConfig, enabled: !stateLookupConfig.enabled }) : null)}
          />
          <label className="label-switch label-switch--small" htmlFor="lookup" />
        </div>
        <p>
          Allow customers to look up a subject’s private gallery without an access code. Select fields below for customers to input when searching <br />
          for a private gallery from an Organization Page or Job URL. Input Fields should match imported Subject Data columns.{' '}
          <a href="https://support.photoday.io/en/articles/9339805-private-gallery-lookup" target="_blank" rel="noopener noreferrer">
            Learn More
          </a>
          .
        </p>
      </header>
      <div className="panel">
        {requesting ? (
          <GridLoader rows={1} columns={5} gap={20} minHeight={400} />
        ) : (
          <>
            <ul className="grid grid-cols-5 md:grid-cols-2 gap-5">
              {stateLookupConfig?.fields?.map((lookupField: LookupConfigField, fieldIndex: number, configFieldsArr: LookupConfigField[]) => {
                const fieldValidation: LookupConfigFieldValidate = lookupConfigValidate?.length
                  ? lookupConfigValidate.find((item: LookupConfigFieldValidate) => item.field_id === lookupField.field_id)
                  : null;

                const matchedCsvColumn: CsvColumnConfig = csvColumnConfig.find((csvColumn: CsvColumnConfig) => csvColumn.field_id === lookupField.field_id);

                const unusedCsvColumnField = csvColumnConfig.filter(
                  (item: LookupConfigField) =>
                    item.field_id === lookupField.field_id || !configFieldsArr.some((configField) => configField.field_id === item.field_id)
                );
                const filterDataOptions = unusedCsvColumnField.map((item: CsvColumnConfig) => ({ label: item.field_text, value: item.field_id }));
                const filterDataValue = filterDataOptions.find((option: SelectOptionType) => option.value === lookupField.field_id);

                return (
                  <li key={`${lookupField.field_id}${fieldIndex}`}>
                    {lookupField.field_id === '_empty' ? (
                      <div className="job-lookup__add" data-index={fieldIndex} onClick={handleAddField}>
                        <i className="icon-plus" />
                        <span>Add Lookup Field</span>
                      </div>
                    ) : (
                      <>
                        <header className="job-lookup__header">
                          <div className="flex items-center gap-2.5">
                            <legend className="m-0">Field {fieldIndex + 1}</legend>
                            {fieldValidation && <>{fieldValidation?.valid ? <i className="icon-checkmark" /> : <i className="icon-triangle-warning" />}</>}
                          </div>
                          {lookupField.removable && (
                            <button className="button button--clean" name="remove" type="button" data-index={fieldIndex} onClick={handleRemoveField}>
                              <i className="icon-trash" />
                            </button>
                          )}
                        </header>
                        <fieldset className="mb-5">
                          <label htmlFor={`filterData${fieldIndex}`}>{lookupField.changeable ? 'Select Input Field' : 'Input Field'}</label>
                          {lookupField.changeable ? (
                            <>
                              <Select
                                id={`filterData${fieldIndex}`}
                                className="select"
                                classNamePrefix="select"
                                value={filterDataValue}
                                name={lookupField.field_id}
                                isLoading={requesting && !csvColumnConfig.length}
                                options={filterDataOptions}
                                onChange={handleFilterDataChange}
                              />
                              {fieldValidation && !fieldValidation?.valid && (
                                <span className="text-error-500 text-body-sm italic">The filter data does not match what's in your mapped data</span>
                              )}
                            </>
                          ) : (
                            <input
                              id={`filterData${fieldIndex}`}
                              type="text"
                              name={`filterData${fieldIndex}`}
                              value={lookupField?.field_text || ''}
                              readOnly={true}
                            />
                          )}
                        </fieldset>
                        {lookupField.changeable && matchedCsvColumn?.field_types?.length && (
                          <fieldset className="mb-5">
                            <label htmlFor={`inputType${fieldIndex}`} className="label--block">
                              Select Type
                            </label>
                            {matchedCsvColumn.field_types.map((fieldType: LookupFieldType, fieldTypeIndex: number) => (
                              <div key={fieldTypeIndex} className="flex items-center mb-2.5">
                                <input
                                  id={`${fieldType}${fieldIndex}`}
                                  type="radio"
                                  name={lookupField.field_id}
                                  value={fieldType}
                                  checked={lookupField.field_type === fieldType}
                                  onChange={handleFieldTypeChange}
                                />
                                <label htmlFor={`${fieldType}${fieldIndex}`} className="label--clean text--normal capitalize">
                                  {fieldType.replace('_', ' ')}
                                </label>
                              </div>
                            ))}
                          </fieldset>
                        )}
                        <fieldset>
                          <label htmlFor={`inputRename${fieldIndex}`}>Rename Input Field (Optional)</label>
                          <input
                            id={`inputRename${fieldIndex}`}
                            type="text"
                            name={lookupField.field_id}
                            value={lookupField?.text_override || ''}
                            maxLength={50}
                            onChange={handleFieldRenameChange}
                          />
                        </fieldset>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
            <footer className="flex justify-end">
              <button className="button" type="button" name="save" disabled={requesting} onClick={handleSave}>
                Save
              </button>
            </footer>
          </>
        )}
      </div>
    </section>
  );
};

export default CodeLookup;
