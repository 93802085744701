import * as types from './constants';
import { handleErrorState, handleRequestingState, handleSuccessState } from '@/utils/reducerHelpers';

const initialState = {
  user: {},
  users: [],

  studio: {},
  studioWalletTransactions: {
    summary: {},
    list: [],
    pagination: { page: 0, perPage: 15, total: 0 }
  },
  studios: {
    list: [],
    pagination: { page: 0, perPage: 15, total: 0 }
  },

  invitations: {
    list: [],
    pagination: { page: 1, perPage: 15, total: 0 }
  },

  userInvitationDetails: {},

  nexus: [],
  account: {},

  plans: [],
  coupon: {},
  currentTerms: {},
  subscription: {},
  subscriptions: [],

  stripeExpressPayoutInfo: {},

  requesting: false,
  successful: false,
  loadStudios: true,
  errors: []
};

const handleGetMyAccountStudioSuccess = (state, payload) => {
  return {
    ...state,
    studio: payload.studio.data,
    errors: [],
    requesting: false,
    successful: true,
    loadStudios: false
  };
};

const handleGetMyAccountStudioTransactionsSuccess = (state, payload) => {
  const { studio: studioPayload } = payload;
  const { transactions: transactionsPayload, ...transactionSummaryPayload } = studioPayload?.data || {};

  return {
    ...state,
    studioWalletTransactions: {
      ...state.studioWalletTransactions,
      ...(Object.keys(transactionSummaryPayload)?.length ? { summary: transactionSummaryPayload } : {}),
      ...(transactionsPayload ? { list: transactionsPayload } : {}),
      pagination: {
        page: Number(studioPayload?.headers['x-page']),
        perPage: Number(studioPayload?.headers['x-per-page']),
        total: Number(studioPayload?.headers['x-total'])
      }
    },

    errors: [],
    requesting: false,
    successful: true,
    loadStudios: false
  };
};

const handleGetMyAccountSuccess = (state, payload) => {
  const { account } = payload;
  const { data } = account;
  return {
    ...state,
    ...{ account: data, errors: [], requesting: false, successful: true }
  };
};

const handleUpdateMyAccountStudioSuccess = (state, payload) => {
  const { studio } = payload;
  const { data } = studio;

  return {
    ...state,
    ...{
      errors: [],
      requesting: false,
      successful: true,
      studio: data,
      loadStudios: true
    }
  };
};

const handleUpdateMyAccountSuccess = (state, payload) => {
  const { account } = payload;
  return {
    ...state,
    account: { ...state.account, ...account },
    ...{ errors: [], requesting: false, successful: true }
  };
};

const handleGetCurrentTermsSuccess = (state, payload) => {
  const { terms } = payload;

  const policiesAgreedTerms = terms.find((policy) => policy.key === 'policies_agreed') || {};
  const currentDate = new Date().toISOString();

  return {
    ...state,
    currentTerms: { ...policiesAgreedTerms, last_requested_at: currentDate },
    ...{ errors: [], requesting: false, successful: true }
  };
};

const handleGetUserStudiosSuccess = (state, { payload }) => {
  return {
    ...state,
    studios: {
      list: payload.data,
      pagination: {
        page: Number(payload.headers['x-page']),
        perPage: Number(payload.headers['x-per-page']),
        total: Number(payload.headers['x-total'])
      }
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateNexusSuccess = (state) => {
  return {
    ...state,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetNexusListSuccess = (state, { payload }) => {
  return {
    ...state,
    nexus: Object.values(payload.data),
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleDeleteNexusSuccess = (state, { payload }) => {
  const filtered = state.nexus.filter((address) => address.id !== payload.data.id);
  const nexus = Object.assign([], state.nexus, [...filtered, payload.data]);

  return {
    ...state,
    nexus,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateStripeExpressCompleteSuccess = (state, { payload }) => {
  return {
    ...state,
    studio: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateStripeExpressInstantPayoutSuccess = (state, { payload }) => {
  return {
    ...state,
    stripeExpressPayoutInfo: { ...state.stripeExpressPayoutInfo, ...payload.data },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetStripeExpressInstantPayoutInfoSuccess = (state, { payload }) => {
  return {
    ...state,
    stripeExpressPayoutInfo: { ...state.stripeExpressPayoutInfo, ...payload.data },
    errors: [],
    successful: true,
    requesting: false
  };
};

// Subscriptions
const handleCreateSubscriptionSuccess = (state, { payload }) => {
  return {
    ...state,
    studio: Object.assign({}, state.studio, {
      school_subscription: payload.data
    }),
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetSubscriptionsSuccess = (state, { payload }) => {
  return {
    ...state,
    subscriptions: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetSubscriptionSuccess = (state, { payload }) => {
  return {
    ...state,
    subscription: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetSubscriptionPlansSuccess = (state, { payload }) => {
  return {
    ...state,
    plans: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetSubscriptionCouponSuccess = (state, { payload }) => {
  return {
    ...state,
    coupon: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleRemoveSubscriptionCoupon = (state) => {
  return {
    ...state,
    coupon: {}
  };
};

const handleDeleteSubscriptionSuccess = (state) => {
  return {
    ...state,
    studio: Object.assign({}, state.studio, { school_subscription: null }),
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetStudioLabsInStudioSuccess = (state, payload) => {
  const labs = payload.payload;
  return {
    ...state,
    entities: {
      ...state.entities,
      studioLabs: {
        ...labs
      }
    },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleAddStudioLabToSuccess = (state, payload) => {
  const lab = payload.payload;
  const currentLabs = Object.keys(state.entities.studioLabs);

  return {
    ...state,
    entities: {
      ...state.entities,
      studioLabs: {
        ...state.entities.studioLabs,
        [currentLabs.length]: lab
      }
    },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleGetAllLabsSuccess = (state, payload) => {
  const labs = payload.payload?.data;

  return {
    ...state,
    entities: {
      ...state.entities,
      allLabs: {
        ...labs
      }
    },
    errors: [],
    requesting: false,
    successful: true
  };
};

// Users
const handleGetUserListSuccess = (state, { payload }) => {
  return {
    ...state,
    users: payload.data,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdateUserSuccess = (state, { payload }) => {
  const { users } = state;
  const { data } = payload;

  const updatedUsers = users.map((user) => (user.id === data.id ? data : user));

  return {
    ...state,
    user: {},
    users: updatedUsers,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateStudioUserInvitationSuccess = (state) => {
  return {
    ...state,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateStudioUserAcceptInvitationSuccess = (state) => {
  return {
    ...state,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetStudioUserInvitationDetailsSuccess = (state, { payload }) => {
  return {
    ...state,
    userInvitationDetails: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetStudioUserInvitationsSuccess = (state, { payload }) => {
  return {
    ...state,
    invitations: {
      list: payload.data,
      pagination: {
        page: Number(payload.headers['x-page']),
        perPage: Number(payload.headers['x-per-page']),
        total: Number(payload.headers['x-total'])
      }
    },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleDeleteStudioUserInvitationSuccess = (state, { payload }) => {
  return {
    ...state,
    invitations: {
      list: state.invitations.list.filter((invitation) => invitation.id !== payload.id),
      pagination: {
        page: Number(payload.headers['x-page']),
        perPage: Number(payload.headers['x-per-page']),
        total: Number(payload.headers['x-total'])
      }
    },
    errors: [],
    successful: true,
    requesting: false
  };
};

// Studio credit system
const handleStudioCreditPurchaseSuccess = (state, { payload }) => {
  return {
    ...state,
    studio: payload.data,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdateMyAccountStudioFromCable = (state, { payload }) => {
  const { studio: stateStudio } = state;

  return {
    ...state,
    studio: { ...stateStudio, ...payload },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleResetUserSettingsState = () => {
  return {
    ...initialState
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_MYACCOUNT_STUDIO_REQUEST:
    case types.GET_MYACCOUNT_STUDIO_TRANSACTIONS_REQUEST:
    case types.UPDATE_MYACCOUNT_STUDIO_REQUEST:
    case types.GET_MYACCOUNT_REQUEST:
    case types.GET_CURRENT_TERMS_REQUEST:
    case types.UPDATE_STRIPE_ACCOUNT_REQUEST:
    case types.DELETE_STRIPE_ACCOUNT_REQUEST:
    case types.CREATE_STRIPE_EXPRESS_ONBOARDING_REQUEST:
    case types.CREATE_STRIPE_EXPRESS_COMPLETE_REQUEST:
    case types.CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_REQUEST:
    case types.CREATE_STRIPE_EXPRESS_REPORT_REQUEST:
    case types.GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_REQUEST:
    case types.UPDATE_MYACCOUNT_REQUEST:
    case types.GET_USER_STUDIOS_REQUEST:
    case types.CREATE_NEXUS:
    case types.DELETE_NEXUS:
    case types.GET_NEXUS_LIST:
    case types.CREATE_SUBSCRIPTION:
    case types.GET_SUBSCRIPTIONS:
    case types.GET_SUBSCRIPTION:
    case types.GET_SUBSCRIPTION_PLANS:
    case types.GET_SUBSCRIPTION_COUPON:
    case types.DELETE_SUBSCRIPTION:
    case types.GET_STUDIO_LABS_IN_STUDIO:
    case types.ADD_STUDIO_LAB_TO_STUDIO:
    case types.GET_ALL_LABS:
    case types.GET_USER_LIST_REQUEST:
    case types.GET_USER_REQUEST:
    case types.UPDATE_USER_REQUEST:
    case types.CREATE_STUDIO_CREDIT_PURCHASE_REQUEST:
    case types.CREATE_STUDIO_USER_INVITATION:
    case types.CREATE_STUDIO_USER_ACCEPT_INVITATION:
    case types.GET_STUDIO_USER_INVITATION_DETAILS:
    case types.GET_STUDIO_USERS:
    case types.DELETE_STUDIO_USER_INVITATION:
      return handleRequestingState(state);

    case types.GET_USER_SUCCESS:
    case types.UPDATE_STRIPE_ACCOUNT_SUCCESS:
    case types.DELETE_STRIPE_ACCOUNT_SUCCESS:
    case types.CREATE_STRIPE_EXPRESS_ONBOARDING_SUCCESS:
    case types.CREATE_STRIPE_EXPRESS_REPORT_SUCCESS:
      return handleSuccessState(state);

    case types.CREATE_STRIPE_EXPRESS_COMPLETE_SUCCESS:
      return handleCreateStripeExpressCompleteSuccess(state, payload);
    case types.CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_SUCCESS:
      return handleCreateStripeExpressInstantPayoutSuccess(state, payload);
    case types.GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_SUCCESS:
      return handleGetStripeExpressInstantPayoutInfoSuccess(state, payload);
    case types.CREATE_NEXUS_SUCCESS:
      return handleCreateNexusSuccess(state, payload);
    case types.GET_NEXUS_LIST_SUCCESS:
      return handleGetNexusListSuccess(state, payload);
    case types.DELETE_NEXUS_SUCCESS:
      return handleDeleteNexusSuccess(state, payload);
    case types.GET_MYACCOUNT_STUDIO_SUCCESS:
      return handleGetMyAccountStudioSuccess(state, payload);
    case types.GET_MYACCOUNT_STUDIO_TRANSACTIONS_SUCCESS:
      return handleGetMyAccountStudioTransactionsSuccess(state, payload);
    case types.UPDATE_MYACCOUNT_STUDIO_SUCCESS:
      return handleUpdateMyAccountStudioSuccess(state, payload);
    case types.GET_MYACCOUNT_SUCCESS:
      return handleGetMyAccountSuccess(state, payload);
    case types.UPDATE_MYACCOUNT_SUCCESS:
      return handleUpdateMyAccountSuccess(state, payload);
    case types.GET_CURRENT_TERMS_SUCCESS:
      return handleGetCurrentTermsSuccess(state, payload);
    case types.GET_USER_STUDIOS_SUCCESS:
      return handleGetUserStudiosSuccess(state, payload);
    case types.CREATE_STUDIO_USER_INVITATION_SUCCESS:
      return handleCreateStudioUserInvitationSuccess(state, payload);
    case types.CREATE_STUDIO_USER_ACCEPT_INVITATION_SUCCESS:
      return handleCreateStudioUserAcceptInvitationSuccess(state, payload);
    case types.GET_STUDIO_USER_INVITATION_DETAILS_SUCCESS:
      return handleGetStudioUserInvitationDetailsSuccess(state, payload);
    case types.GET_STUDIO_USERS_SUCCESS:
      return handleGetStudioUserInvitationsSuccess(state, payload);
    case types.DELETE_STUDIO_USER_INVITATION_SUCCESS:
      return handleDeleteStudioUserInvitationSuccess(state, payload);
    case types.CREATE_SUBSCRIPTION_SUCCESS:
      return handleCreateSubscriptionSuccess(state, payload);
    case types.GET_SUBSCRIPTIONS_SUCCESS:
      return handleGetSubscriptionsSuccess(state, payload);
    case types.GET_SUBSCRIPTION_SUCCESS:
      return handleGetSubscriptionSuccess(state, payload);
    case types.GET_SUBSCRIPTION_PLANS_SUCCESS:
      return handleGetSubscriptionPlansSuccess(state, payload);
    case types.GET_SUBSCRIPTION_COUPON_SUCCESS:
      return handleGetSubscriptionCouponSuccess(state, payload);
    case types.DELETE_SUBSCRIPTION_SUCCESS:
      return handleDeleteSubscriptionSuccess(state, payload);
    case types.GET_STUDIO_LABS_IN_STUDIO_SUCCESS:
      return handleGetStudioLabsInStudioSuccess(state, payload);
    case types.ADD_STUDIO_LAB_TO_STUDIO_SUCCESS:
      return handleAddStudioLabToSuccess(state, payload);
    case types.GET_ALL_LABS_SUCCESS:
      return handleGetAllLabsSuccess(state, payload);
    case types.GET_USER_LIST_SUCCESS:
      return handleGetUserListSuccess(state, payload);
    case types.UPDATE_USER_SUCCESS:
      return handleUpdateUserSuccess(state, payload);
    case types.CREATE_STUDIO_CREDIT_PURCHASE_SUCCESS:
      return handleStudioCreditPurchaseSuccess(state, payload);

    case types.UPDATE_MYACCOUNT_STUDIO_ERROR:
    case types.GET_MYACCOUNT_STUDIO_ERROR:
    case types.GET_MYACCOUNT_STUDIO_TRANSACTIONS_ERROR:
    case types.GET_MYACCOUNT_ERROR:
    case types.GET_CURRENT_TERMS_ERROR:
    case types.UPDATE_STRIPE_ACCOUNT_ERROR:
    case types.DELETE_STRIPE_ACCOUNT_ERROR:
    case types.CREATE_STRIPE_EXPRESS_ONBOARDING_ERROR:
    case types.CREATE_STRIPE_EXPRESS_COMPLETE_ERROR:
    case types.CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_ERROR:
    case types.CREATE_STRIPE_EXPRESS_REPORT_ERROR:
    case types.GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_ERROR:
    case types.UPDATE_MYACCOUNT_ERROR:
    case types.GET_USER_STUDIOS_ERROR:
    case types.CREATE_NEXUS_ERROR:
    case types.DELETE_NEXUS_ERROR:
    case types.GET_NEXUS_LIST_ERROR:
    case types.CREATE_SUBSCRIPTION_ERROR:
    case types.GET_SUBSCRIPTIONS_ERROR:
    case types.GET_SUBSCRIPTION_ERROR:
    case types.GET_SUBSCRIPTION_PLANS_ERROR:
    case types.GET_SUBSCRIPTION_COUPON_ERROR:
    case types.DELETE_SUBSCRIPTION_ERROR:
    case types.GET_STUDIO_LABS_IN_STUDIO_ERROR:
    case types.ADD_STUDIO_LAB_TO_STUDIO_ERROR:
    case types.GET_ALL_LABS_ERROR:
    case types.GET_USER_ERROR:
    case types.UPDATE_USER_ERROR:
    case types.CREATE_STUDIO_USER_INVITATION_ERROR:
    case types.CREATE_STUDIO_USER_ACCEPT_INVITATION_ERROR:
    case types.GET_STUDIO_USER_INVITATION_DETAILS_ERROR:
    case types.GET_STUDIO_USERS_ERROR:
    case types.DELETE_STUDIO_USER_INVITATION_ERROR:
    case types.CREATE_STUDIO_CREDIT_PURCHASE_ERROR:
      return handleErrorState(state, payload);

    case types.REMOVE_SUBSCRIPTION_COUPON:
      return handleRemoveSubscriptionCoupon(state);
    case types.UPDATE_MY_ACCOUNT_STUDIO_FROM_CABLE:
      return handleUpdateMyAccountStudioFromCable(state, payload);
    case types.RESET_USER_SETTINGS_STATE:
      return handleResetUserSettingsState();

    default:
      return state;
  }
};

export default reducer;
