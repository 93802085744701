import { FC } from 'react';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Images
import imageQrConfirmation from '@/assets/images/qr-confirmation.png';
import imageIssuesResolved from '@/assets/images/illustration-lady-computer.svg';

// Types
import { QrCode, QrCodePhoto, QrCodeResolutionAction, QrCodeConfirmation } from '@/types';

interface QrConfirmationProps {
  activeQrCode: QrCode | null;
  selectedPhotoId: string | null;
  subsequentialPhotosIds: string[] | null;

  confirmationSettings: QrCodeConfirmation;
  isMatchNextVisible: boolean;

  onConfirmationAlwaysVisible: ({ actionType, always }: { actionType: QrCodeResolutionAction; always: boolean }) => void;
  onResolutionConfirmation: ({ actionType, qrCodeId, confirm }: { actionType: QrCodeResolutionAction; qrCodeId?: string; confirm: boolean }) => void;
  onMatchAndNext: () => void;
  onMatchAndNextClose: () => void;
}

const QrConfirmation: FC<QrConfirmationProps> = ({
  activeQrCode,
  selectedPhotoId,
  subsequentialPhotosIds,

  confirmationSettings,
  isMatchNextVisible,

  onConfirmationAlwaysVisible,
  onResolutionConfirmation,
  onMatchAndNext,
  onMatchAndNextClose
}) => {
  return (
    <>
      {/* Assign QR Code Modal */}
      {confirmationSettings[QrCodeResolutionAction.CodeIdentification].isVisible && (
        <aside className="job-qr__confirmation animate">
          <div className="flex items-center grow-0 gap-7 mb-10">
            <ul className="flex items-center">
              {activeQrCode?.photo_candidates
                ?.filter(({ id: qrPhotoId }) => selectedPhotoId === qrPhotoId || subsequentialPhotosIds?.includes(qrPhotoId))
                .slice(0, 4)
                .map((qrPhoto: QrCodePhoto, index: number, array: QrCodePhoto[]) => (
                  <li key={qrPhoto.id} className={`job-qr__card ${index === 0 ? 'job-qr__card--prime' : ''}`}>
                    {index === 0 && array.length > 1 && <span className="job-qr__badge">+{array.length - 1}</span>}
                    <img
                      className="job-qr__image"
                      src={imageScaling({ url: qrPhoto.image_url, size: 'xsmall' })}
                      alt={qrPhoto.image_file_name}
                      draggable={false}
                    />
                  </li>
                ))}
            </ul>
            <i className="icon-arrow-right-large"></i>
            <figure>
              <img className="mb-2.5" src={imageQrConfirmation} width="96" alt="" />
              <figcaption className="text-headline-xs font-semibold">{activeQrCode?.title}</figcaption>
            </figure>
          </div>
          <p className="text-body-lg mb-5">
            The image you selected will be marked as a QR image and the{' '}
            {Number(activeQrCode?.photo_candidates?.filter(({ id: qrPhotoId }) => subsequentialPhotosIds?.includes(qrPhotoId))?.length)} photos that follow{' '}
            <br /> it will be assigned to this record. Do you with to continue?
          </p>
          <fieldset className="mb-10">
            <input
              id="dontShow"
              className="hidden"
              type="checkbox"
              name="dontShow"
              checked={confirmationSettings[QrCodeResolutionAction.CodeIdentification].isAlwaysVisible ? false : true}
              onChange={({ target }) =>
                onConfirmationAlwaysVisible({ actionType: QrCodeResolutionAction.CodeIdentification, always: target.checked ? false : true })
              }
            />
            <label htmlFor="dontShow" className="label--checkbox text-body-lg">
              Don’t show again
            </label>
          </fieldset>
          <div className="flex grow-0 gap-2.5">
            <button
              className="button"
              type="button"
              name="yes"
              onClick={() => onResolutionConfirmation({ actionType: QrCodeResolutionAction.CodeIdentification, qrCodeId: activeQrCode?.id, confirm: true })}
            >
              Yes
            </button>
            <button
              className="button button--outline"
              type="button"
              name="cancel"
              onClick={() => onResolutionConfirmation({ actionType: QrCodeResolutionAction.CodeIdentification, confirm: false })}
            >
              Cancel
            </button>
          </div>
        </aside>
      )}

      {/* Association Photos Modal */}
      {confirmationSettings[QrCodeResolutionAction.PhotoAssociation].isVisible && (
        <aside className="job-qr__confirmation animate">
          <div className="flex items-center grow-0 gap-7 mb-10">
            <ul className="flex items-center">
              {activeQrCode?.photo_candidates
                ?.filter(({ id: qrPhotoId }) => selectedPhotoId === qrPhotoId || subsequentialPhotosIds?.includes(qrPhotoId))
                .slice(0, 4)
                .map((qrPhoto: QrCodePhoto) => (
                  <li key={qrPhoto.id} className="job-qr__card">
                    <img
                      className="job-qr__image"
                      src={imageScaling({ url: qrPhoto.image_url, size: 'xsmall' })}
                      alt={qrPhoto.image_file_name}
                      draggable={false}
                    />
                  </li>
                ))}
            </ul>
            <i className="icon-arrow-right-large"></i>
            <figure>
              <img className="mb-2.5" src={imageQrConfirmation} width="96" alt="Qr Confirmation" />
              <figcaption className="text-headline-xs font-semibold">{activeQrCode?.title}</figcaption>
            </figure>
          </div>
          <p className="text-body-lg mb-5">
            These images will be assigned to this QR record as visible by customers. <br /> These images should not include an image of the QR itself.
          </p>
          <fieldset className="mb-10">
            <input
              id="dontShow"
              className="hidden"
              type="checkbox"
              name="dontShow"
              checked={confirmationSettings[QrCodeResolutionAction.PhotoAssociation].isAlwaysVisible ? false : true}
              onChange={({ target }) =>
                onConfirmationAlwaysVisible({ actionType: QrCodeResolutionAction.PhotoAssociation, always: target.checked ? false : true })
              }
            />
            <label htmlFor="dontShow" className="label--checkbox text-body-lg">
              Don’t show again
            </label>
          </fieldset>
          <div className="flex grow-0 gap-2.5">
            <button
              className="button"
              type="button"
              name="yes"
              onClick={() => onResolutionConfirmation({ actionType: QrCodeResolutionAction.PhotoAssociation, qrCodeId: activeQrCode?.id, confirm: true })}
            >
              Yes
            </button>
            <button
              className="button button--outline"
              type="button"
              name="cancel"
              onClick={() => onResolutionConfirmation({ actionType: QrCodeResolutionAction.PhotoAssociation, confirm: false })}
            >
              Cancel
            </button>
          </div>
        </aside>
      )}

      {/* Ignore Issue */}
      {confirmationSettings[QrCodeResolutionAction.IgnoreIssue].isVisible && (
        <aside className="job-qr__confirmation animate">
          <p className="text-body-lg mb-5">
            Ignoring this issue may result in some images matching to the wrong QR record. Ignoring <br /> this issue can't be undone. Correcting the match
            after ignoring this issue will be handled <br /> manually with subject records. Are you sure you want to ignore it?
          </p>
          <fieldset className="mb-10">
            <input
              id="dontShow"
              className="hidden"
              type="checkbox"
              name="dontShow"
              checked={confirmationSettings[QrCodeResolutionAction.IgnoreIssue].isAlwaysVisible ? false : true}
              onChange={({ target }) => onConfirmationAlwaysVisible({ actionType: QrCodeResolutionAction.IgnoreIssue, always: target.checked ? false : true })}
            />
            <label htmlFor="dontShow" className="label--checkbox text-body-lg">
              Don’t show again
            </label>
          </fieldset>
          <div className="flex grow-0 gap-2.5">
            <button
              className="button button--danger"
              type="button"
              name="ignore"
              onClick={() => onResolutionConfirmation({ actionType: QrCodeResolutionAction.IgnoreIssue, qrCodeId: activeQrCode?.id, confirm: true })}
            >
              Ignore
            </button>
            <button
              className="button button--outline"
              type="button"
              name="cancel"
              onClick={() => onResolutionConfirmation({ actionType: QrCodeResolutionAction.IgnoreIssue, confirm: false })}
            >
              Cancel
            </button>
          </div>
        </aside>
      )}

      {/* Restore */}
      {confirmationSettings[QrCodeResolutionAction.RestoreQrCode].isVisible && (
        <aside className="job-qr__confirmation animate">
          <div className="flex items-center grow-0 gap-7 mb-10">
            <ul className="flex items-center">
              {activeQrCode?.photos?.slice(0, 4).map((qrPhoto: QrCodePhoto, index: number) => (
                <li key={qrPhoto.id} className={`job-qr__card ${index === 0 ? 'job-qr__card--prime' : ''}`}>
                  {index === 0 && <span className="job-qr__badge">+{activeQrCode?.photos?.length - 1}</span>}
                  <img
                    className="job-qr__image"
                    src={imageScaling({ url: qrPhoto.image_url, size: 'xsmall' })}
                    alt={qrPhoto.image_file_name}
                    draggable={false}
                  />
                </li>
              ))}
            </ul>
            <i className="icon-arrow-right-large"></i>
            <figure>
              <img className="mb-2.5" src={imageQrConfirmation} width="96" alt="Qr Confirmation" />
              <figcaption className="text-headline-xs font-semibold">{activeQrCode?.title}</figcaption>
            </figure>
          </div>
          <p className="text-body-lg mb-5">
            Restoring this QR record will assign to it the QR image and the {Number(activeQrCode?.photos?.length) - 1} images <br /> that follow. Do you wish to
            restore this QR record?
          </p>
          <fieldset className="mb-10">
            <input
              id="dontShow"
              className="hidden"
              type="checkbox"
              name="dontShow"
              checked={confirmationSettings[QrCodeResolutionAction.RestoreQrCode].isAlwaysVisible ? false : true}
              onChange={({ target }) =>
                onConfirmationAlwaysVisible({ actionType: QrCodeResolutionAction.RestoreQrCode, always: target.checked ? false : true })
              }
            />
            <label htmlFor="dontShow" className="label--checkbox text-body-lg">
              Don’t show again
            </label>
          </fieldset>
          <div className="flex grow-0 gap-2.5">
            <button
              className="button"
              type="button"
              name="yes"
              onClick={() => onResolutionConfirmation({ actionType: QrCodeResolutionAction.RestoreQrCode, qrCodeId: activeQrCode?.id, confirm: true })}
            >
              Yes
            </button>
            <button
              className="button button--outline"
              type="button"
              name="cancel"
              onClick={() => onResolutionConfirmation({ actionType: QrCodeResolutionAction.RestoreQrCode, confirm: false })}
            >
              Cancel
            </button>
          </div>
        </aside>
      )}

      {/* Ignore All Confirmation Modal */}
      {confirmationSettings[QrCodeResolutionAction.IgnoreAllIssues].isVisible && (
        <aside className="job-qr__confirmation animate">
          <p className="text-body-lg mb-5">
            Ignoring these issues may result in some images matching to the wrong QR record. Ignoring <br /> these issues can't be undone. Correcting the match
            after ignoring this issue will be handled <br /> manually with subject records. Are you sure you want to ignore it?
          </p>
          <fieldset className="mb-10">
            <input
              id="dontShow"
              className="hidden"
              type="checkbox"
              name="dontShow"
              checked={confirmationSettings[QrCodeResolutionAction.IgnoreAllIssues].isAlwaysVisible ? false : true}
              onChange={({ target }) =>
                onConfirmationAlwaysVisible({ actionType: QrCodeResolutionAction.IgnoreAllIssues, always: target.checked ? false : true })
              }
            />
            <label htmlFor="dontShow" className="label--checkbox text-body-lg">
              Don’t show again
            </label>
          </fieldset>
          <div className="flex grow-0 gap-2.5">
            <button
              className="button button--danger"
              type="button"
              name="ignore"
              onClick={() => onResolutionConfirmation({ actionType: QrCodeResolutionAction.IgnoreAllIssues, confirm: true })}
            >
              Ignore All
            </button>
            <button
              className="button button--outline"
              type="button"
              name="cancel"
              onClick={() => onResolutionConfirmation({ actionType: QrCodeResolutionAction.IgnoreAllIssues, confirm: false })}
            >
              Cancel
            </button>
          </div>
        </aside>
      )}

      {/* Delete All Session Images Confirmation Modal */}
      {confirmationSettings[QrCodeResolutionAction.DeleteAllImages].isVisible && (
        <aside className="job-qr__confirmation animate">
          <p className="text-body-lg mb-5">
            Are you sure you want to delete all photos associated with this QR session? <br />
            You will need to re-upload them to start over.
          </p>
          <fieldset className="mb-10">
            <input
              id="dontShow"
              className="hidden"
              type="checkbox"
              name="dontShow"
              checked={confirmationSettings[QrCodeResolutionAction.DeleteAllImages].isAlwaysVisible ? false : true}
              onChange={({ target }) =>
                onConfirmationAlwaysVisible({ actionType: QrCodeResolutionAction.DeleteAllImages, always: target.checked ? false : true })
              }
            />
            <label htmlFor="dontShow" className="label--checkbox text-body-lg">
              Don’t show again
            </label>
          </fieldset>
          <div className="flex grow-0 gap-2.5">
            <button
              className="button button--danger"
              type="button"
              name="ignore"
              onClick={() => onResolutionConfirmation({ actionType: QrCodeResolutionAction.DeleteAllImages, confirm: true })}
            >
              Delete Session Images
            </button>
            <button
              className="button button--outline"
              type="button"
              name="cancel"
              onClick={() => onResolutionConfirmation({ actionType: QrCodeResolutionAction.DeleteAllImages, confirm: false })}
            >
              Cancel
            </button>
          </div>
        </aside>
      )}

      {/* Resolved Modal */}
      {isMatchNextVisible && (
        <aside className="job-qr__confirmation animate">
          <img className="mb-5" src={imageIssuesResolved} width="345" alt="Issues resolved" />
          <p className="text-headline-xs font-semibold mb-5">
            All issues have been resolved, clicking “Match Images” will match <br /> your images to their subjects
          </p>
          <div className="flex grow-0 gap-2.5">
            <button className="button button--medium" type="button" name="next" onClick={onMatchAndNext}>
              Match Images
            </button>
            <button className="button button--outline" type="button" name="back" onClick={onMatchAndNextClose}>
              Back
            </button>
          </div>
        </aside>
      )}
    </>
  );
};

export default QrConfirmation;
