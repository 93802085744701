import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const errorToast = (message) => {
  return toast(message, {
    autoClose: 10000,
    transition: Slide,
    closeOnClick: false,
    pauseOnHover: false,
    position: 'top-right',
    type: 'error'
  });
};

export const successToast = (message) => {
  return toast(message, {
    autoClose: 10000,
    transition: Slide,
    pauseOnHover: false,
    closeOnClick: false,
    position: 'top-right',
    type: 'success'
  });
};
