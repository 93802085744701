import React from 'react';
import PropTypes from 'prop-types';

import ProductSelector from './ProductSelector';
import { NestedField } from '@/components/Shared/Forms';
import BogoDiscountSelector from './BogoDiscountSelector';

const BogoFreeOptions = ({ productCategories, change, discountType, amount }) => {
  const handlePercentOrFixedChange = (values) => {
    let num = parseFloat(values.amount);
    if (values.discount_type === 'fixed_amount') {
      change('fixed_amount_cents', !isNaN(num) ? Math.round(num * 100) : null);
      change('fixed_percentage', null);
    } else if (values.discount_type === 'fixed_percentage') {
      change('fixed_amount_cents', null);
      change('fixed_percentage', !isNaN(num) ? num : null);
    } else if (values.discount_type === 'free') {
      change('fixed_amount_cents', null);
      change('fixed_percentage', null);
    }
  };

  return (
    <div id="giveaway-offer-bogo-step2" className="form-segment">
      <h4 className="font-semibold"> ...Get </h4>
      <p> ...the customer will get the following products </p>
      <NestedField name="free_products_attributes" component={ProductSelector} productCategories={productCategories} required={true} />
      <h4 className="font-semibold add-margin"> ...For </h4>
      <BogoDiscountSelector onChange={handlePercentOrFixedChange} discountType={discountType} amount={amount} />
    </div>
  );
};

BogoFreeOptions.propTypes = {
  productCategories: PropTypes.array,
  change: PropTypes.func.isRequired,
  discountType: PropTypes.string,
  amount: PropTypes.string
};

export default BogoFreeOptions;
