import React from 'react';

// Plugins
import Select from 'react-select';
import { Tooltip } from 'react-tippy';

// Components
import UnavailableProducts from './UnavailableProducts';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Images
import imageBusiness from '@/assets/images/icon-small-business.png';
import imagePlaceholder from '@/assets/images/image-placeholder.png';

// Styles
import '../style.css';

const Products = function Products({
  information,
  onSelectAll,
  jobUnavailableItems,
  jobRequesting,
  onOptionSelect,
  onSelectChange,
  moreOptionsShow,
  themeProperties,
  unavailableShow,
  onMoreOptionsShow,
  optionsPriceSheet,
  moreOptionsEnabled,
  onToggleUnavailable,
  onPriceSheetsRefresh,
  priceSheetsRequesting,
  categoryThemesSelected,
  primaryThemeProperties
}) {
  return (
    <section className="panel panel--section animate">
      <figure className="job-settings-store__icon-header">
        <img src={imageBusiness} className="job-settings-store__icon" alt="card" />
        <figcaption className="flex justify-between start">
          <div className="flex flex-col justify-between">
            <h2 className="job-settings-store__panel-title text-headline-sm">Price Sheet</h2>
            <p className="m-0">Add a price sheet to sell products in your store.</p>
          </div>
          <a
            href={`/storefront/price-sheets/${information.priceSheetId}`}
            className="button button--outline button--medium sm:hidden "
            target="_blank"
            rel="noopener noreferrer"
            disabled={!information.priceSheetId}
          >
            View Price Sheet
          </a>
        </figcaption>
      </figure>

      <fieldset className="job-settings-store__row">
        <div className="flex justify-start items-center">
          <div className="job-settings-store__select-container">
            <Select
              className="select select--nomargin job-settings-store__select"
              classNamePrefix="select"
              isLoading={jobRequesting}
              value={optionsPriceSheet.filter((sheet) => sheet.value === information.priceSheetId)}
              options={optionsPriceSheet}
              onChange={(value) => onSelectChange(value, 'priceSheetId')}
            />
            <button className="button button--clean" type="button" onClick={onPriceSheetsRefresh}>
              <i className="icon-refresh"></i>
            </button>
          </div>
          <span className="job-settings-store__select-link">
            or{' '}
            <a href="/storefront/price-sheets" target="_blank" rel="noopener noreferrer">
              create a new price sheet
            </a>
          </span>
        </div>
      </fieldset>

      <fieldset>
        <h3>Product Themes</h3>
        <p className="job-settings-store__sub-title">
          Products in your price sheet that support the following properties will display theme options for your customers to choose from.
        </p>
      </fieldset>

      <fieldset>
        {priceSheetsRequesting && !primaryThemeProperties.length ? (
          <GridLoader columns={6} rows={3} />
        ) : (
          information.priceSheetId &&
          primaryThemeProperties.length > 0 && (
            <>
              <h3>{primaryThemeProperties[0].title_pluralize}</h3>
              <div className="flex justify-between animate">
                <p className="job-settings-store__sub-title">Select at least one {primaryThemeProperties[0].code}.</p>
                <div className="button button--link" onClick={() => onSelectAll(primaryThemeProperties[0].code)}>
                  {primaryThemeProperties
                    .find((category) => category.code === primaryThemeProperties[0].code)
                    .theme_property_values.every((theme) => information.themeValues.includes(theme.id))
                    ? 'Clear All'
                    : 'Select All'}
                </div>
              </div>

              <ul className="grid gap-2.5 animate job-settings-store__themes">
                {primaryThemeProperties.length &&
                  primaryThemeProperties[0].theme_property_values.map((theme, index) => {
                    const themeIsActive = information.themeValues.find((value) => value === theme.id);

                    return (
                      <li
                        className={`job-settings-store__theme-container ${themeIsActive ? 'job-settings-store__theme-container--active' : ''}`}
                        key={theme.id + index}
                        onClick={(e) => onOptionSelect(e, theme.id)}
                        disabled={true}
                      >
                        <figure className="job-settings-store__theme">
                          <div className="job-settings-store__icon-container">
                            <img src={theme.icon_url || imagePlaceholder} className="job-settings-store__theme-icon" alt="theme" />
                          </div>
                          <figcaption>
                            <Tooltip title={theme.value} position="top" arrow="true" distance="10">
                              {theme.value}
                            </Tooltip>
                          </figcaption>
                        </figure>
                      </li>
                    );
                  })}
              </ul>

              {primaryThemeProperties.length > 1 && (
                <button
                  className="button button--outline button-dropdown button--medium font-semibold job-settings-store__more"
                  type="button"
                  onClick={onMoreOptionsShow}
                  disabled={!moreOptionsEnabled}
                >
                  More Options
                </button>
              )}
            </>
          )
        )}
      </fieldset>

      {moreOptionsShow &&
        primaryThemeProperties.slice(1).map((property, index) => (
          <fieldset className="panel" key={index}>
            <h3>{property.title}</h3>
            <div className="flex justify-between animate">
              <p className="job-settings-store__sub-title">Select at least one {property.code} otherwise all options are available.</p>
              <div className="button button--link" onClick={() => onSelectAll(property.code)}>
                {categoryThemesSelected[property.code].themeValues.length ? 'Select All' : ''}
              </div>
            </div>

            <ul className="grid gap-2.5 animate job-settings-store__themes">
              {property.theme_property_values.map((theme, index) => {
                const themeIsActive = information.themeValues.find((value) => value === theme.id) || !categoryThemesSelected[property.code].themeValues.length;
                const themeIsDisabled = themeProperties[property.code] ? !themeProperties[property.code].includes(theme.id) : false;

                return (
                  <li
                    className={`job-settings-store__theme-container animate ${themeIsActive ? 'job-settings-store__theme-container--active' : ''} ${
                      themeIsDisabled ? 'job-settings-store__theme-container--disabled' : ''
                    }`}
                    key={theme.id + index}
                    onClick={(e) => onOptionSelect(e, theme.id)}
                  >
                    <figure className="job-settings-store__theme">
                      <div className="job-settings-store__icon-container">
                        {theme.prop_code === 'color' ? (
                          <>
                            {theme.icon_url ? (
                              <img src={theme.icon_url || imagePlaceholder} className="job-settings-store__theme-icon" alt="theme" />
                            ) : (
                              <div
                                className="job-settings-store__theme-icon job-settings-store__theme-icon--color"
                                style={{ backgroundColor: /^[0-9A-F]{6}$/i.test(theme.color_hex) ? `#${theme.color_hex}` : theme.value.toLowerCase() }}
                              ></div>
                            )}
                          </>
                        ) : (
                          <img src={theme.icon_url || imagePlaceholder} className="job-settings-store__theme-icon" alt="theme" />
                        )}
                      </div>
                      <figcaption>
                        <Tooltip title={theme.value} position="top" arrow="true" distance="10">
                          {theme.value}
                        </Tooltip>
                      </figcaption>
                    </figure>
                  </li>
                );
              })}
            </ul>
          </fieldset>
        ))}

      {unavailableShow && <UnavailableProducts onToggleUnavailable={onToggleUnavailable} data={jobUnavailableItems} />}
    </section>
  );
};

export default Products;
