import * as types from './constants';

// Create nexus
export const createNexus = (payload, callback) => ({
  type: types.CREATE_NEXUS,
  payload: { payload, callback }
});

export const createNexusSuccess = (payload) => ({
  type: types.CREATE_NEXUS_SUCCESS,
  payload: { payload }
});

export const createNexusError = (error) => ({
  type: types.CREATE_NEXUS_ERROR,
  payload: { error }
});

// Read nexus list
export const getNexusList = (payload, callback) => ({
  type: types.GET_NEXUS_LIST,
  payload: { payload, callback }
});

export const getNexusListSuccess = (payload) => ({
  type: types.GET_NEXUS_LIST_SUCCESS,
  payload: { payload }
});

export const getNexusListError = (error) => ({
  type: types.GET_NEXUS_LIST_ERROR,
  payload: { error }
});

// Delete Nexus
export const deleteNexus = (payload, callback) => ({
  type: types.DELETE_NEXUS,
  payload: { payload, callback }
});

export const deleteNexusSuccess = (payload) => ({
  type: types.DELETE_NEXUS_SUCCESS,
  payload: { payload }
});

export const deleteNexusError = (error) => ({
  type: types.DELETE_NEXUS_ERROR,
  payload: { error }
});

export const getMyAccountStudioRequest = (payload, callback) => ({
  type: types.GET_MYACCOUNT_STUDIO_REQUEST,
  payload: { payload, callback }
});

export const getMyAccountStudioRequestSuccess = (studio) => ({
  type: types.GET_MYACCOUNT_STUDIO_SUCCESS,
  payload: {
    studio
  }
});

export const getMyAccountStudioRequestError = (error) => ({
  type: types.GET_MYACCOUNT_STUDIO_ERROR,
  payload: {
    error
  }
});

export const updateMyAccountStudioRequest = (studio, successCallback, errorCallback) => ({
  type: types.UPDATE_MYACCOUNT_STUDIO_REQUEST,
  studio,
  successCallback,
  errorCallback
});

export const updateMyAccountStudioRequestSuccess = (studio) => ({
  type: types.UPDATE_MYACCOUNT_STUDIO_SUCCESS,
  payload: {
    studio
  }
});

export const updateMyAccountStudioRequestError = (error) => ({
  type: types.UPDATE_MYACCOUNT_STUDIO_ERROR,
  payload: {
    error
  }
});

export const getMyAccountStudioTransactionsRequest = (payload, callback) => ({
  type: types.GET_MYACCOUNT_STUDIO_TRANSACTIONS_REQUEST,
  payload: { payload, callback }
});

export const getMyAccountStudioTransactionsSuccess = (studio) => ({
  type: types.GET_MYACCOUNT_STUDIO_TRANSACTIONS_SUCCESS,
  payload: {
    studio
  }
});

export const getMyAccountStudioTransactionsError = (error) => ({
  type: types.GET_MYACCOUNT_STUDIO_TRANSACTIONS_ERROR,
  payload: {
    error
  }
});

export const getMyAccountRequest = () => ({
  type: types.GET_MYACCOUNT_REQUEST
});

export const getMyAccountRequestSuccess = (account) => ({
  type: types.GET_MYACCOUNT_SUCCESS,
  payload: {
    account
  }
});

export const getMyAccountRequestError = (error) => ({
  type: types.GET_MYACCOUNT_ERROR,
  payload: {
    error
  }
});

export const updateMyAccountRequest = (accountInfo, callback) => ({
  type: types.UPDATE_MYACCOUNT_REQUEST,
  payload: {
    accountInfo,
    callback
  }
});

export const updateMyAccountRequestSuccess = (account) => ({
  type: types.UPDATE_MYACCOUNT_SUCCESS,
  payload: {
    account
  }
});

export const updateMyAccountRequestError = (error) => ({
  type: types.UPDATE_MYACCOUNT_ERROR,
  payload: {
    error
  }
});

export const getCurrentTermsRequest = () => ({
  type: types.GET_CURRENT_TERMS_REQUEST
});

export const getCurrentTermsRequestSuccess = (terms) => ({
  type: types.GET_CURRENT_TERMS_SUCCESS,
  payload: {
    terms
  }
});

export const getCurrentTermsRequestError = (error) => ({
  type: types.GET_CURRENT_TERMS_ERROR,
  payload: {
    error
  }
});

// Stripe Express
export const updateStripeAccountRequest = ({ studioId, code }) => ({
  type: types.UPDATE_STRIPE_ACCOUNT_REQUEST,
  payload: {
    studioId,
    code
  }
});

export const updateStripeAccountRequestSuccess = (studio) => ({
  type: types.UPDATE_STRIPE_ACCOUNT_SUCCESS,
  payload: {
    studio
  }
});

export const updateStripeAccountRequestError = (error) => ({
  type: types.UPDATE_STRIPE_ACCOUNT_ERROR,
  payload: {
    error
  }
});

export const deleteStripeAccountRequest = (studioId) => ({
  type: types.DELETE_STRIPE_ACCOUNT_REQUEST,
  payload: {
    studioId
  }
});

export const deleteStripeAccountRequestSuccess = (studio) => ({
  type: types.DELETE_STRIPE_ACCOUNT_SUCCESS,
  payload: {
    studio
  }
});

export const deleteStripeAccountRequestError = (error) => ({
  type: types.DELETE_STRIPE_ACCOUNT_ERROR,
  payload: {
    error
  }
});

export const createStripeExpressOnboarding = (payload, callback) => ({
  type: types.CREATE_STRIPE_EXPRESS_ONBOARDING_REQUEST,
  payload: { payload, callback }
});

export const createStripeExpressOnboardingSuccess = (payload) => ({
  type: types.CREATE_STRIPE_EXPRESS_ONBOARDING_SUCCESS,
  payload: { payload }
});

export const createStripeExpressOnboardingError = (error) => ({
  type: types.CREATE_STRIPE_EXPRESS_ONBOARDING_ERROR,
  payload: { error }
});

export const createStripeExpressComplete = (payload, callback) => ({
  type: types.CREATE_STRIPE_EXPRESS_COMPLETE_REQUEST,
  payload: { payload, callback }
});

export const createStripeExpressCompleteSuccess = (payload) => ({
  type: types.CREATE_STRIPE_EXPRESS_COMPLETE_SUCCESS,
  payload: { payload }
});

export const createStripeExpressCompleteError = (error) => ({
  type: types.CREATE_STRIPE_EXPRESS_COMPLETE_ERROR,
  payload: { error }
});

export const createStripeExpressInstantPayout = (payload, callback) => ({
  type: types.CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_REQUEST,
  payload: { payload, callback }
});

export const createStripeExpressInstantPayoutSuccess = (payload) => ({
  type: types.CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_SUCCESS,
  payload: { payload }
});

export const createStripeExpressInstantPayoutError = (error) => ({
  type: types.CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_ERROR,
  payload: { error }
});

export const createStripeExpressReport = (payload, callback) => ({
  type: types.CREATE_STRIPE_EXPRESS_REPORT_REQUEST,
  payload: { payload, callback }
});

export const createStripeExpressReportSuccess = (payload) => ({
  type: types.CREATE_STRIPE_EXPRESS_REPORT_SUCCESS,
  payload: { payload }
});

export const createStripeExpressReportError = (error) => ({
  type: types.CREATE_STRIPE_EXPRESS_REPORT_ERROR,
  payload: { error }
});

export const getStripeExpressInstantPayoutInfo = (payload, callback) => ({
  type: types.GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_REQUEST,
  payload: { payload, callback }
});

export const getStripeExpressInstantPayoutInfoSuccess = (payload) => ({
  type: types.GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_SUCCESS,
  payload: { payload }
});

export const getStripeExpressInstantPayoutInfoError = (error) => ({
  type: types.GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_ERROR,
  payload: { error }
});

export const getUserStudiosRequest = (payload, callback) => ({
  type: types.GET_USER_STUDIOS_REQUEST,
  payload: { payload, callback }
});

export const getUserStudiosRequestSuccess = (payload) => ({
  type: types.GET_USER_STUDIOS_SUCCESS,
  payload: { payload }
});

export const getUserStudiosRequestError = (error) => ({
  type: types.GET_USER_STUDIOS_ERROR,
  payload: {
    error
  }
});

export const changeCurrentStudioRequest = (studio, callback) => ({
  type: types.CHANGE_CURRENT_STUDIO_REQUEST,
  payload: { studio, callback }
});

export const changeCurrentStudioRequestError = (error) => ({
  type: types.CHANGE_CURRENT_STUDIO_ERROR,
  payload: {
    error
  }
});

// Subscriptions
export const createSubscription = (payload, callback) => ({
  type: types.CREATE_SUBSCRIPTION,
  payload: { payload, callback }
});

export const createSubscriptionSuccess = (payload) => ({
  type: types.CREATE_SUBSCRIPTION_SUCCESS,
  payload: { payload }
});

export const createSubscriptionError = (error) => ({
  type: types.CREATE_SUBSCRIPTION_ERROR,
  payload: { error }
});

export const getSubscriptions = (payload, callback) => ({
  type: types.GET_SUBSCRIPTIONS,
  payload: { payload, callback }
});

export const getSubscriptionsSuccess = (payload) => ({
  type: types.GET_SUBSCRIPTIONS_SUCCESS,
  payload: { payload }
});

export const getSubscriptionsError = (error) => ({
  type: types.GET_SUBSCRIPTIONS_ERROR,
  payload: { error }
});

export const getSubscription = (payload, callback) => ({
  type: types.GET_SUBSCRIPTION,
  payload: { payload, callback }
});

export const getSubscriptionSuccess = (payload) => ({
  type: types.GET_SUBSCRIPTION_SUCCESS,
  payload: { payload }
});

export const getSubscriptionError = (error) => ({
  type: types.GET_SUBSCRIPTION_ERROR,
  payload: { error }
});

export const getSubscriptionPlans = (payload, callback) => ({
  type: types.GET_SUBSCRIPTION_PLANS,
  payload: { payload, callback }
});

export const getSubscriptionPlansSuccess = (payload) => ({
  type: types.GET_SUBSCRIPTION_PLANS_SUCCESS,
  payload: { payload }
});

export const getSubscriptionPlansError = (error) => ({
  type: types.GET_SUBSCRIPTION_PLANS_ERROR,
  payload: { error }
});

export const getSubscriptionCoupon = (payload, callback) => ({
  type: types.GET_SUBSCRIPTION_COUPON,
  payload: { payload, callback }
});

export const getSubscriptionCouponSuccess = (payload) => ({
  type: types.GET_SUBSCRIPTION_COUPON_SUCCESS,
  payload: { payload }
});

export const getSubscriptionCouponError = (error) => ({
  type: types.GET_SUBSCRIPTION_COUPON_ERROR,
  payload: { error }
});

export const removeSubscriptionCoupon = () => ({
  type: types.REMOVE_SUBSCRIPTION_COUPON
});

export const deleteSubscription = (payload, callback) => ({
  type: types.DELETE_SUBSCRIPTION,
  payload: { payload, callback }
});

export const deleteSubscriptionSuccess = (payload) => ({
  type: types.DELETE_SUBSCRIPTION_SUCCESS,
  payload: { payload }
});

export const deleteSubscriptionError = (error) => ({
  type: types.DELETE_SUBSCRIPTION_ERROR,
  payload: { error }
});

// Read studio labs in studio
export const getStudioLabsInStudio = (payload, callback) => ({
  type: types.GET_STUDIO_LABS_IN_STUDIO,
  payload: { payload, callback }
});

export const getStudioLabsInStudioSuccess = (payload) => ({
  type: types.GET_STUDIO_LABS_IN_STUDIO_SUCCESS,
  payload: { payload }
});

export const getStudioLabsInStudioError = (error) => ({
  type: types.GET_STUDIO_LABS_IN_STUDIO_ERROR,
  payload: { error }
});

// Add studio lab to studio
export const addStudioLabtoStudio = (payload, callback) => ({
  type: types.ADD_STUDIO_LAB_TO_STUDIO,
  payload: { payload, callback }
});

export const addStudioLabtoStudioSuccess = (payload) => ({
  type: types.ADD_STUDIO_LAB_TO_STUDIO_SUCCESS,
  payload: { payload }
});

export const addStudioLabtoStudioError = (error) => ({
  type: types.ADD_STUDIO_LAB_TO_STUDIO_ERROR,
  payload: { error }
});

// Read All Labs
export const getAllLabs = (payload, callback) => ({
  type: types.GET_ALL_LABS,
  payload: { payload, callback }
});

export const getAllLabsSuccess = (payload) => ({
  type: types.GET_ALL_LABS_SUCCESS,
  payload: { payload }
});

export const getAllLabsError = (error) => ({
  type: types.GET_ALL_LABS_ERROR,
  payload: { error }
});

// Users

// Read user list
export const getUserList = (payload, callback) => ({
  type: types.GET_USER_LIST_REQUEST,
  payload: { payload, callback }
});

export const getUserListSuccess = (payload) => ({
  type: types.GET_USER_LIST_SUCCESS,
  payload: { payload }
});

export const getUserListError = (error) => ({
  type: types.GET_USER_LIST_ERROR,
  payload: { error }
});

// Read user
export const getUser = (payload, callback) => ({
  type: types.GET_USER_REQUEST,
  payload: { payload, callback }
});

export const getUserSuccess = (payload) => ({
  type: types.GET_USER_SUCCESS,
  payload: { payload }
});

export const getUserError = (error) => ({
  type: types.GET_USER_ERROR,
  payload: { error }
});

// Update user
export const updateUser = (payload, callback) => ({
  type: types.UPDATE_USER_REQUEST,
  payload: { payload, callback }
});

export const updateUserSuccess = (payload) => ({
  type: types.UPDATE_USER_SUCCESS,
  payload: { payload }
});

export const updateUserError = (error) => ({
  type: types.UPDATE_USER_ERROR,
  payload: { error }
});

// Create studio user invitation
export const createStudioUserInvitation = (payload, callback) => ({
  type: types.CREATE_STUDIO_USER_INVITATION,
  payload: { payload, callback }
});

export const createStudioUserInvitationSuccess = (payload) => ({
  type: types.CREATE_STUDIO_USER_INVITATION_SUCCESS,
  payload: { payload }
});

export const createStudioUserInvitationError = (error) => ({
  type: types.CREATE_STUDIO_USER_INVITATION_ERROR,
  payload: { error }
});

// Accept studio user invitation
export const createStudioUserAcceptInvitation = (payload, callback) => ({
  type: types.CREATE_STUDIO_USER_ACCEPT_INVITATION,
  payload: { payload, callback }
});

export const createStudioUserAcceptInvitationSuccess = (payload) => ({
  type: types.CREATE_STUDIO_USER_ACCEPT_INVITATION_SUCCESS,
  payload: { payload }
});

export const createStudioUserAcceptInvitationError = (error) => ({
  type: types.CREATE_STUDIO_USER_ACCEPT_INVITATION_ERROR,
  payload: { error }
});

export const getStudioUserInvitationDetails = (payload, callback) => ({
  type: types.GET_STUDIO_USER_INVITATION_DETAILS,
  payload: { payload, callback }
});

export const getStudioUserInvitationDetailsSuccess = (payload) => ({
  type: types.GET_STUDIO_USER_INVITATION_DETAILS_SUCCESS,
  payload: { payload }
});

export const getStudioUserInvitationDetailsError = (error) => ({
  type: types.GET_STUDIO_USER_INVITATION_DETAILS_ERROR,
  payload: { error }
});

// Read studio user invitations
export const getStudioUsers = (payload, callback) => ({
  type: types.GET_STUDIO_USERS,
  payload: { payload, callback }
});

export const getStudioUsersSuccess = (payload) => ({
  type: types.GET_STUDIO_USERS_SUCCESS,
  payload: { payload }
});

export const getStudioUsersError = (error) => ({
  type: types.GET_STUDIO_USERS_ERROR,
  payload: { error }
});

// Delete studio user invitation
export const deleteStudioUserInvitation = (payload, callback) => ({
  type: types.DELETE_STUDIO_USER_INVITATION,
  payload: { payload, callback }
});

export const deleteStudioUserInvitationSuccess = (payload) => ({
  type: types.DELETE_STUDIO_USER_INVITATION_SUCCESS,
  payload: { payload }
});

export const deleteStudioUserInvitationError = (error) => ({
  type: types.DELETE_STUDIO_USER_INVITATION_ERROR,
  payload: { error }
});

// Studio credit system

// Create credit purchase
export const createStudioCreditPurchase = (payload, callback) => ({
  type: types.CREATE_STUDIO_CREDIT_PURCHASE_REQUEST,
  payload: { payload, callback }
});

export const createStudioCreditPurchaseSuccess = (payload) => ({
  type: types.CREATE_STUDIO_CREDIT_PURCHASE_SUCCESS,
  payload: { payload }
});

export const createStudioCreditPurchaseError = (error) => ({
  type: types.CREATE_STUDIO_CREDIT_PURCHASE_ERROR,
  payload: { error }
});

// Update Studio from Cable
export const updateMyAccountStudioFromCable = (payload) => ({
  type: types.UPDATE_MY_ACCOUNT_STUDIO_FROM_CABLE,
  payload: { payload }
});

export const resetUserSettingsState = () => ({ type: types.RESET_USER_SETTINGS_STATE });
