import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  getJobExport,
  createJobExportPrepare,
  getJobExportItems,
  updateJobExport,
  updateJobExportItem,
  updateJobExportFromCable,
  updateJobExportItemFromCable,
  deleteJobExportItem,
  getSubject,
  getJobExportPeopleFieldOptions
} from '../../../actions';

// Plugins
import { Tooltip } from 'react-tippy';
import Select, { SingleValue, MultiValue, Options, OnChangeValue } from 'react-select';
// @ts-ignore
import { useActionCable } from 'use-action-cable';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Helpers
import imageScaling from '@/utils/imageScaling';
import { Subject } from '@/types/subject';

// Components
import ReviewCropper from './Cropping';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';
import FeatureManager from '../../../Gallery/People/FeaturedManager';

// Styles
import './style.css';

// Types
import { SelectOptionType, Export, ExportItem } from '@/types';

interface ExportsReviewProps {
  match: {
    params: {
      jobId: string;
      id: string;
    };
  };
  history: { push: (location: string) => void };
}

interface CropData {
  crop_width: number;
  crop_height: number;
  crop_x: number;
  crop_y: number;
}

const ExportsReview: FC<ExportsReviewProps> = ({ match, history }) => {
  const dispatch = useDispatch();

  const {
    params: { jobId, id: exportId }
  } = match;
  const {
    people: { fields: peopleFields, requesting: peopleRequesting },
    exports: { exportItems, requesting: exportsRequesting },
    requesting: jobRequesting,

    export: currentExport
  } = useSelector((state: any) => state.jobs);

  const [showExportOverwriteWarning, setShowExportOverwriteWarning] = useState<boolean>(false);

  const [groupOptions, setGroupOptions] = useState<Options<SelectOptionType>>();
  const [groupSelected, setGroupSelected] = useState<SingleValue<SelectOptionType>>(null);
  const [fieldsSelected, setFieldsSelected] = useState<MultiValue<SelectOptionType>>([]);

  const [showIssuesOnly, setShowIssuesOnly] = useState<boolean>(false);
  const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);
  const [filteredExportItems, setFilteredExportItems] = useState<ExportItem[]>([]);

  const [subjectSelected, setSubjectSelected] = useState<Subject | null>(null);
  const [exportItemSelected, setExportItemSelected] = useState<ExportItem | null>(null);

  const [showConfirmPrepare, setShowConfirmPrepare] = useState(false);
  const [showIgnoreAll, setShowIgnoreAll] = useState(false);
  const [confirmIgnore, setConfirmIgnore] = useState('');

  const [showDelete, setShowDelete] = useState(false);

  const [showAutoCrop, setShowAutoCrop] = useState<boolean>(false);
  const [showFeatureManager, setShowFeatureManager] = useState<boolean>(false);

  const isExportComplete = currentExport.status === 'download' && currentExport.download_url;

  const handleShowAutoCropToggle = () => setShowAutoCrop(!showAutoCrop);

  const handleFilterItems = () => {
    let newItems: ExportItem[] = [...exportItems];

    if (showIssuesOnly) {
      newItems = exportItems.filter((item: ExportItem) => {
        const { ignore_issues, crop_status, photo, cropped_image_url } = item;
        const showIssueIcon =
          !ignore_issues &&
          (!photo ||
            crop_status === 'warning' ||
            crop_status === 'failed' ||
            crop_status === 'created' ||
            crop_status === 'pending' ||
            (crop_status === 'success' && !cropped_image_url) ||
            (currentExport.crop && crop_status === 'no_crop'));

        return showIssueIcon;
      });
    } else if (groupSelected?.value && groupSelected?.value !== 'everyone' && fieldsSelected?.length > 0) {
      newItems = exportItems?.filter((item: any) =>
        fieldsSelected?.some((field: SingleValue<SelectOptionType>) => field?.value === item?.subject[`${groupSelected?.value}`])
      );
    }

    setFilteredExportItems(newItems);
  };

  const checkForItemIssues = (items: ExportItem[]) => {
    const showWarning: boolean = items.some(
      (item: ExportItem) =>
        !item.ignore_issues &&
        (!item.photo ||
          item.crop_status === 'warning' ||
          item.crop_status === 'failed' ||
          item.crop_status === 'created' ||
          item.crop_status === 'pending' ||
          (item.crop_status === 'success' && !item.cropped_image_url) ||
          (currentExport.crop && item.crop_status === 'no_crop'))
    );

    setShowWarningMessage(showWarning);
  };

  const handleToggleFeatureManager = (item: ExportItem | null) => {
    if (item?.subject) {
      dispatch(
        getSubject({ id: item.subject.id, with_photos: true }, (response: { data: Subject }) => {
          const { data } = response;
          setExportItemSelected(item);
          setSubjectSelected(data);
        })
      );
    } else {
      setSubjectSelected(null);
      setExportItemSelected(null);
    }

    setShowFeatureManager(!showFeatureManager);
  };

  const handlePrimarySelect = ({ photoId }: { photoId: string }) => {
    if (exportItemSelected?.photo || exportItemSelected?.photo?.id !== photoId) {
      dispatch(
        updateJobExportItem({ exportId: exportItemSelected?.id, photo_id: photoId, ignore_issues: false }, () => {
          handleToggleFeatureManager(null);
        })
      );
    } else {
      handleToggleFeatureManager(null);
    }
  };

  const handleIgnoreIssue = (item: ExportItem) => {
    dispatch(
      updateJobExportItem({ exportId: item?.id, ignore_issues: true }, () => {
        const newExportItems = filteredExportItems;
        const index = filteredExportItems.findIndex((exportItem) => exportItem.id === item.id);
        if (index >= 0) {
          newExportItems.splice(index, 1, { ...item, ignore_issues: true });
        }

        setFilteredExportItems(newExportItems);
      })
    );
  };

  const handleUpdateCrop = (cropData: CropData) => {
    dispatch(
      updateJobExportItem({ exportId: exportItemSelected?.id, ...cropData, ignore_issues: true }, () => {
        handleShowAutoCropToggle();
      })
    );
  };

  const handleDeleteShow = (item: ExportItem) => {
    setExportItemSelected(item);
    setShowDelete(true);
  };

  const handleDeleteCancel = () => {
    setShowDelete(false);
  };

  const handleDeleteConfirm = () => {
    dispatch(
      deleteJobExportItem({ id: exportItemSelected?.id }, () => {
        handleDeleteCancel();
      })
    );
  };

  const handleIgnoreAllChange = (e: any) => setConfirmIgnore(e.target.value);

  const handleIgnoreAllCancel = () => {
    setConfirmIgnore('');
    setShowIgnoreAll(false);
  };

  const handleIgnoreAllConfirm = () => {
    dispatch(updateJobExport({ exportId, ignore_issues: true }, () => handleIgnoreAllCancel()));
  };

  const handleExportConfirmCancel = () => {
    setShowConfirmPrepare(false);
  };

  const handleExportConfirm = () => {
    dispatch(createJobExportPrepare({ exportId }, () => history.push(`/jobs/${jobId}/services/exports`)));
  };

  const handleExportMessageReceive = {
    received(message: any) {
      if (message.type === 'export') {
        dispatch(updateJobExportFromCable({ data: message['export'] }));
      }

      if (message.type === 'export-item') {
        dispatch(updateJobExportItemFromCable({ data: message['export_item'] }));
      }
    }
  };

  const FooterLinks = () => (
    <>
      <hr />
      <p className="m-0">
        <small>
          Need help? Check out our{' '}
          <a href="https://support.photoday.io/en/articles/3379907-what-types-of-data-exports-are-available-to-me" target="_blank" rel="noopener noreferrer">
            support article on export files
          </a>
          .
        </small>
      </p>
    </>
  );

  // This event channel is unique to the current export being reviewed
  useActionCable({ channel: 'ExportEventsChannel', export_id: exportId }, handleExportMessageReceive);
  useActionCable({ channel: 'JobEventsChannel', job_id: jobId }, handleExportMessageReceive);

  useEffect(() => {
    const everyoneOption = { label: 'Everyone', value: 'everyone' };
    const options = Object.keys(peopleFields)
      .filter((key) => key !== 'image_name')
      .map((key) => ({ value: key, label: key.replace('session_start', 'check-in_date').replace('_', ' ') }));

    setGroupOptions([everyoneOption, ...options]);
    setGroupSelected(everyoneOption);
  }, [peopleFields]);

  useEffect(() => {
    setFieldsSelected([]);
  }, [groupSelected]);

  useEffect(() => {
    if (exportItems?.length > 0) {
      handleFilterItems();
    }
  }, [fieldsSelected, showIssuesOnly]);

  useEffect(() => {
    if (exportItems?.length > 0) {
      handleFilterItems();
      checkForItemIssues(exportItems);
    }
  }, [JSON.stringify(exportItems)]);

  useEffect(() => {
    if (currentExport && currentExport.status === 'review' && currentExport.download_url) {
      setShowExportOverwriteWarning(true);
    }
  }, [currentExport]);

  useEffect(() => {
    if (exportId) {
      dispatch(
        getJobExport({ exportId }, (response: { data: Export }) => {
          const { data } = response;
          if (data.status === 'review' || data.status === 'download') {
            dispatch(getJobExportItems({ exportId }));
          } else {
            history.push(`/jobs/${jobId}/services/exports`);
          }
        })
      );
    }

    // Fetch subject data
    dispatch(getJobExportPeopleFieldOptions({ id: exportId }));
  }, []);

  return (
    <>
      <header className="flex justify-between items-center job-exports-review__header">
        <div className="flex items-start job-exports-review__header-container">
          <button className="button button--clean button--noborder" onClick={() => history.push(`/jobs/${jobId}/services/exports`)}>
            <i className="icon-back"></i>
          </button>

          {showIssuesOnly && (
            <hgroup>
              <h2 className="text-headline-sm">Review Issues</h2>
              <h6 className="m-0">Please review the issues for the {currentExport?.name} export file.</h6>
            </hgroup>
          )}

          {isExportComplete && (
            <hgroup>
              <h2 className="text-headline-sm">Export Complete</h2>
              <h6 className="m-0">{currentExport?.name}</h6>
            </hgroup>
          )}

          {!showIssuesOnly && !isExportComplete && (
            <hgroup>
              <h2 className="text-headline-sm">Review Photos</h2>
              <h6 className="m-0">Please review the {currentExport?.name} export file.</h6>
            </hgroup>
          )}
        </div>

        <div className="button-group">
          <Tooltip {...{ title: 'Export Settings', arrow: false, distance: 10, position: 'top', delay: 300, touchHold: true }}>
            <Link to={`/jobs/${jobId}/services/exports/${exportId}/settings`}>
              <button className="button button--outline button--small" name="button" type="button">
                <i className="icon-gear" />
              </button>
            </Link>
          </Tooltip>
          <button
            className="button button--medium"
            name="confirm"
            type="button"
            disabled={showWarningMessage || jobRequesting || currentExport.status !== 'review'}
            onClick={() => setShowConfirmPrepare(true)}
          >
            Confirm
          </button>
          {isExportComplete && (
            <a href={currentExport.download_url} className="button button--outline button--medium" type="button" target="_blank" rel="noopener noreferrer">
              <b>Download Ready</b>
            </a>
          )}
        </div>
      </header>

      {showWarningMessage && (
        <aside className="flex justify-between items-center panel panel--warning panel--neat">
          <p className="m-0">It looks like you may have an issue with a few subjects.</p>
          <button className="button button--warning" type="button" name="ignore" onClick={() => setShowIgnoreAll(true)}>
            Ignore All
          </button>
        </aside>
      )}

      <aside className="flex justify-between top">
        <div className="flex items-start start button-group job-exports-review__filter">
          <Select
            className="select select--capitalize"
            classNamePrefix="select"
            placeholder="Select a group"
            isDisabled={showIssuesOnly}
            value={groupSelected}
            options={groupOptions ? groupOptions : []}
            onChange={(select: SingleValue<SelectOptionType>) => setGroupSelected(select)}
          />
          <Select
            className="select select--capitalize"
            classNamePrefix="select"
            isMulti={true}
            isClearable={false}
            maxMenuHeight={300}
            isDisabled={showIssuesOnly || groupSelected === null || groupSelected.value === 'everyone'}
            placeholder={groupSelected?.value === 'everyone' ? 'All subjects' : 'Select a field'}
            value={fieldsSelected}
            options={
              groupSelected && groupSelected?.value !== 'everyone'
                ? peopleFields[groupSelected.value!].sort().map((field: SingleValue<SelectOptionType>) => ({ value: field, label: field }))
                : []
            }
            onChange={(select: OnChangeValue<SelectOptionType, true>) => setFieldsSelected(select)}
          />
        </div>
        {!isExportComplete && (
          <div className="flex items-center justify-end animate job-exports-review__switch-container">
            <span>View only issues</span>
            <input
              id="viewIssuesSwitch"
              className="hidden"
              name="view_issues"
              type="checkbox"
              checked={showIssuesOnly}
              onChange={() => setShowIssuesOnly(!showIssuesOnly)}
            />
            <label className="label-switch label-switch--small" htmlFor="viewIssuesSwitch" />
          </div>
        )}
      </aside>

      <div className="job-exports-review__container">
        {filteredExportItems?.length > 0 ? (
          <div className="grid gap-5 animate job-exports-review__row">
            {filteredExportItems.map((exportItem: ExportItem) => {
              const { subject, photo: primaryPhoto, crop_status, crop_message, cropped_image_url, ignore_issues } = exportItem;

              const showIssueIcon =
                !ignore_issues &&
                (!primaryPhoto ||
                  crop_status === 'warning' ||
                  crop_status === 'failed' ||
                  crop_status === 'created' ||
                  crop_status === 'pending' ||
                  (crop_status === 'success' && !cropped_image_url) ||
                  (currentExport.crop && crop_status === 'no_crop'));

              const subjectImage = cropped_image_url
                ? cropped_image_url
                : primaryPhoto
                  ? (primaryPhoto?.retouched_image_url ?? primaryPhoto.image_url)
                  : undefined;

              const showImageProcessing = (crop_status === 'created' || crop_status === 'pending') && !cropped_image_url;

              return (
                <div className={`job-exports-review__item ${showImageProcessing ? 'job-exports-review__item--processing' : ''}`} key={subject.id}>
                  <figure>
                    <div className=" flex items-center justify-between job-exports-review__image-header">
                      <span className="font-semibold job-exports-review__image-name">
                        <Tooltip
                          {...{
                            title: `${subject.first_name} ${subject.last_name}`,
                            arrow: false,
                            distance: 18,
                            position: 'top',
                            delay: 300,
                            touchHold: true,
                            disabled: subject && !primaryPhoto
                          }}
                        >
                          {`${subject.first_name} ${subject.last_name}`}
                        </Tooltip>
                      </span>
                      {showIssueIcon && <i className="icon-issue"></i>}
                    </div>

                    <div
                      className={`job-exports-review__image-container ${showImageProcessing ? 'job-exports-review__image-container--processing' : ''} ${
                        primaryPhoto && primaryPhoto.has_transparency ? 'job-exports-review__image-container--knockout' : ''
                      }`}
                      onClick={() => (!showIssueIcon ? handleToggleFeatureManager(exportItem) : null)}
                    >
                      <LazyLoadImage
                        className="job-exports-review__image"
                        src={imageScaling({
                          url: subjectImage,
                          size: 'medium'
                        })}
                        alt={subject.session_photo_filename}
                        height={225}
                        effect="opacity"
                        draggable="false"
                      />

                      {showIssueIcon && (
                        <div className="flex flex-col justify-between items-center flex-nowrap job-exports-review__overlay">
                          <small className="job-exports-review__overlay-message">
                            {subjectImage ? "There's an issue with this photo." : 'This subject is missing a primary photo.'}
                          </small>

                          <div className="flex flex-col justify-end middle">
                            {!primaryPhoto && (
                              <button
                                className="button button--outline button--small button--block"
                                type="button"
                                onClick={() => handleToggleFeatureManager(exportItem)}
                              >
                                <span className="font-semibold">Add Photo</span>
                              </button>
                            )}

                            {primaryPhoto && (
                              <>
                                <button
                                  className="button button--outline button--small button--block"
                                  type="button"
                                  onClick={() => handleToggleFeatureManager(exportItem)}
                                >
                                  <span className="font-semibold">Change Photo</span>
                                </button>
                                <button
                                  className="button button--outline button--small button--block"
                                  type="button"
                                  onClick={() => {
                                    setExportItemSelected(exportItem);
                                    handleShowAutoCropToggle();
                                  }}
                                >
                                  <span className="font-semibold"> Crop Manually</span>
                                </button>
                              </>
                            )}

                            <button className="button button--outline button--small button--block" type="button" onClick={() => handleIgnoreIssue(exportItem)}>
                              <span className="font-semibold">Ignore Issue</span>
                            </button>

                            {crop_message && (
                              <>
                                <button
                                  className="button button--link  button--small button--block job-exports-review__overlay-detail sm:hidden"
                                  type="button"
                                  onClick={() => {}}
                                >
                                  View issue detail
                                </button>
                                <span className="flex items-center justify-center job-exports-review__overlay-detail--message">{crop_message}</span>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="flex justify-between items-center font-semibold flex-nowrap job-exports-review__image-footer">
                      {/* Delete Item */}
                      <Tooltip {...{ title: 'Remove', arrow: false, distance: 10, position: 'top', delay: 300, touchHold: true }}>
                        <button
                          className="button button--outline button--small button--noborder job-exports-review__action"
                          type="button"
                          draggable="false"
                          onClick={() => {
                            handleDeleteShow(exportItem);
                          }}
                        >
                          <i className="icon-add-circle"></i>
                        </button>
                      </Tooltip>
                      {/* show crop icon  */}
                      {primaryPhoto?.image_url && currentExport.crop && (
                        <Tooltip {...{ title: 'Crop', arrow: false, distance: 10, position: 'top', delay: 300, touchHold: true }}>
                          <button
                            className="button button--outline button--small button--noborder"
                            type="button"
                            draggable="false"
                            onClick={() => {
                              setExportItemSelected(exportItem);
                              handleShowAutoCropToggle();
                            }}
                          >
                            <i className="icon-autocrop"></i>
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  </figure>{' '}
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {exportsRequesting ? (
              <GridLoader rows={2} columns={5} gap={20} minHeight={301} />
            ) : (
              <aside className="flex items-center justify-center panel panel--secondary panel--tall animate">
                <h3 className="m-0">{`${showIssuesOnly ? 'All issues have been reviewed.' : 'No subjects were found.'}`}</h3>
              </aside>
            )}
          </>
        )}
      </div>

      {/* Manual subject crop modal */}
      {showAutoCrop && exportItemSelected && exportItemSelected.photo?.image_url && (
        <ReviewCropper
          {...{
            aspectRatio: currentExport.width / currentExport.height,
            exportItem: exportItemSelected,
            onUpdateCrop: handleUpdateCrop,
            onShowAutoCropToggle: handleShowAutoCropToggle
          }}
        />
      )}

      {showFeatureManager && subjectSelected && (
        <FeatureManager
          imageTitle="primary photo"
          exportPrimaryId={exportItemSelected?.photo?.id}
          subject={subjectSelected}
          requesting={peopleRequesting || jobRequesting}
          onSelect={handlePrimarySelect}
          onClose={() => handleToggleFeatureManager(null)}
        />
      )}

      {/* Confirm Prepare */}
      <aside className={`modal ${showConfirmPrepare ? '' : 'transparent'} text-left`}>
        <div className="modal__box modal__box--dark modal__box--xsmall">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={handleExportConfirmCancel}>
              <i className="icon-close"></i>
            </button>

            <h3>{showExportOverwriteWarning ? 'Export' : 'Prepare to Export'}</h3>
          </header>
          <main className="modal__content">
            {showExportOverwriteWarning ? (
              <>
                <p>It looks like this file has been previously exported. By confirming, any previous downloads for this export will be replaced.</p>
                <br />
                <p>
                  You can download the current version of{' '}
                  <a href={currentExport.download_url} className="button button--link" target="_blank" rel="noopener noreferrer">
                    <span className="font-semibold">{currentExport ? currentExport.name : ''} </span>
                  </a>{' '}
                  before confirming.
                </p>
              </>
            ) : (
              <p>
                PhotoDay will begin to prepare the export file. Based on the amount of subjects, it may take a few minutes for the file to be ready. Not to
                worry, you’ll receive an email once the export file is available for download.
              </p>
            )}
          </main>
          <footer className="text-center modal__footer modal__footer--fixed">
            <button
              className="button button--large font-semibold"
              name="button"
              type="button"
              onClick={handleExportConfirm}
              disabled={jobRequesting}
              data-loading={jobRequesting}
            >
              {showExportOverwriteWarning ? 'Confirm' : 'Export File'}
            </button>
            <FooterLinks />
          </footer>
        </div>
      </aside>

      {/* Ignore All modal */}
      <aside className={`modal ${showIgnoreAll ? '' : 'transparent'} text-left`}>
        <div className="modal__box modal__box--dark modal__box--xsmall">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={handleIgnoreAllCancel}>
              <i className="icon-close"></i>
            </button>
            <h3>Ignore All Issues</h3>
          </header>
          <main className="modal__content">
            <p>Are you sure you want to ignore all issues from this export? This action cannot be undone.</p>
            <p>
              Type <b>"ignore"</b> to ignore all issues.
            </p>
            <input className="input--block" type="text" name="ignore" value={confirmIgnore} onChange={handleIgnoreAllChange} maxLength={7} />
          </main>
          <footer className="text-center modal__footer modal__footer--fixed">
            <button
              className="button button--danger button--large"
              name="button"
              type="button"
              onClick={handleIgnoreAllConfirm}
              disabled={!(confirmIgnore.toLowerCase() === 'ignore') || jobRequesting}
              data-loading={jobRequesting}
            >
              Ignore All
            </button>
            <FooterLinks />
          </footer>
        </div>
      </aside>

      {/* Delete modal */}
      <aside className={`modal ${showDelete ? '' : 'transparent'} text-left`}>
        <div className="modal__box modal__box--xsmall modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={handleDeleteCancel}>
              <i className="icon-close"></i>
            </button>
            <h3>Remove Subject</h3>
          </header>
          <main className="modal__content">
            <p>
              Are you sure you want to remove <b>{`${exportItemSelected?.subject?.first_name} ${exportItemSelected?.subject?.last_name}`}</b> from the export?
            </p>
          </main>
          <footer className="flex justify-center modal__footer button-group">
            <button
              className="button button--danger"
              name="button"
              type="button"
              onClick={handleDeleteConfirm}
              disabled={jobRequesting}
              data-loading={jobRequesting}
            >
              Remove
            </button>
            <button className="button button--lean" name="cancel" type="button" onClick={handleDeleteCancel}>
              Cancel
            </button>
          </footer>
        </div>
      </aside>
    </>
  );
};

export default ExportsReview;
